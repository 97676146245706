import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import _ from 'lodash';
import i18n from 'i18next';
import { DefaultCreativeListModel, CreativeListColumns, CreativeListModel } from 'components/CampaignCreativeList/CreativeListModel';
import rtbBindingListFormatters from 'components/CampaignCreativeList/defaultListFormatters';
import fbAdListFormatters from 'components/CampaignCreativeList/fbAdListFormatters';
import { CreativeManagementStateContext } from './CreativeManagementStateContext';
import { L1Object } from 'core/l1Object/L1Object';

export interface ModifyCreativeActivationStateContentModel {
  readonly state: ModifyCreativeActivationStateContentState;
  readonly event: UpdateEventListener<ModifyCreativeActivationStateContentModel>;
  readonly creativeData: Array<any>;
  readonly activate: boolean;
  readonly selectedCreatives: Array<number>;
  setStateContext (stateContext: CreativeManagementStateContext): void;
  getTitle (): string;
  handleOnSelect (creativeId: number): void;
  handleOnSelectAll (): void;
  onCampaignChange (index: number): void;
  getCreativeListModel (): CreativeListModel;
}

export type ModifyCreativeActivationStateContentProps = {
  readonly model: ModifyCreativeActivationStateContentModel;
};

export type ModifyCreativeActivationStateContentState = {
  readonly selectedCreatives: Array<number>;
  readonly currentCampaignIndex: number;
};

abstract class DefaultModifyCreativeActivationStateContentModel implements ModifyCreativeActivationStateContentModel {
  event: FireableUpdateEventListener<ModifyCreativeActivationStateContentModel>;
  creativeData: Array<any>;
  stateContext?: CreativeManagementStateContext;
  activate: boolean;
  selectedCreatives: Array<number>;
  currentCampaignIndex: number;
  bindingIdMap: any = {};

  constructor (creativeData: Array<any>, activate: boolean, private l1Object: L1Object) {
    this.event = new FireableUpdateEventListener<ModifyCreativeActivationStateContentModel>();
    this.creativeData = creativeData.map(data => {
      return {
        ...data,
        creatives: _.filter(data.creatives, creative => creative.isActiveBinding === !activate)
      };
    });
    this.activate = activate;
    this.selectedCreatives = [];
    this.currentCampaignIndex = 0;
    creativeData.forEach(data => {
      data.creatives.forEach((creative) => {
        this.bindingIdMap[`${data.id}-${creative.id}`] = creative.l3ChannelId;
      });
    });
  }

  get state (): ModifyCreativeActivationStateContentState {
    return {
      selectedCreatives: this.selectedCreatives,
      currentCampaignIndex: this.currentCampaignIndex
    };
  }

  abstract get listFormatters ();

  getCreativeListModel () {
    const currentCreativeData = this.creativeData[this.currentCampaignIndex];
    const columnsToShow = [
      CreativeListColumns.ID,
      CreativeListColumns.PREVIEW,
      CreativeListColumns.STATUS,
      CreativeListColumns.DELIVERY,
      CreativeListColumns.TOOL
    ];
    return new DefaultCreativeListModel(
      this.l1Object,
      {
        id: currentCreativeData.id,
        draftId: currentCreativeData.draftId,
        startDate: currentCreativeData.startDate,
        endDate: currentCreativeData.endDate
      },
      currentCreativeData.creatives,
      _.intersection(currentCreativeData.creatives.map(creative => creative.id), this.selectedCreatives),
      false,
      this.handleOnSelect,
      this.handleOnSelectAll,
      (creativeId) => `/creatives/${creativeId}/edit`,
      this.listFormatters,
      columnsToShow
    );
  }

  getTitle () {
    if (this.activate) {
      return i18n.t<string>('modifyCreativeActivationStateContent.labels.chooseActivate');
    } else {
      return i18n.t<string>('modifyCreativeActivationStateContent.labels.chooseDeactivate');
    }
  }

  setStateContext = (stateContext: CreativeManagementStateContext) => {
    this.stateContext = stateContext;
  }

  handleOnSelect = (creativeId: number) => {
    if (this.selectedCreatives.indexOf(creativeId) > -1) {
      _.remove(this.selectedCreatives, id => id === creativeId);
    } else {
      this.selectedCreatives.push(creativeId);
    }

    this.updateStateData();
    this.updateState();
  }

  handleOnSelectAll = () => {
    const allCreativeId = this.creativeData[this.currentCampaignIndex].creatives.map(creative => creative.id);
    const notSelected = _.difference(allCreativeId, this.selectedCreatives);
    if (notSelected.length > 0 && notSelected.length <= allCreativeId.length) {
      this.selectedCreatives = _.concat(this.selectedCreatives, notSelected);
    } else {
      _.remove(this.selectedCreatives, creativeId => notSelected.indexOf(creativeId));
    }
    this.updateStateData();
    this.updateState();
  }

  updateStateData = () => {
    this.stateContext?.setData && this.stateContext.setData({
      bindingIdMap: this.bindingIdMap,
      activate: this.activate,
      selectedCreatives: this.selectedCreatives
    });
  }

  onCampaignChange = (index: number) => {
    this.currentCampaignIndex = index;
    this.updateState();
  }

  updateState () {
    this.event.fireEvent(this);
  }
}

export class RtbModifyCreativeActivationStateContentModel extends DefaultModifyCreativeActivationStateContentModel {

  get listFormatters () {
    return rtbBindingListFormatters;
  }
}

export class RetailModifyCreativeActivationStateContentModel extends RtbModifyCreativeActivationStateContentModel {
}

export class EdiMaxModifyCreativeActivationStateContentModel extends RtbModifyCreativeActivationStateContentModel {
}

export class PICModifyCreativeActivationStateContentModel extends RtbModifyCreativeActivationStateContentModel {
}

export class FbModifyCreativeActivationStateContentModel extends DefaultModifyCreativeActivationStateContentModel {

  get listFormatters () {
    return fbAdListFormatters;
  }
}
