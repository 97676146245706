import React from 'react';
import { faPencilAlt, faEye, faChartArea, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Link } from 'react-router-dom';
import styles from './fbAdSetList.module.scss';
import i18n from 'i18n';
import { FbAdSet, FbBillingEvent, FbPacingType } from 'core/fbAdSet/FbAdSet';
import { formatPrice, formatPriceWithCurrency } from 'helper/CurrencyHelper';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, hasFuncs, inCondition, notSelfServeAdObject } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import moment from 'moment';
import _, { clone } from 'lodash';
import { Status, getEffectiveStatusDefaultColor } from 'components/Status/Status';
import { Trans } from 'react-i18next';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { LimitationTableCell } from 'components/LimitationTableCell/LimitationTableCell';

export const getAdSetStatusDesData = (adSet) => {
  let des = _.startCase(_.lowerCase(adSet.configured_status));
  let color = getEffectiveStatusDefaultColor(adSet.configured_status);
  switch (adSet.configured_status) {
    case 'PAUSED':
      des = i18n.t<string>('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case 'DELETED':
    case 'ARCHIVED':
      des = i18n.t<string>('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ACTIVE':
      des = i18n.t<string>('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return {
    des,
    color
  };
};

export const getAdSetEffectiveStatusDesData = (adSet) => {
  const isDraft = _.get(adSet, 'isDraft', false);
  if (isDraft) {
    return null;
  }

  let des = _.startCase(_.lowerCase(adSet.effective_status));
  let color = getEffectiveStatusDefaultColor(adSet.effective_status);
  let extraInfo = adSet.issues_info ?
    adSet.issues_info.error_summary
    : undefined;
  const now = moment();
  switch (adSet.effective_status) {
    case 'PAUSED':
    case 'CAMPAIGN_PAUSED':
      if (adSet.budget_remaining > 0) {
        des = i18n.t<string>('campaignList.labels.budgetRemainState');
        color = 'danger';
      } else {
        des = i18n.t<string>('campaignList.labels.deactivateState');
        color = 'black';
      }
      if (adSet.effective_status === 'CAMPAIGN_PAUSED') {
        extraInfo = 'Campaign Paused';
      }
      break;
    case 'DELETED':
    case 'ARCHIVED':
      des = i18n.t<string>('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ACTIVE':
      if (moment(adSet.start_time).isAfter(now)) {
        des = i18n.t<string>('campaignList.labels.notStartState');
        color = 'light';
      } else if (moment(adSet.end_time).isBefore(now)) {
        des = i18n.t<string>('campaignList.labels.endState');
        color = 'light';
      } else if (adSet.bindingCount === 0) {
        des = i18n.t<string>('campaignList.labels.noCreativesState');
        color = 'black';
      } else {
        des = i18n.t<string>('campaignList.labels.activateState');
      }
      break;
    default:
      break;
  }

  return {
    des,
    color,
    extraInfo
  };
};

const campaignWritePermission = hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(ADDONFEATURE.CHANNEL.FB));

const nameHeaderFormatter = (
  adSetLength: number,
  selectedObjects: (number | string)[],
  onSelectAll,
  isDeletedList,
  column: any,
  _1,
  { sortElement }
) => {
  const campaignEditPermission = clone(campaignWritePermission).and(inCondition(() => !isDeletedList));
  return (
    <div className={styles.nameHeader}>
      <PermissionChecker permissionAware={campaignEditPermission}>
        <input
          type='checkbox'
          checked={adSetLength === selectedObjects.length}
          ref={el => el && (el.indeterminate = adSetLength !== selectedObjects.length && selectedObjects.length > 0)}
          onChange={onSelectAll}
          id={'input0'}
        />
        <label htmlFor='input0' />
      </PermissionChecker>
      {i18n.t<string>(column.text)}
      {sortElement}
    </div>
  );
};

const creativeBindingFormatter = (
  enableBindingCount: number , { id, draftId, isDraft, state }, _1, formatExtraData?: { onClick, l1Object }
) => {
  if (!formatExtraData) {
    return <div/>;
  }
  const { onClick, l1Object } = formatExtraData;
  const onClickBinding = () => onClick(isDraft ? draftId : id, isDraft);

  const renderCheckButton = () => (
    <IconWithTooltip
      className={styles.detailBtn}
      onClick={onClickBinding}
      icon={faEye}
      tooltipProps={{
        id: `campaignListCreativeViewHint-${id}`,
        tooltip: i18n.t<string>('campaignList.labels.creativeViewHint')
      }}
    />
  );
  const campaignEditPermission = clone(campaignWritePermission).and(inCondition(() => !['DELETED', 'ARCHIVED'].includes(state))).and(notSelfServeAdObject(l1Object));
  return (
      <div className={styles.bindingCell}>
        {enableBindingCount === 0 ? i18n.t<string>('creativeList.labels.none') : enableBindingCount}
        <PermissionChecker
          permissionAware={campaignEditPermission}
          renderOtherwise={renderCheckButton}
        >
          <IconWithTooltip
            placement='right'
            className={styles.campaignEditBtn}
            onClick={onClickBinding as () => void}
            icon={faPencilAlt}
            tooltipProps={{
              id: `campaignListCreativeEditHint-${id}`,
              tooltip: i18n.t<string>('campaignList.labels.creativeEditHint')
            }}
          />
        </PermissionChecker>
      </div>
  );
};

const nameFormatter = (objectId: number | string, fbAdSet: FbAdSet, _2, formatExtraData: any): any => {
  const renderLabel = () => <span className={styles.name}>{fbAdSet.name}</span>;
  const { selectedAdSets, selectedDrafts, onSelect, l1Object } = formatExtraData;
  const isDraft: boolean = _.get(fbAdSet, 'isDraft', false);
  const adSetId: number | string = fbAdSet.id;
  const draftId: number | string = isDraft ? objectId.toString().split('_')[2] : '';
  const objectIsChecked = !isDraft ? selectedAdSets.indexOf(adSetId.toString()) > -1 : selectedDrafts.indexOf(draftId.toString()) > -1;
  const editPath = isDraft ?
    `${formatExtraData.currentUrl}/drafts/${draftId}/edit` :
    `${formatExtraData.currentUrl}/campaigns/${adSetId}/edit`;
  const campaignEditPermission = clone(campaignWritePermission).and(inCondition(() => !['DELETED', 'ARCHIVED'].includes(fbAdSet.configured_status)));
  return (
    <div className={`${styles.nameCell}`}>
      <div className={styles.selectCheckBox}>
        <PermissionChecker permissionAware={campaignEditPermission}>
          <input
            type='checkbox'
            checked={objectIsChecked}
            onChange={_.partial(onSelect, fbAdSet)}
            id={`input${objectId}`}
          />
          <label htmlFor={`input${objectId}`} />
        </PermissionChecker>
      </div>
      <div className={styles.info}>
        <PermissionChecker permissionAware={notSelfServeAdObject(l1Object).and(campaignEditPermission)} renderOtherwise={renderLabel}>
          <Link to={editPath} className={`${styles.name} ${styles.link}`}>
            {fbAdSet.name}
          </Link>
        </PermissionChecker>
        <div className={styles.id}>
          {'ID: ' + objectId}
        </div>
        {isDraft &&
          <div className={styles.draftIcon}>
            {i18n.t<string>('l2ObjectList.labels.draft')}
          </div>
        }
      </div>
    </div>
  );
};

const stateFormatter = (stateData, fbAdSet: FbAdSet) => {
  return (
    <Status
      label={stateData.des}
      color={stateData.color}
    />
  );
};

const deliveryFormatter = (stateData, fbAdSet: FbAdSet) => {
  const isDraft: boolean = _.get(fbAdSet, 'isDraft', false);
  if (isDraft) {
    const renderExtraInfo = () => i18n.t<string>('campaignList.labels.draftStateHint');
    return (
      <Status
        label={i18n.t<string>('campaignList.labels.deactivateState')}
        color={'black'}
        renderExtraInfo={renderExtraInfo}
      />
    );
  }

  return (
    <Status
      label={stateData.des}
      color={stateData.color}
      renderExtraInfo={stateData.extraInfo ? () => stateData.extraInfo : undefined}
    />
  );
};

const scheduleFormatter = (startTime: string, fbAdSet: FbAdSet, _1): any => {
  return (
    <Trans i18nKey='campaignList.labels.schedule'>
      {{ start: startTime }}<br/>to<br/>{{ end: fbAdSet.end_time }}
    </Trans>
  );
};

const budgetFormatter = (budget: {
  lifetime: number,
  daily: number
}, _1, _2, formatExtraData: any): any => {
  const hasBudget = budget.lifetime !== -1;
  const hasDailyBudget = budget.daily !== -1;
  const currency = formatExtraData.currency;
  if (hasBudget) {
    return formatPrice(currency, +budget.lifetime);
  }
  if (hasDailyBudget) {
    return i18n.t<string>('common.valuePerDay', { value: formatPrice(currency, +budget.daily) });
  }
  return i18n.t<string>('common.labels.noData');
};

const optimizationFormatter = (optimization: string, fbAdSet: FbAdSet, _1, formatExtraData: any): any => {
  const currency = formatExtraData.currency;
  const validOptimizations: string[] = Object.values(L2ObjectOptimizationGoal);
  const bidStrategy = _.get(fbAdSet, 'bid_strategy', BidStrategy.LOWEST_COST_WITHOUT_CAP);
  return (
    <div className={styles.optimizationCell}>
      <div className={styles.title}>
        {i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal')}
      </div>
      <div>:</div>
      <div>
        {
          validOptimizations.includes(optimization) ?
            i18n.t<string>(`optimizationGoal.${optimization.toLowerCase()}`) :
            fbAdSet.optimization_goal
        }
      </div>
      {
        _.get(fbAdSet, 'promoted_object.custom_event_type') &&
        <>
          <div className={styles.title}>
            {i18n.t<string>('adSetSetupFlow.mainStep.field.promoted_object_custom_event_type')}
          </div>
          <div>:</div>
          <div>
            {_.startCase(_.lowerCase(_.get(fbAdSet, 'promoted_object.custom_event_type')))}
          </div>
        </>
      }
      <div className={styles.title}>
        {i18n.t<string>('adSetSetupFlow.mainStep.field.bidStrategy')}
      </div>
      <div>:</div>
      <div>
        {
          Object.values(BidStrategy).includes(bidStrategy) ?
            i18n.t<string>(`l1Object.labels.bidStrategy.${bidStrategy.toLowerCase()}`) :
            bidStrategy
        }
      </div>
      <div className={styles.title}>
        {i18n.t<string>('adSetSetupFlow.mainStep.field.bidControl')}
      </div>
      <div>:</div>
      <div>
        {fbAdSet.bid_amount ?
         formatPriceWithCurrency(currency, +(fbAdSet.bid_amount)) :
         i18n.t<string>('common.labels.noData')
        }
      </div>
      <div className={styles.title}>
        {i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent')}
      </div>
      <div>:</div>
      <div>
        {
          Object.values(FbBillingEvent).includes(fbAdSet.billing_event) ?
            i18n.t<string>(`adSet.billingEvent.${fbAdSet.billing_event.toLowerCase()}`) :
            fbAdSet.billing_event
        }
      </div>
      {_.get(fbAdSet, 'pacing_type[0]') &&
        <>
          <div className={styles.title}>
            {i18n.t<string>('adSetSetupFlow.mainStep.field.pacing')}
          </div>
          <div>:</div>
          <div>
            {i18n.t<string>(`adSet.pacing.${_.get(fbAdSet, 'pacing_type[0]', FbPacingType.STANDARD).toLowerCase()}`)}
          </div>
        </>
      }
    </div>
  );
};

const targetingFormatter = (summaryData: any, fbAdSet: FbAdSet) => {
  return <LimitationTableCell summaryData={summaryData} l2ChannelId={fbAdSet.id}/>;
};

const resultsFormatter = (value, fbAdSet: FbAdSet, _2, formatExtraData: any) => {
  const objective = formatExtraData.objective;
  let hint = objective;
  const eventType = _.get(fbAdSet, 'promoted_object.custom_event_type');
  if (objective === L1ObjectObjective.SALES &&
    fbAdSet.optimization_goal === L2ObjectOptimizationGoal.SALES &&
    eventType) {
    hint = eventType;
  }
  return (
    <div className={styles.resultCell}>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {_.startCase(_.lowerCase(hint))}
      </div>
    </div>
  );
};

const floatingEditBtnsFormatter = (_1, fbAdSet: FbAdSet, _2, formatExtraData: any): any => {
  const adSetId: number | string = fbAdSet.id;
  const draftId: number | string = _.get(fbAdSet, 'draftId', 0);
  const onDeleteBtnClick = () => formatExtraData.onDeleteBtnClick(adSetId);
  const canDelete = formatExtraData.canDeleteSelectedAdSets([adSetId]);
  const editPath = fbAdSet.isDraft ?
    `${formatExtraData.currentUrl}/drafts/${draftId}/edit` :
    `${formatExtraData.currentUrl}/campaigns/${adSetId}/edit`;
  const editMessage = fbAdSet.isDraft ?
    i18n.t<string>('l2ObjectList.labels.editDraft') :
    i18n.t<string>('adSetSetupFlow.mainStep.editTitle');
  const timeFormat = 'YYYY-MM-DD HH:mm:ss';
  const from = encodeURIComponent(moment(fbAdSet.start_time).startOf('day').format(timeFormat));
  const to = encodeURIComponent(moment(fbAdSet.end_time).endOf('day').format(timeFormat));
  const reportUrl = `/reports/performance?dimension=l2ChannelId&from=${from}&to=${to}&l2ChannelId=${adSetId}`;
  const campaignDeletePermission = clone(campaignWritePermission).and(inCondition(() => !['DELETED', 'ARCHIVED'].includes(fbAdSet.configured_status)));
  const campaignEditPermission = clone(campaignDeletePermission).and(notSelfServeAdObject(formatExtraData.l1Object));
  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      <PermissionChecker permissionAware={campaignEditPermission}>
        <IconWithTooltip
          icon={faPencilAlt}
          tooltipProps={{
            id: `fbAdSet-editTip-${adSetId}-${draftId}`,
            tooltip: editMessage,
            link: editPath
          }}
        />
      </PermissionChecker>
      <PermissionChecker permissionAware={campaignDeletePermission}>
        <IconWithTooltip
          icon={faTrashAlt}
          disabled={!canDelete}
          onClick={onDeleteBtnClick}
          tooltipProps={{
            id: `fbAdSet-deleteTip-${adSetId}-${draftId}`,
            tooltip: canDelete ? i18n.t<string>('adSet.deleteTitle') : i18n.t<string>('adSet.hints.cannotDelete')
          }}
        />
      </PermissionChecker>
      <PermissionChecker permissionAware={hasFuncs(Permission.REPORT_ADS)}>
        <IconWithTooltip
          icon={faChartArea}
          tooltipProps={{
            id: `fbAdSet-reportTip-${adSetId}-${draftId}`,
            link: reportUrl,
            tooltip: i18n.t<string>('campaignList.labels.reportHint')
          }}
        />
      </PermissionChecker>
    </TableRowToolBar>
  );
};

const formatters = {
  nameHeaderFormatter,
  nameFormatter,
  stateFormatter,
  deliveryFormatter,
  creativeBindingFormatter,
  optimizationFormatter,
  scheduleFormatter,
  budgetFormatter,
  resultsFormatter,
  targetingFormatter,
  floatingEditBtnsFormatter
};
export default formatters;
