import React, { ReactElement } from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import _ from 'lodash';

import { DAILY_BUDGET_STATE } from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignForm/RtbCampaignBasicFormModel';
import {
  DailyBudgetPlan,
  RtbCampaignPlanType,
  VideoAdMetricEvent,
  VideoAdViewObjective
} from 'core/rtbCampaign/RtbCampaign';
import styles from './rtbCampaignInfo.module.scss';
import i18n from 'i18n';
import { AlertDialog } from 'components/AlertDialog';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Trans } from 'react-i18next';
import { FormikField } from 'components/common/form/field/FormikField';
import { Link } from 'react-router-dom';
import { LabelField } from 'components/common/form/field/LabelField';
import { CustomField } from 'components/common/form/field/CustomField';
import { RadioField } from 'components/common/form/field/RadioField';
import { SelectOptions } from 'components/common/commonType';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';

const cx = classnames.bind(styles);

export type CampaignInfoState = {
  alertContent?: string | ReactElement | ReactElement[],
  needShowDailyBudgetAlert: boolean
};

const videoAdMetricEventOptions: SelectOptions[] = createSelectOptionsFromEnum(VideoAdMetricEvent, 'campaign.labels.');

class RtbCampaignInfo extends React.Component<any, CampaignInfoState> {

  turnOnNeedShowDailyBudgetAlert: () => void;

  dudgetTypeOptions = [{
    label: i18n.t<string>('campaignInfo.labels.schedule'),
    value: DailyBudgetPlan.SCHEDULE,
    extra: i18n.t<string>('campaignInfo.labels.cannotModifyHint')
  }, {
    label: i18n.t<string>('campaignInfo.labels.daily'),
    value: DailyBudgetPlan.DAILY,
    extra: i18n.t<string>('campaignInfo.labels.cannotModifyHint')
  }];

  constructor (props) {
    super(props);
    this.state = {
      alertContent: undefined,
      needShowDailyBudgetAlert: false
    };
    this.turnOnNeedShowDailyBudgetAlert = _.partial(this.setNeedShowDailyBudgetAlert, true);
  }

  getDailyBudgetTips () {
    const { dailyBudgetState } = this.props;
    switch (dailyBudgetState) {
      case DAILY_BUDGET_STATE.OVER_BUDGET:
        return (
          <Trans i18nKey='campaign.descriptions.overDailyBudget'>
            ...<span className='text-danger'>...</span>...
          </Trans>
        );
      case DAILY_BUDGET_STATE.UNDER_BUDGET:
        return (
          <Trans i18nKey='campaign.descriptions.underDailyBudget'>
            ...<span className='text-danger'>...</span>...
          </Trans>
        );
      case DAILY_BUDGET_STATE.MEET_BUDGET:
        return (
          <Trans i18nKey='campaign.descriptions.meetDailyBudget'>
            ...<span className='text-dark'>...</span>...
          </Trans>
        );
      case DAILY_BUDGET_STATE.DEFAULT:
      default:
        return;
    }
  }

  setNeedShowDailyBudgetAlert = (needShow) => {
    this.setState({
      needShowDailyBudgetAlert: needShow
    });
  }

  checkIsUnderBudget = () => {
    if (this.state.needShowDailyBudgetAlert && this.props.dailyBudgetState === DAILY_BUDGET_STATE.UNDER_BUDGET) {
      this.setState({
        alertContent: (
          <Trans i18nKey='campaign.descriptions.underDailyBudget'>
            ...<span className='text-danger'>...</span>...
          </Trans>
        )
      });
    }
  }

  dismissAlertWindow = () => {
    this.setState({
      alertContent: undefined,
      needShowDailyBudgetAlert: false
    });
  }

  renderTargetBudgetInput = () => {
    const {
      isCPCModel,
      isCPVModel,
      isCPMModel,
      budget,
      orderPrice,
      currency,
      showBudgetHint,
      remainBudget
    } = this.props;
    let value = 0;
    const orderPriceNumber =
      typeof orderPrice === 'string' ? parseFloat(orderPrice) : orderPrice;
    if (orderPriceNumber && orderPriceNumber >= 0) {
      value = Math.round(budget / orderPriceNumber);
    }
    let label = i18n.t<string>('campaign.labels.targetClicks');
    let unit = i18n.t<string>('campaign.labels.clicks');
    if (isCPMModel) {
      label = i18n.t<string>('campaign.labels.targetImpressions');
      unit = i18n.t<string>('campaign.labels.kImpressions');
    } else if (isCPVModel) {
      label = i18n.t<string>('campaign.labels.targetViews');
      unit = i18n.t<string>('campaign.labels.views');
    }

    let hint: ReactElement | undefined;
    if (showBudgetHint) {
      hint = (
        <Trans i18nKey='campaign.descriptions.remainBudget'>
          Remain budget <span className='text-dark'><>{{ currency }}{{ remainBudget }}</></span>
        </Trans>
      );
    }

    const inputProps = {
      label: i18n.t<string>('campaignInfo.labels.totalBudget'),
      prefix: currency,
      name: 'budget',
      type: 'number',
      min: 0,
      autoComplete: 'off',
      onBlur: this.checkIsUnderBudget,
      onChange: this.turnOnNeedShowDailyBudgetAlert,
      hint,
      fieldContentWidth: 184
    };

    return (
      isCPCModel || isCPVModel || isCPMModel ? (
        <FormikField.InputGroup
          {...inputProps}
          postText={`${label} ${value} ${unit}`}
        />
      ) : (
        <FormikField.InputGroup {...inputProps}/>
      )
    );
  }

  renderReadOnlyViewObjective () {
    if (!this.props.videoAdViewObjective) {
      return <div />;
    }

    const {
      offset,
      videoAdEvent,
      videoAdMetricEvent
    } = this.props.videoAdViewObjective;
    const isVideoProgress = videoAdEvent === VideoAdViewObjective.PROGRESS;
    const isVideoMetric = videoAdEvent === VideoAdViewObjective.METRIC;
    let contentLabel = i18n.t<string>('campaign.labels.videoObjectiveDefault');
    if (isVideoProgress) {
      contentLabel = `${offset} ${i18n.t<string>('campaign.labels.sec')}`;
    }
    if (isVideoMetric) {
      contentLabel = i18n.t<string>(`campaign.labels.${videoAdMetricEvent.toLowerCase()}`);
    }
    return (
      <LabelField
        name='videoAdEvent'
        label={i18n.t<string>('campaign.labels.videoObjective')}
        value={contentLabel}
      />
    );
  }

  renderViewObjective () {
    const {
      canEditPriceModel,
      videoAdViewObjective,
      videoAdViewObjectiveOptions
    } = this.props;

    if (!canEditPriceModel) {
      return this.renderReadOnlyViewObjective();
    }
    const isVideoProgress = videoAdViewObjective ? videoAdViewObjective.videoAdEvent === VideoAdViewObjective.PROGRESS : false;
    const isVideoMetric = videoAdViewObjective ? videoAdViewObjective.videoAdEvent === VideoAdViewObjective.METRIC : false;
    return (
      <>
        <FormikField.Radio
          label={i18n.t<string>('campaign.labels.videoObjective')}
          className={styles.radio}
          options={videoAdViewObjectiveOptions}
          name='videoAdViewObjective.videoAdEvent'
        />
        {isVideoProgress && (
          <FormikField.InputGroup
            label=''
            className={styles.videoAdviewObjectiveAppend}
            prefix={i18n.t<string>('campaign.prefixs.videoObjectiveProgress')}
            name='videoAdViewObjective.offset'
            type='number'
          />
        )}
        {isVideoMetric && (
          <FormikField.InputGroup
            label=''
            prefix={i18n.t<string>('campaign.prefixs.videoObjectiveMetric')}
            name='videoAdViewObjective.videoAdMetricEvent'
          >
            <FormikField.Select
              name='videoAdViewObjective.videoAdMetricEvent'
              options={videoAdMetricEventOptions}
              className={styles.videoAdMetricEvent}
              simpleValue
              fieldContentWidth='auto'
              formGroupClassName={styles.marginBottom0}
            />
          </FormikField.InputGroup>
        )}
      </>
    );
  }

  renderOrderPrice (priceModel, canEditPriceModel) {
    const { orderPriceMinimum, currency, showOrderPriceHint, onChangeOrderPrice } = this.props;

    switch (priceModel) {
      case RtbCampaignPlanType.FCPC:
      case RtbCampaignPlanType.FCPM:
      case RtbCampaignPlanType.FCPV:
      case RtbCampaignPlanType.FVCPM:
        const price = `${currency}${orderPriceMinimum}`;
        const tips = showOrderPriceHint
          ? <Trans i18nKey='campaign.descriptions.orderPriceTips'>
              The price have to over <span className='text-dark'>{{ price }}</span>
            </Trans>
          : '';
        const label =
          i18n.t<string>(`campaign.labels.${priceModel}`) +
          i18n.t<string>('campaign.labels.orderPrice');
        return canEditPriceModel ? (
          <FormikField.InputGroup
            label={label}
            name='orderPrice'
            prefix={currency}
            hint={tips}
            type='number'
            fieldContentWidth={184}
            onChange={onChangeOrderPrice}
          />
        ) : (
          <FormikField.InputGroup
            as='label'
            label={label}
            name='orderPrice'
            prefix={currency}
            prefixBorder={false}
            type='number'
            className={styles.priceLabel}
          />
        );
      case RtbCampaignPlanType.RS:
      case RtbCampaignPlanType.DCPM:
      default:
        return;
    }
  }

  renderDailyBudgetPlan () {
    const {
      currency,
      dailyBudget
    } = this.props;
    const isScheduleDistribution = _.isNil(dailyBudget) || dailyBudget === 0;
    return (
      <CustomField
        label={i18n.t<string>('campaignInfo.labels.dailyBudgetPlan')}
        name='dailyBudgetPlan'
      >
        {
          isScheduleDistribution ?
          <LabelField
            value={i18n.t<string>('campaignSummary.labels.budgetScheduleDistribution')}
            formGroupClassName={styles.marginBottom0}
          /> :
          <>
            <LabelField
              value={i18n.t<string>('campaignInfo.labels.daily')}
              formGroupClassName={styles.marginBottom7}
            />
            <FormikField.InputGroup
              prefix={currency}
              name='dailyTargetBudget'
              hint={this.getDailyBudgetTips()}
              type='number'
              min={0}
              autoComplete='off'
              formGroupClassName={styles.marginBottom0}
            />
          </>
        }
      </CustomField>
    );
  }

  renderCampaignScheduling () {
    const { startDate, endDate } = this.props;
    const endDateMoment = moment(endDate);
    const startDateMoment = moment(startDate);
    const days = endDateMoment.diff(startDateMoment, 'days') + 1;
    const hours = endDateMoment.diff(startDateMoment, 'hours') + 1;
    if (days > 1) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
          Total <span className='text-dark'><>{{ days }} days</></span>
        </Trans>
      );
    } else if (days === 1) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingHour'>
          Total <span className='text-dark'><>{{ hours }} hours</></span>
        </Trans>
      );
    }
  }

  render () {
    const {
      formType,
      autoOptimise,
      editL1ObjectPath,
      errors,
      orderName,
      minDate,
      maxDate,
      canEditPriceModel,
      priceModelOptions,
      agencyProfit,
      currency,
      priceModel,
      canUseDailyBudget,
      canEditBudgetPlan,
      onChangeDailyBudgetPlan: handleChangeDailyBudgetPlan,
      onChangePriceModel: handleChangePriceModel,
      onChangeSchedule: handleChangeSchedule,
      showTagsPlaceholder,
      isCampaignStart,
      videoAdViewObjectiveOptions,
      showOrderPriceField,
      retailerOptions,
      dailyBudgetType
    } = this.props;

    const priceModelClassName = cx('priceModel', {
      'componentWithTips': true
    });
    const dateRangePickerClassName = cx('componentWithTips', {
      disableStart: isCampaignStart
    });
    const CBOBudgetFormatter = () => {
      return i18n.t<string>('campaignInfo.labels.CBOBudgetHint');
    };
    const onChangeSchedule = (startDate, endDate) => {
      handleChangeSchedule(startDate, endDate);
      this.setNeedShowDailyBudgetAlert(true);
    };
    const retailFormatter = value => {
      return i18n.t<string>(`retailers.${value}`);
    };
    const priceModelFormatter = priceModel => i18n.t<string>(`campaign.labels.${priceModel}`);
    return (
      <fieldset>
        <legend>
          <span>{i18n.t<string>('campaignInfo.labels.title')}</span>
        </legend>
        <div className={styles.component}>
          {this.state.alertContent &&
            <AlertDialog
              title={i18n.t<string>('common.warning')}
              message={this.state.alertContent}
              danger={false}
              dismiss={this.dismissAlertWindow}
            />
          }
          <LabelField
            label={i18n.t<string>('campaignInfo.labels.order')}
            name='orderName'
            value={orderName}
          />
          {retailerOptions && (
            formType === 'create'
              ? <FormikField.Select
                label={i18n.t<string>('campaignInfo.labels.retailType')}
                name='retailType'
                simpleValue
                options={retailerOptions}
              /> :
              <FormikField.Label
                label={i18n.t<string>('campaignInfo.labels.retailType')}
                name='retailType'
                formatter={retailFormatter}
              />)
          }
          <FormikField.Input
            label={i18n.t<string>('campaignInfo.labels.campaignName')}
            name='name'
          />
          <FormikField.DateRangePicker
            label={i18n.t<string>('campaignInfo.labels.dayRange')}
            className={dateRangePickerClassName}
            minDate={minDate}
            maxDate={maxDate}
            startDateFormikName='startDate'
            endDateFormikName='endDate'
            name='dayRange'
            timeInterval={60}
            startDatePickerDisabled={isCampaignStart}
            onPopOverClosed={this.checkIsUnderBudget}
            onChange={onChangeSchedule}
            hoverHint={i18n.t<string>('campaignInfo.labels.dateCannotModifyHint')}
            hint={this.renderCampaignScheduling()}
          />
          {
            canEditPriceModel ?
            <FormikField.Select
              className={priceModelClassName}
              name='priceModel'
              label={i18n.t<string>('campaignInfo.labels.priceModel')}
              simpleValue
              options={priceModelOptions}
              onChange={handleChangePriceModel}
              hoverHint={i18n.t<string>('campaignInfo.labels.cannotModifyPriceModelHint')}
            /> :
            <FormikField.Label
              name='priceModel'
              label={i18n.t<string>('campaignInfo.labels.priceModel')}
              formatter={priceModelFormatter}
            />
          }
          {videoAdViewObjectiveOptions && this.renderViewObjective()}
          {showOrderPriceField && this.renderOrderPrice(priceModel, canEditPriceModel)}
          {autoOptimise ?
            <FormikField.Label
              label={i18n.t<string>('campaignInfo.labels.totalBudget')}
              name='budget'
              formatter={CBOBudgetFormatter}
              hint={errors.budget ?
                <div className={styles.budgetError}>
                  <div className='errorTip'>{errors.budget}</div>
                  <Link to={editL1ObjectPath}>
                    {i18n.t<string>('campaignInfo.labels.backToEditRtbCampaignGroup')}
                  </Link>
                </div> :
                ''
              }
            /> :
            this.renderTargetBudgetInput()
          }
          {canUseDailyBudget && !canEditBudgetPlan &&
            this.renderDailyBudgetPlan()}
          {canUseDailyBudget && canEditBudgetPlan && (
            <RadioField
              name='dailyBudgetType'
              label={i18n.t<string>('campaignInfo.labels.dailyBudgetPlan')}
              className={styles.radio}
              useOptionExtraAsHoverTip={true}
              options={this.dudgetTypeOptions}
              value={dailyBudgetType}
              onChange={handleChangeDailyBudgetPlan}
            />
          )}
          {+dailyBudgetType === DailyBudgetPlan.DAILY && canEditBudgetPlan && (
            <FormikField.InputGroup
              label=''
              formGroupClassName={styles.dailyBudgetInput}
              name='dailyTargetBudget'
              prefix={currency}
              hint={this.getDailyBudgetTips()}
              type='number'
              min={0}
              autoComplete='off'
              onBlur={this.checkIsUnderBudget}
              onChange={this.turnOnNeedShowDailyBudgetAlert}
            />
          )}
          <FormikField.ToggleButton
            name='deliverType'
            label={i18n.t<string>('campaign.labels.deliverType')}
            options={this.props.campaignDeliverTypeOptions}
          />
          <FormikField.Tags
            label={i18n.t<string>('campaign.labels.tags')}
            name='tags'
            placeholder={showTagsPlaceholder ? i18n.t<string>('campaign.placeholders.tags') : ''}
          />
          <PermissionChecker
            permissionAware={
              notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)
                .and(addOnEnabled(ADDONFEATURE.CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING))
            }
          >
            <LabelField
              label={i18n.t<string>('campaign.labels.agencyProfit')}
              name='agencyProfit'
              value={agencyProfit}
            />
          </PermissionChecker>
          {/* {campaignAdType === AdType.EDIMAX && (
            <FormikField.Switch
              name='isOutOfOutdoorBk'
              label={i18n.t<string>('campaignInfo.labels.isOutOfOutdoorBk')}
            />
          )} */}
        </div>
      </fieldset>
    );
  }
}

export default RtbCampaignInfo;
