import { FormikContextType, useFormikContext } from 'formik';
import React from 'react';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { FormikFieldProps } from 'hoc/withFormikField';

type FormikFieldNameOptionalProps = PartialBy<FormikFieldProps, 'name'>;
export type FormikCustomRenderProps<T = any> = FormikContextType<T> & FormikFieldNameOptionalProps;
export type FormikCustomProps<T> = FormikFieldNameOptionalProps & {
  render?: (props: FormikCustomRenderProps<T>) => React.ReactNode;
  name?: string;
  children?: React.ReactNode | ((props: FormikCustomRenderProps<T>) => React.ReactNode);
};

export type CustomFieldProps = any;

export type FormikCustomWithFieldLayoutProps<T> = FormikCustomProps<T> & FieldLayoutProps;
export type FormikCustomFieldProps<T = any> = ({ withFieldLayout?: true } & FormikCustomWithFieldLayoutProps<T>) | ({ withFieldLayout: false } & FormikCustomProps<T>);

export const CustomField = withFieldLayout<CustomFieldProps>(({
  render,
  children,
  ...props
}: CustomFieldProps) => {
  if (children) {
    if (typeof children === 'function') {
      return <React.Fragment>{children(props)}</React.Fragment>;
    }
    return <React.Fragment>{children}</React.Fragment>;
  }
  return render ? <React.Fragment>{render(props)}</React.Fragment> : <div/>;
});

CustomField.defaultProps = {
  fieldContentWidth: 'auto'
};

export const FormikCustom = <T,>({
  render,
  children,
  ...props
}: FormikCustomProps<T>) => {

  const formikBag = useFormikContext<T>();
  const newProps = {
    ...props,
    ...formikBag
  };

  if (children) {
    if (typeof children === 'function') {
      return <React.Fragment>{children(newProps)}</React.Fragment>;
    }
    return <React.Fragment>{children}</React.Fragment>;
  }
  return render ? <React.Fragment>{render(newProps)}</React.Fragment> : <div/>;
};
export const FormikCustomWithFieldLayout = withFieldLayout<
  FormikCustomProps<any>
>(FormikCustom) as <T>(
  props: FormikCustomWithFieldLayoutProps<T>
) => React.ReactElement<FormikCustomWithFieldLayoutProps<T>>;

export const FormikCustomField = <T,>({
  withFieldLayout,
  ...props
}: FormikCustomFieldProps<T>) => {
  if (withFieldLayout === false) {
    return <FormikCustom<T> {...props} />;
  } else {
    return <FormikCustomWithFieldLayout<T> {...props} />;
  }
};

FormikCustomField.defaultProps = {
  fieldContentWidth: 'auto'
};
