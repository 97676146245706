import { RtbCampaignPlanType, VideoAdViewObjective } from 'core/rtbCampaign/RtbCampaign';
import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import i18n from 'i18n';

export interface UnknowAdTypeFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractUnknowAdTypeFormModel extends DefaultRtbCampaignBasicFormModel
  implements UnknowAdTypeFormModel {

  getBidPriceFloorData (): any {
    return [];
  }

  getVideoAdViewObjectiveOptions = (priceModel: RtbCampaignPlanType) => {
    if (priceModel !== RtbCampaignPlanType.FCPV) {
      return undefined;
    }

    return [{
      value: VideoAdViewObjective.DEFAULT,
      label: i18n.t<string>('campaign.labels.videoObjectiveDefault'),
      extra: i18n.t<string>('campaign.descriptions.comboObjectiveDefault')
    }, {
      value: VideoAdViewObjective.PROGRESS,
      label: i18n.t<string>('campaign.labels.videoObjectiveProgress')
    }, {
      value: VideoAdViewObjective.METRIC,
      label: i18n.t<string>('campaign.labels.videoObjectiveMetric')
    }];
  }
}

export class EditUnknowAdTypeFormModel extends AbstractUnknowAdTypeFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreateUnknowAdTypeFormModel extends AbstractUnknowAdTypeFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
