import { SelectOptions } from 'components/common/commonType';
import { L1ObjectChannel } from 'core/l1Object/L1Object';

export abstract class DefaultDraftSetupStateContentModel {

  canGoNext: boolean = false;

  l1ObjectOptionsCache = {};

  constructor (public orderOptions: SelectOptions[]) {}

  abstract getL1ObjectsOfChannel (l1Objects);

  setCanGoNext = (canGoNext: boolean) => {
    this.canGoNext = canGoNext;
  }
}

export class RtbCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.RTB);
  }
}

export class FbAdSetDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.FB);
  }
}

export class RetailCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.RETAIL_MEDIA);
  }
}

export class EdiMaxCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects: any) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.EDIMAX);
  }
}

export class PICCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects: any) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.PIC);
  }
}
