import { SelectOptions } from 'components/common/commonType';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { AddonFeatureManager } from 'core/auth/AddonFeatureManager';
import { Permission } from 'core/auth/Permission';
import { FbObjective } from 'core/fbCampaign/FbCampaign';
import { BidStrategy } from 'core/l2Object/L2Object';
import { PermissionItem } from 'core/permission/PermissionAware';
import { addOnEnabled, hasFuncs, isPmax3Order } from 'core/permission/PermissionDSL';
import { RtbObjective } from 'core/rtbCampaignGroup/RtbCampaignGroup';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { L1ObjectCreateRequest, L1ObjectUpdateRequest, L1ObjectWebService, RestfulL1ObjectWebService } from 'ws/L1ObjectWebService';
import { CHANNEL_OBJECTIVE_MAP, L1ObjectChannel, L1ObjectObjective } from './L1Object';
import { get, includes, uniq } from 'lodash';

export interface L1ObjectManager {
  createL1Object (l1Object: L1ObjectCreateRequest): Promise<number>;
  updateL1Object (l1ObjectId: number | string, l1Object: L1ObjectUpdateRequest): Promise<void>;
  getL1Object (l1ObjectId: number | string): Promise<any>;
  getL1Objects (orderId: number | string): Promise<Array<any>>;
  deleteL1Object (l1ObjectId: number): Promise<void>;
  updateL1ObjectState (l1ObjectIds: (number | string)[], state: 'activate' | 'deactivate'): Promise<void>;
  getL1ObjectOptions (): Promise<SelectOptions[]>;
  getOrderNumber (l1ObjectId: string | number): Promise<string>;
  // refreshFbAdSets (l1ObjectId: number | string): Promise<void>;
  getFBCampaignMinBudget (l1ObjectId: number | string): Promise<number>;
  getAdSetLifetimeBudgetOfCampaign (l1ObjectId: number | string): Promise<{[key: string]: number}>;
  getL1ObjectChannelOptions (): SelectOptions[];
  getL1ObjectObjectiveOptions (channel: L1ObjectChannel): SelectOptions[];
  getL1ObjectBidStrategyOptions (): SelectOptions[];
  getNativeObjectiveValue (channel: L1ObjectChannel, pmax3Objective?: string): FbObjective | RtbObjective | undefined;
  getAllowedChannels (addonFeatureManager: AddonFeatureManager): string[];
  isChannelAllowed (channel: L1ObjectChannel, addonFeatureManager: AddonFeatureManager): boolean;
  getModifyPermissionItem (l1Object?: {
    channel: string,
    adsOrderId: number
  }): PermissionItem;
  isOutdoorChannel (channel: L1ObjectChannel): boolean;
}

export class DefaultL1ObjectManager implements L1ObjectManager {
  webService: L1ObjectWebService;

  constructor (
    webService: L1ObjectWebService = new RestfulL1ObjectWebService()
  ) {
    this.webService = webService;
  }

  async createL1Object (l1Object: L1ObjectCreateRequest): Promise<number> {
    return this.webService.createL1Object(l1Object);
  }
  async updateL1Object (l1ObjectId: number | string, l1Object: L1ObjectUpdateRequest): Promise<void> {
    return this.webService.updateL1Object(l1ObjectId, l1Object);
  }
  async getL1Object (l1ObjectId: number | string): Promise<any> {
    return this.webService.getL1Object(l1ObjectId);
  }
  async getL1Objects (orderId: number | string): Promise<any> {
    return this.webService.getL1Objects(orderId);
  }
  async deleteL1Object (l1ObjectId: number): Promise<void> {
    return this.webService.deleteL1Object(l1ObjectId);
  }
  async updateL1ObjectState (l1ObjectIds: (number | string)[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.webService.updateL1ObjectState(l1ObjectIds, state);
  }
  async getL1ObjectOptions (): Promise<SelectOptions[]> {
    return this.webService.getL1ObjectOptions();
  }
  async getOrderNumber (l1ObjectId: string | number): Promise<string> {
    return this.webService.getOrderNumber(l1ObjectId);
  }
  // async refreshFbAdSets (l1ObjectId: number | string): Promise<void> {
  //   return this.webService.refreshFbAdSets(l1ObjectId);
  // }
  async getFBCampaignMinBudget (l1ObjectId: number | string): Promise<number> {
    return this.webService.getFBCampaignMinBudget(l1ObjectId);
  }
  async getAdSetLifetimeBudgetOfCampaign (l1ObjectId: number | string): Promise<{[key: string]: number}> {
    return this.webService.getAdSetLifetimeBudgetOfCampaign(l1ObjectId);
  }

  getL1ObjectChannelOptions (): SelectOptions[] {
    return createSelectOptionsFromEnum(L1ObjectChannel, 'l1Object.labels.channel_');
  }

  getL1ObjectObjectiveOptions (channel: L1ObjectChannel): SelectOptions[] {
    const allowedObjectiveMap = Object.keys(CHANNEL_OBJECTIVE_MAP).reduce((acc, key) => {
      acc[key] = uniq<L1ObjectObjective>(Object.values(CHANNEL_OBJECTIVE_MAP[key]));
      return acc;
    }, {});
    const ignoreObjectives = Object.values(L1ObjectObjective).filter(objective => !allowedObjectiveMap[channel].includes(objective));
    return createSelectOptionsFromEnum(L1ObjectObjective, 'l1Object.labels.objective.', [...ignoreObjectives]);
  }

  getL1ObjectBidStrategyOptions (): SelectOptions[] {
    return createSelectOptionsFromEnum(BidStrategy, 'l1Object.labels.bidStrategy.');
  }

  getNativeObjectiveValue (channel: L1ObjectChannel, pmax3Objective?: string) {
    if (!pmax3Objective) {
      return;
    }
    const nativeObjectiveMap = {
      [L1ObjectChannel.RTB]: {
        [L1ObjectObjective.AWARENESS]: RtbObjective.AWARENESS,
        [L1ObjectObjective.TRAFFIC]: RtbObjective.TRAFFIC,
        [L1ObjectObjective.SALES]: RtbObjective.SALES
      },
      [L1ObjectChannel.RETAIL_MEDIA]: {
        [L1ObjectObjective.AWARENESS]: RtbObjective.AWARENESS,
        [L1ObjectObjective.TRAFFIC]: RtbObjective.TRAFFIC,
        [L1ObjectObjective.SALES]: RtbObjective.SALES
      },
      [L1ObjectChannel.EDIMAX]: {
        [L1ObjectObjective.AWARENESS]: RtbObjective.AWARENESS
      },
      [L1ObjectChannel.PIC]: {
        [L1ObjectObjective.AWARENESS]: RtbObjective.AWARENESS
      },
      [L1ObjectChannel.MESSAGE]: {
        [L1ObjectObjective.AWARENESS]: RtbObjective.AWARENESS
      },
      [L1ObjectChannel.FB]: {
        [L1ObjectObjective.AWARENESS]: FbObjective.OUTCOME_AWARENESS,
        [L1ObjectObjective.TRAFFIC]: FbObjective.OUTCOME_TRAFFIC,
        [L1ObjectObjective.SALES]: FbObjective.OUTCOME_SALES
      }
    };
    const channelMap = nativeObjectiveMap[channel];
    return channelMap[pmax3Objective];
  }

  getAllowedChannels (addonFeatureManager: AddonFeatureManager): string[] {
    return Object.keys(L1ObjectChannel)
      .filter((channel: string) => {
        const featureName = ADDONFEATURE.CHANNEL[channel];
        if (!featureName) {
          return true;
        }
        return addonFeatureManager.isFeatureEnable(featureName);
      });
  }

  isChannelAllowed (channel: L1ObjectChannel, addonFeatureManager: AddonFeatureManager): boolean {
    const featureName = ADDONFEATURE.CHANNEL[channel];
    if (!featureName) {
      return true;
    }
    return addonFeatureManager.isFeatureEnable(featureName);
  }

  getModifyPermissionItem (l1Object?: {
    channel: string,
    adsOrderId: number
  }): PermissionItem {
    const channel = get(l1Object, 'channel');
    const adsOrderId = get(l1Object, 'adsOrderId');
    let basicPermissionAware = hasFuncs(Permission.CAMPAIGN_WRITE);
    if (channel) {
      const channelFeatureName = ADDONFEATURE.CHANNEL[channel];
      if (channelFeatureName) {
        basicPermissionAware = basicPermissionAware.and(addOnEnabled(channelFeatureName));
      }
    }

    if (adsOrderId) {
      basicPermissionAware.and(isPmax3Order(adsOrderId));
    }

    return basicPermissionAware;
  }

  isOutdoorChannel (channel: L1ObjectChannel) {
    return includes([
      L1ObjectChannel.EDIMAX,
      L1ObjectChannel.PIC
    ], channel);
  }
}
