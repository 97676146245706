import React, { CSSProperties } from 'react';
import BootstrapTable, { ColumnDescription, ExpandRowProps, SortOrder, TableChangeHandler } from 'react-bootstrap-table-next';
import styles from './stickableBootstrapTable.module.scss';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import i18n from 'i18n';
import { SetRefContext } from 'contexts/SetRefContext';
import paginationFactory from 'react-bootstrap-table2-paginator';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { SectionWithSearchbar } from 'components/SectionWithSearchbar/SectionWithSearchbar';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';
import { selectableColumnFormatter, selectableHeaderFormatter } from 'components/TableColumn/TableColumn';

interface BatchOperation {
  text: string;
  onClick: () => void;
}

interface StickableBootstrapTableProps<T = any> {
  customPadding?: boolean;
  hover?: boolean;
  hidePagination?: boolean;
  className?: string;
  scrollByElementControl: boolean;
  data?: T[];
  noDataIndication?: string | (() => JSX.Element | string);
  stickHeader?: boolean;
  stickFooter?: boolean;
  stickFirstColumn?: boolean;
  stickLastColumn?: boolean;
  columns: ColumnDescription[];
  summaryData?: T;
  keyField: string;
  remote?: boolean;
  defaultSorted?: [{
    dataField: any; order: SortOrder;
  }];
  expandRow?: ExpandRowProps<T, number>;
  renderRowBtns?: (row: T) => JSX.Element[];
  onTableChange?: TableChangeHandler<T> | undefined;
  rowClasses?: (row: any, rowIndex: number) => string;
  selectedRows?: (string | number)[];
  onSelect?: (id: string | number, select: boolean) => void;
  onSelectPage?: (checked: boolean) => void;
  defaultSearchString?: string;
  searchbarPlaceholder?: string;
  onHandleSearch?: (searchString: string) => void;
  renderListOperations?: () => JSX.Element[];
  batchOperaionConfig?: {
    title?: string,
    operation: BatchOperation | BatchOperation[];
  }[];
  pagination?: { page?: number, sizePerPage: number, totalSize?: number };
}

export class StickableBootstrapTable<T extends object = any> extends React.Component<StickableBootstrapTableProps<T>, any> {

  cx: any;
  tableContainerRef: any;
  debouncedResize: any;
  debounceHandleSearch: any;
  setRef?: (ref: any) => void;
  sortTimeout: NodeJS.Timeout | undefined;

  static defaultProps = {
    stickFirstColumn: true,
    customPadding: true,
    hidePagination: false,
    scrollByElementControl: true,
    pagination: {
      sizePerPage: 10
    }
  };

  constructor (props) {
    super(props);
    this.cx = classNames.bind(styles);
    this.tableContainerRef = React.createRef();
    this.state = {
      dividerVisible: false,
      hoverdRowIndex: -1
    };
    this.debouncedResize = _.debounce(this.resizeHandler.bind(this), 100);
    this.debounceHandleSearch = this.props.onHandleSearch ?
      _.debounce(this.props.onHandleSearch, this.props.remote ? 1000 : 0) :
      undefined;
  }

  componentDidMount () {
    window.addEventListener('resize', this.debouncedResize);
  }

  componentDidUpdate () {
    const hasData = this.props.data && this.props.data.length > 0;
    const showPlaceholder = this.props.noDataIndication && !hasData;
    if (showPlaceholder && this.setRef) {
      this.setRef(undefined);
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.debouncedResize);
    if (this.setRef) {
      this.setRef(undefined);
    }
  }

  get selectable () {
    return this.props.onSelect && this.props.selectedRows;
  }

  get searchable () {
    return !!this.debounceHandleSearch;
  }

  resizeHandler = () => {
    this.setState({ ...this.state });
  }

  handleScroll = () => {
    const current = this.tableContainerRef.current;
    if (current) {
      const scrollVertical = this.props.stickHeader || this.props.stickFooter;
      if (scrollVertical) {
        const showRowDivider = current.scrollTop > 0;
        if (showRowDivider !== this.state.dividerVisible) {
          this.setState({ dividerVisible: showRowDivider });
        }
      } else {
        const showColumnDivider = current.scrollLeft > 0;
        if (showColumnDivider !== this.state.dividerVisible) {
          this.setState({ dividerVisible: showColumnDivider });
        }
      }
    }
  }

  handleOnSearch = (searchString: string): void => {
    if (searchString === '') {
      this.debounceHandleSearch.cancel();
      this.props.onHandleSearch && this.props.onHandleSearch(searchString);
      return;
    }
    this.debounceHandleSearch(searchString);
  }

  renderTablePlaceholder () {
    if (!this.props.noDataIndication) {
      return undefined;
    }
    if (typeof this.props.noDataIndication === 'string') {
      return (
        <div className={styles.placeholder}>
          <TablePlaceholder placeholder={i18n.t<string>(this.props.noDataIndication)} />
        </div>
      );
    }

    return this.props.noDataIndication();
  }

  renderBatchOperations = () => {
    if (!this.props.selectedRows) {
      return <div/>;
    }
    const handleRemoveSelect = () => {
      if (this.props.onSelectPage) {
        this.props.onSelectPage(false);
      }
    };
    const renderOperations = () => {
      if (!this.props.batchOperaionConfig) {
        return;
      }

      const handleNavEvent = (event) => {
        event && event.stopPropagation();
      };

      return this.props.batchOperaionConfig.map(config => {
        const operation = config.operation;
        if (Array.isArray(operation)) {
          const renderDropdownItems = () => {
            return operation.map(op => {
              const onClick = (event) => {
                event && event.stopPropagation();
                op.onClick();
              };
              return (
                <ListEditBar.Dropdown.Item key={op.text} onClick={onClick}>
                  {op.text}
                </ListEditBar.Dropdown.Item>
              );
            });
          };
          return (
            <ListEditBar.Dropdown
              key={config.title}
              title={config.title}
              id='creativeManagement'
              onClick={handleNavEvent}
            >
              {renderDropdownItems()}
            </ListEditBar.Dropdown>
          );
        }
        const onClick = (event) => {
          event && event.stopPropagation();
          operation.onClick();
        };
        return (
          <ListEditBar.Item key={operation.text}>
            <ListEditBar.Link onClick={onClick}>
              {operation.text}
            </ListEditBar.Link>
          </ListEditBar.Item>
        );
      });
    };
    return (
      <ListEditBar selectedItems={this.props.selectedRows} handleRemoveSelect={handleRemoveSelect}>
        {renderOperations()}
      </ListEditBar>
    );
  }

  isColumnSummaryData = (data) => {
    return typeof data === 'object' && data !== null && data.isSummary;
  }

  defaultColumnSortFunc = (dataA, dataB, order) => {
    if (order === 'desc') {
      return dataA < dataB ? 1 : -1;
    }

    return dataA > dataB ? 1 : -1;
  }

  columnSortFunc = (column) => {
    if (!column.sort) {
      return;
    }

    if (!this.props.summaryData) {
      return column.sortFunc;
    }

    return (dataA, dataB, order) => {
      const dataAIsSummary = this.isColumnSummaryData(dataA);
      const dataBIsSummary = this.isColumnSummaryData(dataB);
      if (dataAIsSummary || dataBIsSummary) {
        return dataAIsSummary ? -1 : 1;
      }

      if (column.sortFunc) {
        return column.sortFunc(dataA, dataB, order);
      }

      return this.defaultColumnSortFunc(dataA, dataB, order);
    };
  }

  getTableColumns = () => {
    const defaultStyle = (colIndex: number) => {
      if (colIndex === 0) {
        return { paddingLeft: this.selectable ? '14px' : '64px' };
      }
      return {};
    };
    const style = this.props.customPadding ?
      undefined :
      (_1, _2, _3, colIndex): CSSProperties => defaultStyle(colIndex);
    const headerStyle = this.props.customPadding ?
      undefined :
      (_1, colIndex): CSSProperties => defaultStyle(colIndex);

    const resultColumns = this.props.columns.map(column => ({
      ...column,
      style,
      headerStyle,
      sortFunc: this.columnSortFunc(column),
      formatter: (data, row, rowIndex, extraData) => {
        if (data !== null && typeof data === 'object' && data.isSummary) {
          return <div>{data.value}</div>;
        }
        return column.formatter ? column.formatter(data, row, rowIndex, extraData) : data;
      }
    }));

    const firstColumn = resultColumns.length > 0 ? resultColumns[0] : undefined;
    if (firstColumn && this.selectable) {
      const extraData = {
        allSelected: this.props.selectedRows!.length === _.defaultTo(this.props.data, []).length,
        selectedRows: this.props.selectedRows,
        onSelect: this.props.onSelect,
        onSelectPage: this.props.onSelectPage,
        columnFormatter: firstColumn.formatter
      };
      firstColumn.formatExtraData = firstColumn.formatExtraData ? {
        ...firstColumn.formatExtraData,
        ...extraData
      } : extraData;
      firstColumn.formatter = selectableColumnFormatter;
      firstColumn.headerFormatter = selectableHeaderFormatter;
    }

    const renderRowBtns = this.props.renderRowBtns;
    if (renderRowBtns) {
      resultColumns.push({
        dataField: 'editBtns',
        text: '',
        sort: false,
        style: () => ({ width: 0 }),
        headerStyle: () => ({ width: 0 }),
        sortFunc: undefined,
        formatter: (_1, row) => (
          <TableRowToolBar className={styles.editBtns}>
            {renderRowBtns(row)}
          </TableRowToolBar>
        )
      });
    }
    return resultColumns;
  }

  setupSummaryData (columns, data) {
    const summaryData = this.props.summaryData;
    if (summaryData) {
      const resultData = {};
      const dataFields = _.uniq(_.concat(
        columns.map(column => column.dataField),
        Object.keys(summaryData)
      ));
      dataFields.forEach(key => {
        resultData[key] = {
          isSummary: true,
          value: summaryData[key]
        };
      });
      const paginationOptions = this.props.pagination;
      if (this.props.hidePagination || !paginationOptions || !paginationOptions.sizePerPage) {
        data.unshift(resultData);
        return data;
      }
      // insert summary data to data array every sizePerPage
      const newData: any[] = [];
      for (let i = 0; i < data.length; i++) {
        if (i % paginationOptions.sizePerPage === 0) {
          newData.push({ ...resultData });
        }
        newData.push(data[i]);
      }
      return newData;
    }
    return data;
  }

  rowClasses = (row, rowIndex) => {
    const hasSummaryData = this.props.summaryData !== undefined;
    return this.cx(this.props.rowClasses ? this.props.rowClasses(row, rowIndex) : undefined, {
      summaryRow: hasSummaryData && rowIndex === 0
    });
  }

  render () {
    const data = _.cloneDeep(_.defaultTo(this.props.data, []));
    const hasData = data.length > 0;
    const showPlaceholder = this.props.noDataIndication && !hasData;
    const containerClass = this.cx('stickableTableContainer', {
      paddingBottom: hasData && !this.props.hidePagination,
      withPlaceholder: showPlaceholder
    });
    const tableClass = this.cx('stickableTable', this.props.className, {
      stickHeader: this.props.stickHeader,
      stickFooter: this.props.stickFooter,
      stickFirstColumn: !this.props.stickHeader && this.props.stickFirstColumn,
      stickLastColumn: !this.props.stickHeader && (this.props.stickLastColumn || this.props.renderRowBtns),
      showDivider: this.state.dividerVisible,
      horizontal: this.props.stickHeader || this.props.stickFooter,
      scrollByElementControl: this.props.scrollByElementControl
    });
    const bootstrapTableProps = _.omit(this.props, ['data', 'stickHeader', 'stickFooter', 'stickFirstColumn', 'stickLastColumn', 'columns', 'summaryData']);
    const columns = this.getTableColumns();
    const finalData = this.setupSummaryData(columns, data);
    const showBatchOperations = this.selectable && this.props.selectedRows!.length > 0 && this.props.batchOperaionConfig;
    const onTableChange = this.props.onTableChange ? (type, props) => {
      if (type === 'sort') {
        if (this.sortTimeout) {
          clearTimeout(this.sortTimeout);
        }
        // prevent sort event from firing too many times when click on check box in header
        this.sortTimeout = setTimeout(() => {
          this.props.onTableChange!(type, props);
        }, 0);
        return;
      }
      this.props.onTableChange!(type, props);
    } : undefined;

    const totalSize = _.defaultTo(this.props.pagination!.totalSize, finalData.length);
    const sizePerPage = this.props.pagination!.sizePerPage;
    return (
      <SetRefContext.Consumer>
        {({ setRef }) => {
          this.setRef = setRef;
          return (
            <div className={containerClass}>
              {showBatchOperations ?
                this.renderBatchOperations() :
                undefined
              }
              {this.searchable && !(showBatchOperations) &&
                <SectionWithSearchbar
                  defaultSearchString={this.props.defaultSearchString!}
                  searchbarPlaceholder={_.defaultTo(this.props.searchbarPlaceholder, '')}
                  onHandleSearch={this.handleOnSearch}
                >
                  {this.props.renderListOperations && this.props.renderListOperations()}
                </SectionWithSearchbar>
              }
              {showPlaceholder ?
                this.renderTablePlaceholder() :
                <div
                  className={tableClass}
                  onScroll={this.handleScroll}
                  ref={ref => {
                    this.tableContainerRef.current = ref;
                    setRef(ref);
                  }}
                >
                  <BootstrapTable<T>
                    key={totalSize}
                    {...bootstrapTableProps}
                    rowClasses={this.rowClasses}
                    data={finalData}
                    // @ts-ignore to ignore headerStyle type error
                    columns={columns}
                    onTableChange={onTableChange}
                    pagination={this.props.hidePagination ?
                      undefined :
                      paginationFactory({
                        ...this.props.pagination,
                        sizePerPage: this.props.summaryData ?
                          sizePerPage + 1 :
                          sizePerPage,
                        hideSizePerPage: true,
                        totalSize: this.props.summaryData ?
                          totalSize + _.ceil(totalSize / sizePerPage) :
                          totalSize
                      })
                    }
                  />
                </div>
              }
            </div>
          );
        }}
      </SetRefContext.Consumer>
    );
  }
}
