import React, { useMemo } from 'react';
import { useFBPreview } from 'hooks/GoCreativePreview/useFBPreview';
import styles from './oneForAllDisplayMediaSummary.module.scss';
import { renderFBPreviews } from 'components/AdPreview/adPreviewRenderer';

export const OneForAllVideoMediaSummary: React.FC<{ mediaSummary: any }> = (props) => {

  const typeProperties = props.mediaSummary.typeProperties;
  const previewProps = useMemo(() => ({
    type: 'VIDEO',
    advertiserId: props.mediaSummary.advertiserId.toString()
  }), [props.mediaSummary.advertiserId]);

  const {
    previewData: fbPreviewData
  } = useFBPreview(
    typeProperties,
    previewProps
  );

  return (
    <div className={styles.oneForAllDisplayPreview}>
      <div className={styles.previewSection}>
        {renderFBPreviews(fbPreviewData, { height: 750 })}
      </div>
    </div>
  );
};
