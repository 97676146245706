import { AgencyDetail, Agency, Currency, AgencyWithPagination } from './Agency';
import {
  AgencyWebService,
  RestfulAgencyWebService
} from 'ws/AgencyWebService';
import { CompanyMember } from 'core/companyMember/CompanyMember';
import { SelectOptions } from 'components/common/commonType';
import { AdLogo } from 'core/adLogo/AdLogo';
import { Pageable } from 'ws/Pageable';
export interface AgencyManager {
  getRTBDefaultMinBudgetPerDay (currency: string): number;
  getRTBDefaultMinBudget (currency: string): number;
  getAgencies (): Promise<Array<Agency>>;
  getAgenciesWithPagination (pageable: Pageable, searchString: string): Promise<AgencyWithPagination>;
  getCreditLog (agencyId: number): Promise<any>;
  getBill (agencyId: number): Promise<any>;
  fetchAgency (agencyId: number): Promise<Agency>;
  updateAgency (request: AgencyDetail): Promise<number>;
  updateAgencySelf (agencyId: number, request: {
    agcPercent: number,
    adLogo: AdLogo,
    logoData: {
      url?: string;
      file?: any;
    }
  }): Promise<number>;
  updateAgencyMargin (agencyId: number, agcPercent: number): Promise<void>;
  createAgency (request: AgencyDetail): Promise<number>;
  getMembers (agencyId: number): Promise<Array<CompanyMember>>;
  getAgenciesOptions (addonFeature?: string, extraFields?: string[], allEnable?: boolean): Promise<Array<SelectOptions>>;
  getOemOptions (): Promise<Array<SelectOptions>>;
  payBill (billId: number): Promise<void>;
  updateRebate (agencyId: number, rebateData: {
    id: string | number;
    effectDate: string;
    rate: string | number;
  }): Promise<void>;
  getAdLogo (agencyId: number): Promise<AdLogo>;
}

const minBudgetPerDayMap = {
  [Currency.USD]: 10,
  [Currency.IDR]: 150000,
  [Currency.NTD]: 300
};

const minBudgetMap = {
  [Currency.USD]: 3,
  [Currency.IDR]: 50000,
  [Currency.NTD]: 100
};

export class DefaultAgencyManager implements AgencyManager {
  webService: AgencyWebService;

  constructor (webService: AgencyWebService = new RestfulAgencyWebService()) {
    this.webService = webService;
  }

  getRTBDefaultMinBudgetPerDay (currency: string): number {
    return minBudgetPerDayMap[currency];
  }

  getRTBDefaultMinBudget (currency: string): number {
    return minBudgetMap[currency];
  }

  getAgencies (addonFeature?: string): Promise<Array<Agency>> {
    return this.webService.getAgencies(addonFeature);
  }

  getAgenciesWithPagination (pageable: Pageable, searchString: string): Promise<AgencyWithPagination> {
    return this.webService.getAgenciesWithPagination(pageable, searchString);
  }

  async getCreditLog (agencyId: number): Promise<any> {
    return this.webService.getCreditLog(agencyId);
  }

  async getBill (agencyId: number): Promise<any> {
    return this.webService.getBill(agencyId);
  }

  async fetchAgency (agencyId: number): Promise<Agency> {
    return this.webService.getAgency(agencyId);
  }

  async updateAgency (request: AgencyDetail): Promise<number> {
    return this.webService.updateAgency(request);
  }

  async updateAgencySelf (agencyId: number, request: {
    agcPercent: number,
    adLogo: AdLogo,
    logoData: {
      url?: string;
      file?: any;
    }
  }): Promise<number> {
    return this.webService.updateAgencySelf(agencyId, request);
  }

  async updateAgencyMargin (agencyId: number, agcPercent: number): Promise<void> {
    return this.webService.updateAgencyMargin(agencyId, agcPercent);
  }

  async createAgency (request: AgencyDetail): Promise<number> {
    return this.webService.createAgency(request);
  }

  async getMembers (agencyId: number): Promise<Array<CompanyMember>> {
    return this.webService.getAgencyMember(agencyId);
  }

  async getAgenciesOptions (addonFeature?: undefined, extraFields?: string[], allAddonEnable?: undefined): Promise<Array<SelectOptions>>;
  async getAgenciesOptions (addonFeature: string, extraFields?: string[], allAddonEnable?: boolean): Promise<Array<SelectOptions>>;
  async getAgenciesOptions (addonFeature?: string, extraFields?: string[], allAddonEnable: boolean = true): Promise<Array<SelectOptions>> {
    return this.webService.getAgenciesOptions(addonFeature, extraFields, allAddonEnable);
  }
  async getOemOptions (): Promise<Array<SelectOptions>> {
    return this.webService.getOemOptions();
  }

  async updateRebate (agencyId: number, rebateData: {
    id: string | number;
    effectDate: string;
    rate: string | number;
  }): Promise<void> {
    return this.webService.updateRebate(agencyId, rebateData);
  }

  async payBill (billId: number): Promise<void> {
    return this.webService.payBill(billId);
  }

  async getAdLogo (agencyId: number): Promise<AdLogo> {
    return this.webService.getAdLogo(agencyId);
  }
}
