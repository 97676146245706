import _ from 'lodash';

import { AdRequestSourceSpace } from 'core/adRequestSource/AdRequestSource';
import client from './RestClient';
import { SelectOptions } from 'components/common/commonType';
import { numberWithCommas } from 'utils/StringUtil';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { GoSegmentType } from 'core/goSegment/GoSegment';
import { AxiosInstance } from 'axios';
import i18n from 'i18n';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { SpaceChannel } from 'core/limitation/ProductGroup';

function wrapSegment (json: any) {
  return {
    value: _.get(json, 'value', '').toString(),
    label: _.get(json, 'label', ''),
    extra: {
      i18nKey: 'limitation.labels.peopleNumberTip',
      value: numberWithCommas(_.get(json, 'count', 0))
    }
  };
}

function wrapSegmentGroup (json: any) {
  const isGroup = _.get(json, 'isGroup', false);
  const options = isGroup ? _.get(json, 'options', []).map(json =>
    wrapSegment(json)
  ) : [];
  return {
    value: _.get(json, 'value', '').toString(),
    label: _.get(json, 'label', ''),
    options,
    isGroup,
    extra: isGroup ? undefined : {
      i18nKey: 'limitation.labels.peopleNumberTip',
      value: numberWithCommas(_.get(json, 'count', 0))
    }
  };
}

function wrapOptionsWithGroup (json: any) {
  const isGroup = _.get(json, 'isGroup', false);
  const autoUpdate = _.get(json, 'autoUpdate', false);
  const options = _.get(json, 'options', []).map(json => {
    return {
      value: _.get(json, 'value', '').toString(),
      label: _.get(json, 'label', '')
    };
  });
  const groupName = _.get(json, 'label', '');
  const label = autoUpdate ? `${groupName} ${i18n.t<string>('productGroup.labels.autoUpdate')}` : groupName;
  return {
    value: _.get(json, 'value', '').toString(),
    label,
    isGroup: isGroup,
    options: isGroup ? [] : options,
    extra: isGroup ? options : undefined
  };
}

function wrapLabelValue (json: any): any {
  return {
    label: _.get(json, 'label', ''),
    value: _.get(json, 'value', '').toString(),
    options: _.get(json, 'options', []).map(subJson => {
      return {
        label: _.get(subJson, 'label', ''),
        value: _.get(subJson, 'value', '').toString()
      };
    })
  };
}

function wrapLabelValueWithCount (json: any): any {
  return {
    label: _.get(json, 'label', ''),
    value: _.get(json, 'value', '').toString(),
    extra: numberWithCommas(_.get(json, 'count', 0)),
    options: _.get(json, 'options', []).map(subJson => {
      return {
        label: _.get(subJson, 'label', ''),
        value: _.get(subJson, 'value', '').toString(),
        extra: numberWithCommas(_.get(subJson, 'count', 0))
      };
    })
  };
}

function wrapGoSegments (goSegments: any, channel: L1ObjectChannel): any {
  if (goSegments.length === 0) {
    return [];
  }

  const valueFieldName = {
    [L1ObjectChannel.RTB]: 'segment_id',
    [L1ObjectChannel.RETAIL_MEDIA]: 'segment_id',
    [L1ObjectChannel.FB]: 'custom_audience.id'
  };
  let validSegmentData = goSegments.filter(goSegment => _.get(goSegment, valueFieldName[channel]) !== undefined);
  const dataTypes = [GoSegmentType.CUSTOM, GoSegmentType.MANUAL, GoSegmentType.UPLOAD];
  const dataGoSegments = _.remove(validSegmentData, goSegment => {
    const type = _.get(goSegment, 'type');
    return type ? dataTypes.includes(type) : false;
  });
  return [{
    label: 'Data',
    value: 'Data',
    isGroup: true,
    options: dataGoSegments.map(goSegment => ({
      label: _.get(goSegment, 'name'),
      value: _.get(goSegment, valueFieldName[channel])
    }))
  }, {
    label: 'Lookalike',
    value: 'Lookalike',
    isGroup: true,
    options: validSegmentData.map(goSegment => ({
      label: _.get(goSegment, 'name'),
      value: _.get(goSegment, valueFieldName[channel])
    }))
  }];
}

function wrapSpaceAttribute (json: any, parentAttribute?: {
  key: string;
  i18n: {
    en: string;
    ['zh-tw']: string;
  }
}): SelectOptions {
  const value = parentAttribute ? `${parentAttribute.key}/${json.key}` : json.key;
  const i18nKey = `productGroup.space.attributes.${value}`;
  const enLabel = parentAttribute ? `${parentAttribute.i18n.en} ${json.i18n.en}` : json.i18n.en;
  const twLabel = parentAttribute ? `${parentAttribute.i18n['zh-tw']}${json.i18n['zh-tw']}` : json.i18n['zh-tw'];
  i18n.addResource('en', 'translation', i18nKey, enLabel);
  i18n.addResource('zh-TW', 'translation', i18nKey, twLabel);

  const limitationI18nKey = `limitation.labels.${value.toString().toLowerCase().replace(/-|\s/g, '_')}`;
  i18n.addResource('en', 'translation', limitationI18nKey, enLabel);
  i18n.addResource('zh-TW', 'translation', limitationI18nKey, twLabel);

  return {
    label: i18n.t<string>(i18nKey),
    value,
    options: json.child.map(childJson => wrapSpaceAttribute(childJson, {
      key: json.key,
      i18n: json.i18n
    }))
  };
}

export interface AdRequestSourceWebService {
  getCountries (): Promise<SelectOptions[]>;
  getFBCountries (): Promise<SelectOptions[]>;
  getAdx (): Promise<SelectOptions[]>;
  getSegmentLabel (): Promise<SelectOptions[]>;
  getSSPSpaces (publisherIds?: string): Promise<AdRequestSourceSpace[]>;
  getSSPSpacesByAdType (adType: AdType): Promise<AdRequestSourceSpace[]>;
  getAsiaMaxCountries (): Promise<SelectOptions[]>;
  getAges (): Promise<SelectOptions[]>;
  getGenders (): Promise<SelectOptions[]>;
  getOS (): Promise<SelectOptions[]>;
  getSpaceTypes (): Promise<SelectOptions[]>;
  getTenmaxCategories (): Promise<SelectOptions[]>;
  getArticleCategories (): Promise<SelectOptions[]>;
  getCarriers (): Promise<SelectOptions[]>;
  getDevice (): Promise<SelectOptions[]>;
  getContentLanguages (): Promise<SelectOptions[]>;
  getSegments (advertiserId: number): Promise<SelectOptions[]>;
  getGoSegments (advertiserId: number, channel: L1ObjectChannel): Promise<SelectOptions[]>;
  getTenmaxSegments (): Promise<SelectOptions[]>;
  getMessageSegments (): Promise<SelectOptions[]>;
  getProductSegments (advertiserId: number | string, retailId: string): Promise<SelectOptions[]>;
  getDomains (): Promise<SelectOptions[]>;
  getFBCountryPeopleAmount (country: string): Promise<number>;
  getRelatedSearchKeywords (keywords: string[], retailer: string): Promise<{
    keyword: string,
    searchCount: number
  }[]>;
  getAgencySegments (agencyId: number): Promise<SelectOptions[]>;
  getSpaceAdFormats (): Promise<SelectOptions[]>;
  getSpaceAttributes (adFormat: string): Promise<{[key: string]: SelectOptions[]}>;
  getSpaceByAttributesAndChannel (attributes: Record<string, string[]>, channel?: SpaceChannel): Promise<SelectOptions[]>;
  getSpacesGroupsOfAdType (adType: AdType): Promise<SelectOptions[]>;
}

export class RestfulAdRequestSourceWebService
  implements AdRequestSourceWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  pickRecords (response: any) {
    return _.sortBy(_.get(response, 'records', []), ['label']).map(record => ({
      ...record,
      options: record.options ? _.sortBy(record.options, ['label']) : record.options
    }));
  }
  async getCountries (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/countries/pmax'
    );
    return this.pickRecords(response.data);
  }
  async getFBCountries (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/countries/fb'
    );
    return _.sortBy(_.get(response.data, 'records', []), ['label']).map(record => ({
      ...record,
      extra: 'country',
      options: record.options ?
        _.sortBy(record.options, ['label'])
         .map(option => ({ ...option, extra: option.isCity ? 'city' : 'region' })) :
        record.options
    }));
  }
  async getAdx (): Promise<SelectOptions[]> {
    const response = await this.restClient.get('/v2/ad-request-source/adx');
    return this.pickRecords(response.data);
  }
  async getSegmentLabel (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/tenmax-segment-labels'
    );
    return this.pickRecords(response.data);
  }
  async getSSPSpaces (publisherIds?: string): Promise<AdRequestSourceSpace[]> {
    const requestUrl = publisherIds
      ? `/v2/ad-request-source/spaces/rmax?publisher_id=${publisherIds}`
      : `/v2/ad-request-source/spaces/rmax`;
    const response = await this.restClient.get(requestUrl);
    return this.pickRecords(response.data).map(json => {
      return wrapOptionsWithGroup(json);
    }).sort((optionsA, optionsB) => optionsA.isGroup && !optionsB.isGroup ? -1 : 1);
  }
  async getSSPSpacesByAdType (adType: AdType): Promise<AdRequestSourceSpace[]> {
    let requestUrl = `/v2/ad-request-source/spaces/rmax?adType=${adType}`;

    const response = await this.restClient.get(requestUrl);
    return this.pickRecords(response.data).map(json => {
      return wrapOptionsWithGroup(json);
    }).sort((optionsA, optionsB) => optionsA.isGroup && !optionsB.isGroup ? -1 : 1);
  }
  async getAsiaMaxCountries (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/countries/rmax'
    );
    return this.pickRecords(response.data);
  }
  async getAges (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/ages'
    );
    return this.pickRecords(response.data);
  }
  async getGenders (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/genders'
    );
    return this.pickRecords(response.data).map(json => {
      return wrapLabelValue(json);
    });
  }
  async getOS (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/os'
    );
    return this.pickRecords(response.data);
  }
  async getSpaceTypes (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/space-types'
    );
    return this.pickRecords(response.data);
  }
  async getTenmaxCategories (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/tenmax-categories'
    );
    return this.pickRecords(response.data).map(json => {
      return wrapLabelValue(json);
    });
  }
  async getCarriers (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/carriers'
    );
    return this.pickRecords(response.data);
  }

  async getContentLanguages (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/content-lang'
    );
    return this.pickRecords(response.data).map(json => {
      return wrapLabelValue(json);
    });
  }

  async getArticleCategories (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/v2/ad-request-source/article-categories?lang=zh'
    );
    return this.pickRecords(response.data).map(json => wrapLabelValueWithCount(json));
  }

  async getSegments (advertiserId): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/segments?advertiserId=${advertiserId}`
    );
    return this.pickRecords(response.data).map(json => wrapSegmentGroup(json));
  }

  async getGoSegments (advertiserId: number, channel: L1ObjectChannel): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/go-segments/${channel}/segment/${advertiserId}`
    );
    return wrapGoSegments(response.data, channel);
  }

  async getDevice (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/device-types`
    );
    return this.pickRecords(response.data);
  }

  async getTenmaxSegments (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/tenmax-segments`
    );
    return _.get(response.data, 'records', []).map(json => {
      return wrapSegmentGroup(json);
    });
  }

  async getMessageSegments (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/pic-segments`
    );
    return _.get(response.data, 'records', []).map(json => {
      return wrapSegmentGroup(json);
    });
  }

  async getProductSegments (advertiserId: number | string, retailId: string): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/rdp-segments?advertiserId=${advertiserId}&retailId=${retailId}`
    );
    return _.get(response.data, 'records', []).map(json => {
      return wrapSegment(json);
    });
  }

  async getDomains (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/domains/asiamax`
    );
    return this.pickRecords(response.data).map(json => {
      return wrapOptionsWithGroup(json);
    }).sort((optionsA, optionsB) => optionsA.isGroup && !optionsB.isGroup ? -1 : 1);
  }

  async getFBCountryPeopleAmount (country: string): Promise<number> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/countries/fb/${country}/people`
    );
    return _.get(response.data, 'count');
  }

  async getRelatedSearchKeywords (keywords: string[], retailer: string): Promise<{
    keyword: string,
    searchCount: number
  }[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/relation-keywords?keywords=${decodeURIComponent(keywords.join(','))}&retailer=${retailer}`
    );
    return _.get(response.data, 'records');
  }

  async getAgencySegments (agencyId: number): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      `/v2/ad-request-source/ad-agencies/segments/${agencyId}`
    );
    return this.pickRecords(response.data).map(json => wrapLabelValueWithCount(json));
  }

  async getSpaceAdFormats (): Promise<SelectOptions[]> {
    const { data } = await this.restClient.get(
      `/v2/ad-request-source/space-ad-formats`
    );

    if (!data) {
      return [];
    }

    const i18nKey = `productGroup.space.attributes.titles.${data.key}`;
    i18n.addResource('en', 'translation', i18nKey, data.i18n.en);
    i18n.addResource('zh-TW', 'translation', i18nKey, data.i18n['zh-tw']);
    return data.child.map(json => _.omit(wrapSpaceAttribute(json), 'options'));
  }

  async getSpaceAttributes (adFormat: string): Promise<{[key: string]: SelectOptions[]}> {
    const { data } = await this.restClient.get(
      `/v2/ad-request-source/space-attributes?adFormat=${adFormat}`
    );
    return this.pickRecords(data).reduce((acc, json) => {
      const i18nKey = `productGroup.space.attributes.titles.${json.key}`;
      i18n.addResource('en', 'translation', i18nKey, json.i18n.en);
      i18n.addResource('zh-TW', 'translation', i18nKey, json.i18n['zh-tw']);
      const limitationI18nKey = `limitation.labels.${json.key}`;
      i18n.addResource('en', 'translation', limitationI18nKey, json.i18n.en);
      i18n.addResource('zh-TW', 'translation', limitationI18nKey, json.i18n['zh-tw']);
      acc[json.key] = json.child.map(child => wrapSpaceAttribute(child));
      return acc;
    }, {});
  }

  async getSpaceByAttributesAndChannel (attributesWithAdFormat: Record<string, string[]>, channel?: SpaceChannel): Promise<SelectOptions[]> {
    const { data } = await this.restClient.post(
      `/v2/ad-request-source/spaces-with-attributes`, {
        channel,
        attributesWithAdFormat
      }
    );

    return this.pickRecords(data).map(record => {
      const value = record.value.replace(/^\\/, '');
      return { label: `${value},${record.label}`, value };
    });
  }

  async getSpacesGroupsOfAdType (adType: AdType): Promise<SelectOptions[]> {
    const { data } = await this.restClient.get(
      `/v2/ad-request-source/space-groups?adType=${adType}`
    );

    return this.pickRecords(data).map(record => wrapOptionsWithGroup(record));
  }
}
