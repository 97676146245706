import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { hasFuncs, hasRoles, notRoles } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { RecentGroupDropdown } from 'components/RecentGroupDropdown/RecentGroupDropdown';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { RoleNames } from 'core';
import styles from './messageSegments.module.scss';
import i18n from 'i18n';
import { SessionStorageItemKeys } from 'helper/StorageHelper';
// import { MessageSegmentSetupFlowPage } from 'containers/MessageSegments/MessageSegmentSetupFlow/MessageSegmentSetupFlowPage';
// import { useCreateMessageSegmentSetupFlowPageModel, useEditMessageSegmentSetupFlowPageModel } from 'containers/MessageSegments/MessageSegmentSetupFlow/MessageSegmentSetupFlowPageModel';
import { Page } from 'components/Page/Page';
import { MessageSegmentList } from './MessageSegmentList/MessageSegmentList';
import { MessageSegmentForm } from './MessageSegmentForm/MessageSegmentForm';
import { useCreateMessageSegmentFormModel, useEditMessageSegmentFormModel } from './MessageSegmentForm/MessageSegmentFormModel';
import { partial } from 'lodash';

export const MessageSegments: React.FC<{ useModel }> = ({
  useModel
}) => {

  const {
    loading,
    agencyId,
    agencies,
    advertiserId,
    advertisers,
    setAgencyId,
    setAdvertiserId
  } = useModel();

  const renderCreateSegmentPage = (props) => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`}/>;
    return (
      <PermissionChecker permissionAware={hasFuncs(Permission.SEGMENT_WRITE)} renderOtherwise={renderRedirect}>
        <MessageSegmentForm advertisers={advertisers} useModel={useCreateMessageSegmentFormModel}/>
      </PermissionChecker>
    );
  };

  const renderEditSegmentPage = (props) => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`}/>;
    const id = props.match.params.segmentId;
    return (
      <PermissionChecker permissionAware={hasFuncs(Permission.SEGMENT_WRITE)} renderOtherwise={renderRedirect}>
        <MessageSegmentForm advertisers={advertisers} useModel={partial(useEditMessageSegmentFormModel, id)}/>
      </PermissionChecker>
    );
  };

  const renderSysAdminMessageSegmentList = () => (
    <MessageSegmentList
      isAdmin={true}
      agencyId={agencyId}
      advertiserId={advertiserId}
      canCreateSegment={advertisers.length > 0}
    />
  );

  const renderHomePage = (props) => {
    const renderFilterArea = () => {
      return (
        <div className={styles.filterArea}>
          <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
            <div className={styles.agencyList}>
              <div className={styles.title}>
                {i18n.t<string>('segmentHome.labels.agencyListTitle')}
              </div>
              <RecentGroupDropdown
                value={agencyId}
                options={agencies}
                storageKey={SessionStorageItemKeys.AGENCY}
                callback={setAgencyId}
                showShowAllBtn={false}
              />
            </div>
          </PermissionChecker>
          <PermissionChecker
            permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)}
          >
            <div className={styles.advertiserList}>
              <div className={styles.title}>
                {i18n.t<string>('segmentHome.labels.advertiserListTitle')}
              </div>
              <RecentGroupDropdown
                value={advertiserId}
                options={advertisers}
                storageKey={SessionStorageItemKeys.ADVERTISER}
                callback={setAdvertiserId}
              />
            </div>
          </PermissionChecker>
        </div>
      );
    };

    return (
      <Page
        title={i18n.t<string>('messageSegments.title')}
        renderInTopArea={renderFilterArea}
        minWidth={570}
      >
        <PermissionChecker
          permissionAware={notRoles(RoleNames.sysAdmin)}
          renderOtherwise={renderSysAdminMessageSegmentList}
        >
          <MessageSegmentList
            isAdmin={false}
            agencyId={agencyId}
            advertiserId={advertiserId}
            canCreateSegment={advertisers.length > 0}
          />
        </PermissionChecker>
      </Page>
    );
  };

  return (
    <>
    {loading && <LoadingIndicator/>}
    <Switch>
      <Route
        path={'/message-segments/new'}
        render={renderCreateSegmentPage}
      />
      <Route
        exact
        path={'/message-segments/:segmentId/edit'}
        render={renderEditSegmentPage}
      />
      <Route
        render={renderHomePage}
      />
    </Switch>
    </>
  );
};
