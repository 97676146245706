import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { CreativeManagementAction } from 'components/CampaignCreativeManagement/CreativeManagementStateFactory';
import { DownloadReportPanel } from 'components/DownloadReportPanel/DownloadReportPanel';
import { DownloadRtbCampaignGroupReportPanelModel, GroupBy } from 'components/DownloadReportPanel/DownloadReportPanelModel';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { StateModal } from 'containers/Common/StateModal';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { AddonFeatureManager } from 'core';
import { Permission } from 'core/auth/Permission';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { Order } from 'core/order/Order';
import { hasFuncs, notSelfServeAdObject } from 'core/permission/PermissionDSL';
import { ROUTE_PATH } from 'enum/RoutePath';
import i18n from 'i18n';
import _ from 'lodash';
import React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { useL1ObjectDetailModel } from './L1ObjectDetailModel';
import styles from './l1ObjectDetail.module.scss';

export const L1ObjectDetail: React.FC<{
  l1ObjectId: number,
  order: Order,
  addonFeatureManager: AddonFeatureManager
}> = ({
  l1ObjectId,
  order,
  addonFeatureManager
}) => {

  const {
    loading,
    viewData,
    currentUrl,
    redirectPath,
    l1Object,
    creativeManageModal,
    componentsData,
    l2ObjectList,
    canDonwloadRtbReport,
    modifyPermissionItem,
    showDownloadRtbReportModal,
    setShowDownloadRtbReportModal
  } = useL1ObjectDetailModel(l1ObjectId, order, addonFeatureManager);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  if (!componentsData || !l1Object) {
    return <LoadingIndicator />;
  }

  const { draftsComponent, campaignsComponent, listComponent } = componentsData;
  const renderDrafts = (props) => {
    const Component = draftsComponent.component;
    return <Component {...props} {...draftsComponent.props}/>;
  };
  const renderCampaigns = (props) => {
    const Component = campaignsComponent.component;
    return <Component {...props} {...campaignsComponent.props}/>;
  };

  const renderCampaignList = (props) => {
    const Component = listComponent.component;
    return <Component {...props} {...listComponent.props}/>;
  };

  const renderCreativeManageModal = (props) => {
    if (creativeManageModal) {
      const query = new URLSearchParams(props.location.search);
      const actionParam = query.get('action');
      const permissions = actionParam === CreativeManagementAction.MANAGE ? hasFuncs(Permission.CREATIVE_READ) : hasFuncs(Permission.CREATIVE_READ).and(notSelfServeAdObject(l1Object).and(modifyPermissionItem));
      const renderRedirect = () => <Redirect to={currentUrl} />;
      return (
        <PermissionChecker
          permissionAware={permissions}
          renderOtherwise={renderRedirect}
        >
          <StateModal
            model={creativeManageModal}
          />
        </PermissionChecker>
      );
    }
    return <div/>;
  };

  const renderContent = (props) => {
    if (_.isEmpty(l1Object)) {
      return <LoadingIndicator />;
    }

    return (
      <div className={styles.l1ObjectDetailContainer}>
        {renderCreativeManageModal(props)}
        {loading && <LoadingIndicator />}
        {showDownloadRtbReportModal &&
          <DownloadReportPanel
            model={
              new DownloadRtbCampaignGroupReportPanelModel(
                {
                  name: l1Object.name,
                  l1ObjectId: l1Object.l1ObjectId
                },
                order.startDate,
                order.endDate,
                [GroupBy.CAMPAIGN, GroupBy.CREATIVE],
                _.partial(setShowDownloadRtbReportModal, false),
                addonFeatureManager
              )
            }
          />
        }
        <TitleWithBreadcrumbs
          title={i18n.t<string>('l1Object.labels.detailTitle')}
          routes={[
            { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
            {
              path: '/orders/:orderNumber',
              breadcrumb: DynamicBreadcrumb,
              props: {
                label: _.get(order, 'projectName'),
                matchParam: 'orderNumber'
              }
            },
            {
              path: '/orders/:orderNumber/campaign-groups/:l1ObjectId',
              breadcrumb: DynamicBreadcrumb,
              props: {
                label: _.get(l1Object, 'name'),
                matchParam: 'l1ObjectId'
              }
            }
          ]}
        />
        <div className={styles.titleBottomLine} />
        <div className={styles.detail}>
          <div className={styles.topArea}>
            <div className={styles.buttonArea}>
              {l1Object.objective !== L1ObjectObjective.UNSPECIFIED &&
                <PermissionChecker permissionAware={notSelfServeAdObject(l1Object).and(modifyPermissionItem)}>
                  <Link
                    to={{
                      pathname: `${currentUrl}/edit`,
                      state: {
                        fromDetail: true,
                        l2ObjectList
                      }
                    }}
                  >
                    {i18n.t<string>('l1Object.labels.editTitle')}
                  </Link>
                </PermissionChecker>
              }
              { canDonwloadRtbReport &&
                <div onClick={_.partial(setShowDownloadRtbReportModal, true)}>
                  {i18n.t<string>('l1Object.labels.downloadReport')}
                </div>
              }
            </div>
          </div>
          <InfoDisplaySection data={viewData.basic} title=''/>
          {viewData.channelData && <InfoDisplaySection data={viewData.channelData} title=''/>}
        </div>
        <div className={styles.other}>
          {renderCampaignList(props)}
        </div>
      </div>
    );
  };

  return (
    <Switch>
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.CAMPAIGN_READ).or(modifyPermissionItem)}
        redirectPath={`${currentUrl}/campaigns/${ROUTE_PATH.ERROR403}`}
        path={`${currentUrl}/campaigns`}
        render={renderCampaigns}
      />
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.CAMPAIGN_READ).or(modifyPermissionItem)}
        redirectPath={`${currentUrl}/message-campaigns/${ROUTE_PATH.ERROR403}`}
        path={`${currentUrl}/message-campaigns`}
        render={renderCampaigns}
      />
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.CAMPAIGN_READ).or(modifyPermissionItem)}
        redirectPath={`${currentUrl}/drafts/${ROUTE_PATH.ERROR403}`}
        path={`${currentUrl}/drafts`}
        render={renderDrafts}
      />
      <Route
        render={renderContent}
      />
    </Switch>
  );
};
