import {
  AdvertiserWebService,
  RestfulAdvertiserWebService,
  AdvertisersWithPagination
} from 'ws/AdvertiserWebService';
import {
  Advertiser
} from './Advertiser';
import {
  SelectOptions
} from 'components/common/commonType';
import { Pageable } from 'ws/Pageable';
import { CompanyMember } from 'core/companyMember/CompanyMember';
import { CompanyRole } from 'core/companyMember/CompanyRole';
import { AdvertiserFBAdAccount, FbAdAccount } from 'core/fbAdAccount/FbAdAccount';
import { FbPage } from 'core/fbPage/FbPage';

export interface AdvertiserManager {
  getAdvertiser (advertiserId): Promise<Advertiser>;
  getAdvertiserCategory (advertiserId): Promise<string>;
  getAdvertiserFbPageList (advertiserId): Promise<any[]>;
  createAdvertiser (advertiser: Partial<Advertiser>): Promise<number>;
  updateAdvertiser (advertiser: Partial<Advertiser>): Promise<number>;
  getAdvertisers (
    agencyId: number | string | undefined,
    pageable: Pageable,
    search?: string): Promise<AdvertisersWithPagination>;
  getAdvertiserOptions (agencyId?: number | null): Promise<Array<SelectOptions>>;
  getAdvertiserMembers (advertiserId: number | string): Promise<Array<CompanyMember>>;
  deleteAdvertiserMembers (advertiserId: number, accountId: number): Promise<void>;
  getAdvertiserMember (advertiserId: number, accountId: number): Promise<CompanyMember>;
  createAdvertiserMember (advertiserId: number, companyRole: CompanyRole): Promise<void>;
  updateAdvertiserMember (advertiserId: number, companyRole: CompanyRole): Promise<void>;
  updateAdvertiserShareSegment (advertiserId: number, segmentSourceIds: number[]): Promise<void>;
  createAdvertiserFBAdAccount (advertiserId: number | string, fbAccountId: string): Promise<void>;
  getFBAdAccounts (): Promise<FbAdAccount[]>;
  getFBPages (): Promise<FbPage[]>;
  getAdvertiserFBAdAccounts (advertiserId: string | number): Promise<AdvertiserFBAdAccount[]>;
}

export class DefaultAdvertiserManager implements AdvertiserManager {
  webService: AdvertiserWebService;

  constructor (webService: AdvertiserWebService = new RestfulAdvertiserWebService()) {
    this.webService = webService;
  }

  async getAdvertiser (advertiserId): Promise<Advertiser> {
    return this.webService.getAdvertiser(advertiserId);
  }

  async getAdvertiserCategory (advertiserId): Promise<string> {
    return this.webService.getAdvertiserCategory(advertiserId);
  }

  async createAdvertiser (advertiser: Partial<Advertiser>): Promise<number> {
    return this.webService.createAdvertiser(advertiser);
  }

  async updateAdvertiser (advertiser: Partial<Advertiser>): Promise<number> {
    return this.webService.updateAdvertiser(advertiser);
  }

  async getAdvertisers (
    agencyId: number | string | undefined,
    pageable: Pageable,
    search?: string): Promise<AdvertisersWithPagination> {
    return this.webService.getAdvertisers(agencyId, pageable, search);
  }

  async getAdvertiserOptions (agencyId?: number): Promise<Array<SelectOptions>> {
    return this.webService.getAdvertiserOptions(agencyId);
  }

  async getAdvertiserMembers (advertiserId: number | string): Promise<Array<CompanyMember>> {
    return this.webService.getAdvertiserMembers(advertiserId);
  }

  async deleteAdvertiserMembers (advertiserId: number, accountId: number): Promise<void> {
    return this.webService.deleteAdvertiserMembers(advertiserId, accountId);
  }

  async getAdvertiserMember (advertiserId: number, accountId: number): Promise<CompanyMember> {
    return this.webService.getAdvertiserMember(advertiserId, accountId);
  }

  async createAdvertiserMember (advertiserId: number, companyRole: CompanyRole): Promise<void> {
    return this.webService.createAdvertiserMember(advertiserId, companyRole);

  }

  async updateAdvertiserMember (advertiserId: number, companyRole: CompanyRole): Promise<void> {
    return this.webService.updateAdvertiserMember(advertiserId, companyRole);
  }

  async updateAdvertiserShareSegment (advertiserId: number, segmentSourceIds: number[]): Promise<void> {
    return this.webService.updateAdvertiserShareSegment(advertiserId, segmentSourceIds);
  }

  async createAdvertiserFBAdAccount (advertiserId: number | string, fbAccountId: string): Promise<void> {
    return this.webService.createAdvertiserFBAdAccount(advertiserId, fbAccountId);
  }

  async getFBAdAccounts (): Promise<FbAdAccount[]> {
    return this.webService.getFBAdAccounts();
  }

  async getAdvertiserFBAdAccounts (advertiserId: string | number): Promise<AdvertiserFBAdAccount[]> {
    return this.webService.getAdvertiserFBAdAccounts(advertiserId);
  }

  async getAdvertiserFbPageList (advertiserId): Promise<any[]> {
    return this.webService.getAdvertiserFbPageList(advertiserId);
  }

  async getFBPages (): Promise<FbPage[]> {
    return this.webService.getFBPages();
  }
}
