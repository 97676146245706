import React, { Fragment } from 'react';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';
import styles from './videoInput.module.scss';
import videoPlaceholder from 'assets/video-placeholder.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18next';
import classnames from 'classnames/bind';
import { VideoPreview } from './VideoPreview';

export class VideoInput extends React.Component<any, any> {

  inputRef: any;
  videoRef: any;
  videoReader: any;
  classNames: any;

  constructor (props) {
    super(props);
    this.videoRef = React.createRef();
    this.inputRef = React.createRef();
    this.classNames = classnames.bind(styles);
    this.state = {
      previewSrc: undefined,
      hovering: false
    };
  }

  componentDidMount () {
    if (this.props.file) {
      this.setState({
        previewSrc: URL.createObjectURL(this.props.file),
        hovering: false
      }, () => {
        this.videoRef.current && this.videoRef.current.load();
      });
    } else if (this.props.url) {
      this.setState({
        previewSrc: this.props.url,
        hovering: false
      });
    }
  }

  componentDidUpdate (prevProps): void {
    if (this.props.file !== prevProps.file && this.props.file) {
      this.setState({
        previewSrc: URL.createObjectURL(this.props.file),
        hovering: false
      }, () => {
        this.videoRef.current && this.videoRef.current.load();
      });
    } else if (this.props.url !== prevProps.url && this.props.url) {
      this.setState({
        previewSrc: this.props.url,
        hovering: false
      });
    } else if (!this.props.file && !this.props.url && this.state.previewSrc) {
      this.setState({
        previewSrc: undefined,
        hovering: false
      });
    }
  }

  onVideoChange = (event) => {
    const files = _.get(event, 'currentTarget.files', []);
    const videoFile = files[0];
    if (!videoFile) {
      return;
    }
    this.props.onChange && this.props.onChange(videoFile);
    event.target.value = null;
  }

  onPreviewAreaMouseEnter = () => {
    this.setState({ hovering: true });
  }

  onPreviewAreaMouseLeave = () => {
    this.setState({ hovering: false });
  }

  onClearBtnClicked = (e) => {
    this.props.onChange && this.props.onChange(undefined);
    this.setState({
      previewSrc: undefined,
      hovering: false
    });
    e.stopPropagation();
  }

  onDragOver = (event) => {
    event.dataTransfer.dropEffect = 'copy';
    event.preventDefault();
  }

  onDragEnter = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  onVideoDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const files = _.get(event, 'dataTransfer.files', []);
    const file = files[0];
    if (!file) {
      return;
    }

    this.props.onChange && this.props.onChange(file);
    const validTypes = ['video/mp4'];
    if (validTypes.indexOf(file.type) === -1) {
      this.setState({
        previewSrc: undefined,
        hovering: false
      });
      return;
    }

    this.setState({
      previewSrc: URL.createObjectURL(file),
      hovering: false
    }, () => {
      this.videoRef.current && this.videoRef.current.load();
    });
  }

  onPlaceholderClick = () => {
    this.inputRef.current && this.inputRef.current.click();
  }

  renderPlaceholder = () => {
    return (
      <>
        <div
          style={{ height: this.state.previewSrc ? 'calc(100% - 75px)' : '100%' }}
          className={styles.interactiveLayer}
          onDragOver={this.onDragOver}
          onDragEnter={this.onDragEnter}
          onDrop={this.onVideoDrop}
          onClick={this.onPlaceholderClick}
        />
        <div className={styles.videoPlaceholder}>
          <img src={videoPlaceholder} alt={'preview area'} />
          <div className={styles.hint}>{i18n.t<string>('creativeSetupFlow.labels.dragdropHint')}</div>
        </div>
      </>
    );
  }

  renderVideoPreview = () => {
    return (
      <div
        className={styles.previewArea}
        onMouseEnter={this.onPreviewAreaMouseEnter}
        onMouseLeave={this.onPreviewAreaMouseLeave}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDrop={this.onVideoDrop}
      >
        {
          this.state.hovering &&
          <Fragment>
            <div className={styles.mask} />
            {this.renderPlaceholder()}
            <div className={styles.closeBtnContainer} onClick={this.onClearBtnClicked}>
              <FontAwesomeIcon
                className={styles.closeBtn}
                icon={faTimes}
              />
            </div>
          </Fragment>
        }
        <VideoPreview
          ref={this.videoRef}
          src={this.state.previewSrc}
          maxWidth={this.props.maxWidth}
          maxHeight={this.props.maxHeight}
        />
      </div>
    );
  }

  render () {
    const pickProps = _.pick(this.props, [
      'disabled',
      'name'
    ]);
    const classNames = this.classNames('videoInputContainer', {
      [this.props.className]: !!this.props.className,
      isEmpty: !this.state.previewSrc
    });
    return (
      <div className={classNames}>
        <FormControl
          accept='video/mp4'
          className={styles.videoInput}
          type='file'
          {...pickProps}
          ref={this.inputRef}
          onChange={this.onVideoChange}
        />
        <div className={styles.videoPreview}>
          {
            this.state.previewSrc ?
              this.renderVideoPreview() :
              this.renderPlaceholder()
          }
        </div>
      </div>
    );
  }
}
