import { L1Object, L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { ADSET_DEFAULT_AGE_MAX, ADSET_DEFAULT_AGE_MIN, FbBillingEvent, FbAdSet, FbAppEvent, FbPacingType } from 'core/fbAdSet/FbAdSet';
import { FinalReportGender, Order } from 'core/order/Order';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultFbAdSetManager, FbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import i18n from 'i18n';
import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { DefaultLimitationManager, LimitationManager } from 'core/limitation/LimitationManager';
import { getAlpha2ByAlpha3 } from 'utils/CountryUtil';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { useCallAPI } from 'hooks/useCallAPI';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { getDayPartSummary, getInitDaypart } from 'components/Dayparts/Dayparts';
import { DefaultEditLimitationModel, EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { useCoreContext } from 'contexts/coreContext';
import { OPERATE } from 'enum/Operate';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import { SavedTargeting } from 'core/limitation/Limitation';
import { useGetSavedTargeting } from 'hooks/useGetSavedTargeting';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { renderFBAdSetSpendLimit } from './steps/FbAdSetSummaryStepRenderFunctions';
import { formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { validateEmpty, validateMinimum, validateMinMax } from 'utils/ValidateUtils';
import { renderIncreaseFBCampaignBudgetHint, renderOverBudgetWording } from 'containers/L2Objects/FormHintRenderFunctions';
import { FbAdSetFormModelData, useFbCreateAdSetFormModel, useFbEditAdSetFormModel } from './steps/FbAdSetFormModel';
import { FbAdSetDraftManager, DraftManager } from 'core/draft/DraftManager';
import { toast } from 'react-toastify';
import { DefaultL2ObjectManager, L2ObjectManager } from 'core/l2Object/L2ObjectManager';
import { getAdSetLimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/adSetLimitationInventorySettings';

const MAIN_STEP_INDEX = 0;

export enum FbAdSetMainStepTab {
  BASIC,
  TARGETINIG
}

export type FbAdSetFormData = {
  name: string;
  account_id: number;
  lifetime_budget?: number | string;
  bid_strategy: BidStrategy;
  bid_amount?: number | string;
  optimization_goal?: L2ObjectOptimizationGoal;
  billing_event?: FbBillingEvent;
  start_time: string;
  end_time: string;
  budget_remaining?: number | string;
  targeting?: {
    [key: string]: any
  },
  hasSpendLimits: boolean,
  lifetime_min_spend_target?: number | string,
  lifetime_spend_cap?: number | string,
  frequency_control_specs?: any,
  promoted_object?: any,
  destination_type?: string,
  dayPart?: { [key: string]: string[] | number[] | string };
  pacing_type?: FbPacingType;
};

export type AdSetSetupFlowPageModelData = {
  title: string,
  objectType: 'adSet' | 'draft',
  loading: boolean,
  finished: boolean,
  fbCountries: SelectOptions[],
  goSegments: SelectOptions[],
  adSet: FbAdSetFormData | undefined,
  initAdSet: FbAdSetFormData | undefined,
  redirectPath: string | undefined,
  canEditOptimizationGoal: boolean,
  otherAdsetMinBudget: number,
  breadcrumbs: any[],
  limitationModel?: EditLimitationModel,
  audienceLowestThreshold: number,
  savedTAList: SavedTargeting[],
  appliedSavedTAInfo?: SelectOptions,
  showTAManagement: boolean,
  estimatedData?: EstimatedAudience,
  showPublishBindingFailed: boolean,
  defaultOptimizationGoal?: L2ObjectOptimizationGoal,
  showAddSaveTargetingModal: boolean;
  setFinished: (finish: any) => any,
  validate: (adSet: FbAdSetFormData) => any,
  getSummaryData: (adSet: FbAdSetFormData) => any,
  getTASummaryData: (limitationValue: any) => any,
  setEsitimatedData: (estimatedData: EstimatedAudience) => void,
  onImportSavedTA: (savedTAInfo: SelectOptions, limitationValue) => void,
  onDeleteSavedTA: (deletedTAId: number) => void,
  setShowTAManagement: (show: boolean) => void,
  submit: () => Promise<void>,
  setAdSet: (adSet: FbAdSetFormData) => void,
  setInitAdSet: (adSet: FbAdSetFormData) => void,
  backToL1ObjectDetail: () => void,
  setRedirectPath: (redirectPath?: string) => void,
  onSaveDraft?: (adSet: FbAdSetFormData) => void,
  adSetFormModel: (order: Order, l1Object: L1Object, adSet: FbAdSetFormData) => FbAdSetFormModelData
  onShowAddSaveTargetingModal: (showModal: boolean) => void;
};

const defaultFbAdSetManager = new DefaultFbAdSetManager();
const defaultDraftManager = new FbAdSetDraftManager();
const defaultAdRequestSourceManager = new DefaultAdRequestSourceManager();
const defaultLimitationManager = new DefaultLimitationManager();
const defaultL2ObjectManager = new DefaultL2ObjectManager();

const useFbAdSetSetupFlowPageModel = (
  order: Order,
  l1Object: L1Object,
  otherFbAdSetList: FbAdSet[],
  fbAdSetManager: FbAdSetManager,
  adRequestSourceManager: AdRequestSourceManager,
  limitationManager: LimitationManager
) => {

  const [appliedSavedTAInfo, setAppliedSavedTAInfo] = useState<SelectOptions | undefined>();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [finished, setFinished] = useState<boolean>(false);
  const [fbCountries, setFbCountries] = useState<SelectOptions[]>([]);
  const [goSegments, setGoSegments] = useState<SelectOptions[]>([]);
  const [estimatedData, setEsitimatedData] = useState<EstimatedAudience | undefined>();
  const [showAddSaveTargetingModal, setShowAddSaveTargetingModal] = useState<boolean>(false);
  const {
    loading,
    callAPIs
  } = useCallAPI();

  const [showTAManagement, setShowTAManagement] = useState(false);

  const fetchFbCountries = useCallback(async () => {
    try {
      const fbCountries = await adRequestSourceManager.getFBCountries();
      setFbCountries(fbCountries);
      return fbCountries;
    } catch (e) {}
  }, [adRequestSourceManager]);

  const fetchFbSegments = useCallback(async () => {
    try {
      const goSegments = await adRequestSourceManager.getGoSegments(order.advertiserId, L1ObjectChannel.FB);
      setGoSegments(goSegments);
    } catch (e) {}
  }, [order.advertiserId, adRequestSourceManager]);

  const backToL1ObjectDetail = () => {
    const redirectPath =
      `/orders/${order.orderNumber}/campaign-groups/${l1Object.l1ObjectId}`;
    setRedirectPath(redirectPath);
  };

  const otherAdsetMinBudget = useMemo(() => {
    const otherList = _.defaultTo(otherFbAdSetList, []);
    const minBudget = otherList.reduce((acc, fbAdSet) => {
      const startDateMoment = moment(fbAdSet.start_time);
      const endDateMoment = moment(fbAdSet.end_time);
      const scheduleDateCount = endDateMoment.diff(startDateMoment, 'days') + 1;
      const parentBidStrategy: string | undefined = _.get(l1Object, 'fb.bid_strategy');
      return acc + Math.max(
        fbAdSetManager.getMinBudgetOfFBObject(
          l1Object.currencyRate,
          +_.defaultTo(fbAdSet.bid_amount, 1),
          _.defaultTo(parentBidStrategy, fbAdSet.bid_strategy) as BidStrategy,
          fbAdSet.billing_event,
          scheduleDateCount,
          order
        ),
        +_.defaultTo(fbAdSet.lifetime_min_spend_target, 0)
      );
    }, 0);

    return minBudget;
  }, [otherFbAdSetList, l1Object, order, fbAdSetManager]);

  const {
    loading: loadingSavedTAList,
    savedTAList,
    fetchSavedTargeting
  } = useGetSavedTargeting(order.advertiserId, L1ObjectChannel.FB, order.orderType, limitationManager);

  const validate = (initAdSet, minBudgetFromServer: number | undefined, value: FbAdSetFormData) => {
    const getAdSetTotalDays = (startTime, endTime) => {
      const startDateMoment = moment(startTime);
      const endDateMoment = moment(endTime);
      return endDateMoment.diff(startDateMoment, 'days') + 1;
    };
    const parentBidStrategy: string | undefined = _.get(l1Object, 'fb.bid_strategy');
    const adSetBidStrategy = _.get(value, 'bid_strategy');
    const bidAmount = +(_.get(value, 'bid_amount', 0).toString());
    const bidStrategy = _.get(value, 'bid_strategy', _.defaultTo(parentBidStrategy, BidStrategy.LOWEST_COST_WITHOUT_CAP) as BidStrategy);
    const billingEvent = _.get(value, 'billing_event', FbBillingEvent.IMPRESSIONS);
    const scheduleDateCount = getAdSetTotalDays(value.start_time, value.end_time);
    const minBudget = fbAdSetManager.getMinBudgetOfFBObject(
      l1Object.currencyRate,
      bidAmount,
      bidStrategy,
      billingEvent,
      scheduleDateCount,
      order,
      minBudgetFromServer
    );

    const shouldCheckBidAmount = parentBidStrategy === BidStrategy.LOWEST_COST_WITH_BID_CAP || adSetBidStrategy === BidStrategy.LOWEST_COST_WITH_BID_CAP;
    const shouldCheckFrequencyControl = _.get(l1Object, 'fb.objective') === L1ObjectObjective.AWARENESS &&
      value.optimization_goal === L2ObjectOptimizationGoal.REACH;
    const hasSpendLimits = l1Object.autoOptimise && value.hasSpendLimits;
    return _.omitBy({
      name: validateEmpty(value.name),
      lifetime_budget: l1Object.budget ?
        validateCampaignBudget(value, order, l1Object, minBudget, otherAdsetMinBudget) :
        validateBudget(order, l1Object, initAdSet, value, minBudget),
      bid_amount: shouldCheckBidAmount ?
          validateMinimum(value.bid_amount, 1, 'campaign.descriptions.priceMinimum', order.currency) :
          undefined,
      frequency_control_specs: shouldCheckFrequencyControl ?
        validateFrequencyControlSpecs(value.frequency_control_specs) :
        undefined,
      lifetime_min_spend_target: hasSpendLimits ?
        validateLifetimeMinSpendTarget(value, order.currency, minBudget) :
        undefined,
      lifetime_spend_cap: hasSpendLimits ?
        validateLifetimeSpendCap(value, order.currency, minBudget) :
        undefined
    }, _.isEmpty);
  };

  const onDeleteSavedTA = (deletedTAId: number) => {
    if (_.get(appliedSavedTAInfo, 'value') === deletedTAId) {
      setAppliedSavedTAInfo(undefined);
    }
    fetchSavedTargeting();
  };

  const onShowAddSaveTargetingModal = (showModal: boolean) => {
    setShowAddSaveTargetingModal(showModal);
  };

  return {
    loading: loading || loadingSavedTAList,
    savedTAList,
    fbCountries,
    redirectPath,
    finished,
    goSegments,
    otherAdsetMinBudget,
    audienceLowestThreshold: 2000000,
    appliedSavedTAInfo,
    estimatedData,
    showTAManagement,
    validate,
    setEsitimatedData,
    callAPIs,
    setFinished,
    getSummaryData: _.partial(getSummaryData, l1Object, order.currency, fbAdSetManager),
    onDeleteSavedTA,
    setRedirectPath,
    fetchFbCountries,
    fetchFbSegments,
    getTASummaryData: _.partial(getTASummaryData, fbAdSetManager),
    setShowTAManagement,
    backToL1ObjectDetail,
    setAppliedSavedTAInfo,
    showAddSaveTargetingModal,
    onShowAddSaveTargetingModal
  };
};

export const useCreateFbAdSetSetupFlowPageModel = (
  order: Order,
  l1Object: L1Object,
  fbAdSetList: FbAdSet[],
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager,
  limitationManager: LimitationManager = defaultLimitationManager,
  l2ObjectManager: L2ObjectManager = defaultL2ObjectManager
): AdSetSetupFlowPageModelData => {

  const {
    validate,
    callAPIs,
    fetchFbCountries,
    fetchFbSegments,
    setShowTAManagement,
    setAppliedSavedTAInfo,
    fbCountries,
    goSegments,
    otherAdsetMinBudget,
    audienceLowestThreshold,
    ...basicProps
  } = useFbAdSetSetupFlowPageModel(order, l1Object, fbAdSetList, fbAdSetManager, adRequestSourceManager, limitationManager);

  const defaultOptimizationGoal = useMemo(() => {
    const allList = _.defaultTo(fbAdSetList, []);
    const notDraftList = allList.filter(adSet => !adSet.draftId);
    if (notDraftList.length === 0 || !l1Object.autoOptimise) {
      return undefined;
    }
    return notDraftList[0].optimization_goal;
  }, [l1Object, fbAdSetList]);

  const defaultAdSet = getDefaultFbAdSet(order, l1Object, fbAdSetList, fbAdSetManager, l2ObjectManager);
  const [adSet, setAdSet] = useState<FbAdSetFormData>(defaultAdSet);
  const [initAdSet, setInitAdSet] = useState(adSet);

  const fetchLimitationPreset = useCallback(async () => {
    try {
      return await limitationManager.getLimitationPreSet('campaign');
    } catch (e) {}
  }, [limitationManager]);

  useEffect(() => {
    callAPIs([fetchFbCountries, fetchLimitationPreset, fetchFbSegments], (fbCountries, limitationPreset) => {
      if (fbCountries) {
        const changeAdSetState = _.partial(onFbCountriesFetched, fbCountries, limitationPreset);
        setAdSet(changeAdSetState);
        setInitAdSet(changeAdSetState);
      }
    });
  }, [fetchFbCountries, fetchFbSegments, fetchLimitationPreset, callAPIs]);

  useEffect(() => {
    const changeAdSetState = adSet => ({
      ...adSet,
      optimization_goal: _.defaultTo(defaultOptimizationGoal, fbAdSetManager.getDefaultOptimizationGoal(l1Object))
    });
    setAdSet(changeAdSetState);
    setInitAdSet(changeAdSetState);
  }, [defaultOptimizationGoal, l1Object, fbAdSetManager]);

  const submit = async () => {
    callAPIs([fbAdSetManager.createAdSet.bind(fbAdSetManager, order, l1Object, adSet)], () => {
      basicProps.setFinished(true);
      basicProps.backToL1ObjectDetail();
    });
  };

  const core = useCoreContext();
  const limitationModel = useMemo(() => {
    if (!core || !adSet.targeting) {
      return;
    }
    return new DefaultEditLimitationModel(
      getAdSetLimitationInventorySettings('create', adSet.optimization_goal, fbCountries, goSegments, order.orderType),
      adSet.targeting,
      {
        need: [OPERATE.INCLUDE],
        notNeed: [OPERATE.EXCLUDE]
      },
      core.addonFeatureManager.addonFeature,
      _.partial(setShowTAManagement, true),
      {
        channel: L1ObjectChannel.FB,
        audienceLowestThreshold,
        estimatedRequiredFields: ['geo_locations'],
        channelTargetingGetter: (limitationValue) => fbAdSetManager.wrapTargetingForServer(limitationValue),
        accountId: adSet.account_id.toString()
      }
    );
  }, [adSet.account_id, adSet.optimization_goal, adSet.targeting, core, fbAdSetManager, fbCountries, goSegments, audienceLowestThreshold, setShowTAManagement, order.orderType]);

  const onImportSavedTA = (savedTAInfo: SelectOptions, limitationValue) => {
    if (!limitationModel) {
      return;
    }
    setAppliedSavedTAInfo(savedTAInfo);
    limitationModel.updateLimitationValue(limitationValue);
  };

  return {
    ...basicProps,
    objectType: 'adSet',
    adSet,
    limitationModel,
    initAdSet,
    fbCountries,
    goSegments,
    otherAdsetMinBudget,
    audienceLowestThreshold,
    title: i18n.t<string>('adSetSetupFlow.mainStep.createTitle'),
    defaultOptimizationGoal,
    canEditOptimizationGoal: !defaultOptimizationGoal,
    showPublishBindingFailed: false,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:l1ObjectId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(l1Object, 'name'),
          matchParam: 'l1ObjectId'
        }
      },
      { path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/campaigns/new', breadcrumb: i18n.t<string>('adSetSetupFlow.mainStep.createTitle') }
    ],
    adSetFormModel: useFbCreateAdSetFormModel,
    submit,
    setAdSet,
    setInitAdSet,
    validate: _.partial(validate, initAdSet, undefined),
    onImportSavedTA,
    setShowTAManagement
  };
};

export const useEditFbAdSetSetupFlowPageModel = (
  order: Order,
  l1Object: L1Object,
  fbAdSetList: FbAdSet[],
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager,
  limitationManager: LimitationManager = defaultLimitationManager
): AdSetSetupFlowPageModelData => {

  const match = useRouteMatch<{adSetId: string}>();
  const adSetId = match.params.adSetId;
  const {
    validate,
    callAPIs,
    fetchFbCountries,
    fetchFbSegments,
    setRedirectPath,
    setShowTAManagement,
    setAppliedSavedTAInfo,
    fbCountries,
    goSegments,
    otherAdsetMinBudget,
    audienceLowestThreshold,
    ...basicProps
  } = useFbAdSetSetupFlowPageModel(order, l1Object, fbAdSetList.filter(fbAdSet => fbAdSet.id.toString() !== adSetId), fbAdSetManager, adRequestSourceManager, limitationManager);

  const [adSet, setAdSet] = useState<FbAdSetFormData | undefined>(undefined);
  const [initAdSet, setInitAdSet] = useState(adSet);
  const [minBudget, setMinBudget] = useState<number | undefined>(undefined);
  const parentBidStrategy: string | undefined = _.get(l1Object, 'fb.bid_strategy');

  useEffect(() => {
    if (!adSetId) {
      return;
    }
    callAPIs([
      fbAdSetManager.getAdSet.bind(fbAdSetManager, adSetId),
      fbAdSetManager.getMinBudget.bind(fbAdSetManager, adSetId),
      fetchFbCountries,
      fetchFbSegments
    ], (fbAdSet, minBudget) => {
      const hasSpendLimits = !!fbAdSet.lifetime_min_spend_target || !!fbAdSet.lifetime_spend_cap;
      const adSetFormData = {
        account_id: _.get(l1Object, 'fb.account_id'),
        ...fbAdSet,
        bid_amount: fbAdSet.bid_amount ? fbAdSet.bid_amount : '',
        bid_strategy: parentBidStrategy ? parentBidStrategy as BidStrategy : fbAdSet.bid_strategy,
        hasSpendLimits,
        lifetime_min_spend_target: hasSpendLimits && fbAdSet.lifetime_min_spend_target ? fbAdSet.lifetime_min_spend_target : '',
        lifetime_spend_cap: hasSpendLimits && fbAdSet.lifetime_spend_cap ? fbAdSet.lifetime_spend_cap : '',
        dayPart: fbAdSet.dayPart,
        pacing_type: _.get(fbAdSet, 'pacing_type[0]')
      };
      setAdSet(adSetFormData);
      setInitAdSet(adSetFormData);
      setMinBudget(minBudget);
      if (l1Object.l1ObjectId !== fbAdSet.l1ObjectId || ['DELETED', 'ARCHIVED'].includes(fbAdSet.configured_status.toString())) {
        const redirectPath =
          `/orders/${order.orderNumber}/campaign-groups/${l1Object.l1ObjectId}/campaigns/${adSetId}/edit/error404`;
        setRedirectPath(redirectPath);
      }
    });
  }, [l1Object, adSetId, fbAdSetManager, fetchFbCountries, fetchFbSegments, callAPIs, parentBidStrategy, order.orderNumber, setRedirectPath]);

  const submit = async () => {
    if (!adSet) {
      return;
    }
    callAPIs([() => fbAdSetManager.editAdSet(order.id, l1Object, adSetId, adSet)], () => {
      basicProps.setFinished(true);
      basicProps.backToL1ObjectDetail();
    });
  };

  const core = useCoreContext();
  const optimizationGoal = _.get(adSet, 'optimization_goal');
  const targeting = _.get(adSet, 'targeting');
  const accountId = _.get(adSet, 'account_id');
  const limitationModel = useMemo(() => {
    return core && targeting && accountId ? new DefaultEditLimitationModel(
      getAdSetLimitationInventorySettings('create', optimizationGoal, fbCountries, goSegments, order.orderType),
      targeting,
      {
        need: [OPERATE.INCLUDE],
        notNeed: [OPERATE.EXCLUDE]
      },
      core.addonFeatureManager.addonFeature,
      _.partial(setShowTAManagement, true),
      {
        channel: L1ObjectChannel.FB,
        audienceLowestThreshold,
        estimatedRequiredFields: ['geo_locations'],
        channelTargetingGetter: (limitationValue) => fbAdSetManager.wrapTargetingForServer(limitationValue),
        accountId: accountId.toString()
      }
    ) : undefined;
  }, [core, targeting, accountId, optimizationGoal, fbCountries, goSegments, setShowTAManagement, audienceLowestThreshold, fbAdSetManager, order.orderType]);

  const onImportSavedTA = (savedTAInfo: SelectOptions, limitationValue) => {
    if (!limitationModel) {
      return;
    }
    setAppliedSavedTAInfo(savedTAInfo);
    limitationModel.updateLimitationValue(limitationValue);
  };

  return {
    ...basicProps,
    objectType: 'adSet',
    adSet,
    initAdSet,
    fbCountries,
    goSegments,
    limitationModel,
    audienceLowestThreshold,
    title: i18n.t<string>('adSetSetupFlow.mainStep.editTitle'),
    canEditOptimizationGoal: false,
    otherAdsetMinBudget,
    showPublishBindingFailed: false,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:l1ObjectId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(l1Object, 'name'),
          matchParam: 'l1ObjectId'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/campaigns/:adSetId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t<string>('common.labels.edit'),
          label: _.get(initAdSet, 'name'),
          matchParam: 'adSetId'
        }
      }
    ],
    adSetFormModel: useFbEditAdSetFormModel,
    submit,
    setAdSet,
    setInitAdSet,
    validate: _.partial(validate, initAdSet, minBudget),
    setRedirectPath,
    onImportSavedTA,
    setShowTAManagement
  };
};

export const useEditFbAdSetDraftSetupFlowPageModel = (
  order: Order,
  l1Object: L1Object,
  fbAdSetList: FbAdSet[],
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager,
  draftManager: DraftManager = defaultDraftManager
): AdSetSetupFlowPageModelData => {

  const match = useRouteMatch<{draftId: string}>();
  const draftId = match.params.draftId;

  const data = useCreateFbAdSetSetupFlowPageModel(
    order,
    l1Object,
    fbAdSetList
  );

  const { loading, callAPIs } = useCallAPI();
  const { defaultOptimizationGoal, setAdSet, setInitAdSet } = data;
  const [showPublishBindingFailed, setShowPublishBindingFailed] = useState(false);

  useEffect(() => {
    callAPIs([() => draftManager.getDraft(draftId)], (draft) => {
      const optimizationGoalOptions = fbAdSetManager.getOptimizationGoalOptions(l1Object);
      const optimizeGoalOption = optimizationGoalOptions.find(option => option.value === draft.optimization_goal);
      const optimizeGoal = defaultOptimizationGoal ?
        defaultOptimizationGoal :
        optimizeGoalOption ?
          optimizeGoalOption.value :
          fbAdSetManager.getDefaultOptimizationGoal(l1Object);
      const billingEventOptions = fbAdSetManager.getBilliingEventOptions(optimizeGoal);
      const billingEventOption = billingEventOptions.find(option => option.value === draft.billing_event);
      const billingEvent = billingEventOption ?
        billingEventOption.value :
        fbAdSetManager.getDefaultBillingEvent(l1Object);
      const strategy = _.get(l1Object, 'fb.bid_strategy', draft.bid_strategy);
      const adSet = {
        ...draft,
        optimization_goal: optimizeGoal,
        billing_event: billingEvent,
        bid_strategy: strategy ? strategy : BidStrategy.LOWEST_COST_WITHOUT_CAP
      };
      setAdSet(adSet);
      setInitAdSet(adSet);
    });
  }, [draftId, draftManager, setAdSet, setInitAdSet, callAPIs, l1Object, defaultOptimizationGoal, fbAdSetManager]);

  const onSaveDraft = (adSet: FbAdSetFormData) => {
    callAPIs([() => draftManager.updateDraft(draftId, fbAdSetManager.getCreateAdSetPayloadForServer(
      order.id,
      order.orderType,
      l1Object,
      adSet
    ))], () => {
      toast.success(i18n.t<string>('adSetSetupFlow.messages.updateDraftSuccess'));
    }, () => {
      toast.error(i18n.t<string>('adSetSetupFlow.messages.updateDraftFailed'));
    });
  };

  const onPublishDraft = async () => {
    const adSet = data.adSet;
    if (!adSet) {
      return;
    }
    callAPIs([draftManager.publishDraft.bind(draftManager, draftId, fbAdSetManager.getCreateAdSetPayloadForServer(
      order.id,
      order.orderType,
      l1Object,
      adSet
    ))], ({
      includeBinding,
      result
    }) => {
      if (result === 'SUCCESS') {
        if (includeBinding) {
          toast.success(i18n.t<string>('adSetSetupFlow.messages.publishL2L3Success'));
        } else {
          toast.success(i18n.t<string>('adSetSetupFlow.messages.publishL2Success'));
        }
        data.setFinished(true);
        data.backToL1ObjectDetail();
      }
      if (result === 'PARTIAL_SUCCESS') {
        setShowPublishBindingFailed(true);
      } else if (result === 'FAIL') {
        toast.error(i18n.t<string>('adSetSetupFlow.messages.publishL2L3Failed'));
      }
    });
  };

  return {
    ...data,
    objectType: 'draft',
    loading: data.loading || loading,
    title: i18n.t<string>('campaign.labels.editCampaignDraftTitle'),
    showPublishBindingFailed,
    onSaveDraft,
    submit: onPublishDraft,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:l1ObjectId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(l1Object, 'name'),
          matchParam: 'l1ObjectId'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/drafts/:draftId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t<string>('common.labels.edit'),
          label: _.get(data.initAdSet, 'name'),
          matchParam: 'draftId'
        }
      }
    ],
    getSummaryData: (adSet: any) => {
      const summaryData = data.getSummaryData(adSet);
      if (adSet.bindings && adSet.bindings.length > 0) {
        const now = moment();
        const isDeliverying = now.isAfter(moment(adSet.start_time)) && now.isBefore(moment(adSet.end_time));
        const isActive = adSet.configured_status === 'ACTIVE';
        const hasActiveBinding = adSet.bindings.find(binding => binding.active) !== undefined;
        const showAlert = isDeliverying && isActive && hasActiveBinding;
        summaryData['draftBindingSummary'] = {
          title: i18n.t<string>('adSetSetupFlow.summaryStep.draftBindingTitle'),
          data: {
            draftBinding: {
              content: _.compact([
                {
                  label: i18n.t<string>('adSetSetupFlow.summaryStep.labels.draftBinding'),
                  value: adSet.bindings.length,
                  hintColor: 'red',
                  hint: showAlert ? i18n.t<string>('adSetSetupFlow.summaryStep.message.willDeliverImmediately') : undefined
                }
              ])
            }
          }
        };
      }
      return summaryData;
    }
  };
};

const getDefaultFbAdSet = (order, l1Object, fbAdSetList, fbAdSetManager, l2ObjectManager: L2ObjectManager) => {
  const allList = _.defaultTo(fbAdSetList, []);
  const notDraftList = allList.filter(adSet => !adSet.draftId);
  let defaultOptimizationGoal;
  if (notDraftList.length > 0 && l1Object.autoOptimise) {
    defaultOptimizationGoal = notDraftList[0].optimization_goal;
  }

  let defaultDestinationType;
  if (allList.length > 0) {
    defaultDestinationType = fbAdSetList[0].destination_type;
  }

  const orderAgeMin: number = +_.defaultTo(order.ageMin, ADSET_DEFAULT_AGE_MIN);
  const orderAgeMax: number = +_.defaultTo(order.ageMax, ADSET_DEFAULT_AGE_MAX);
  const orderGender: FinalReportGender = _.defaultTo(order.gender, FinalReportGender.ALL);

  const initAgeAndGender = {
    ageMin: orderAgeMin,
    ageMax: orderAgeMax,
    gender: orderGender
  };

  const initDateRange = l2ObjectManager.getInitDateRange(order);

  const optimizationGoal = _.defaultTo(defaultOptimizationGoal, fbAdSetManager.getDefaultOptimizationGoal(l1Object));
  const destinationType = _.defaultTo(defaultDestinationType, fbAdSetManager.getDefaultDestinationType(l1Object));
  const parentBidStrategy: string | undefined = _.get(l1Object, 'fb.bid_strategy');
  let defaultUserOsTA: any = undefined;
  let defaultPromotedObject: any = undefined;
  if (optimizationGoal === L2ObjectOptimizationGoal.SALES) {
    defaultUserOsTA = {
      type: 'user_os',
      value: [{
        label: 'Android',
        value: 'Android'
      }]
    };
    defaultPromotedObject = {
      custom_event_type: FbAppEvent.PURCHASE
    };
  }

  let defaultDayPart: any = undefined;
  let defaultPacingType: FbPacingType | undefined = FbPacingType.STANDARD;
  if (l1Object.autoOptimise) {
    defaultDayPart = getInitDaypart();
    defaultPacingType = undefined;
  }

  return {
    name: l1Object.name,
    account_id: +_.get(l1Object, 'fb.account_id', ''),
    start_time: initDateRange.startTime,
    end_time: initDateRange.endTime,
    bid_strategy: _.defaultTo(parentBidStrategy, BidStrategy.LOWEST_COST_WITHOUT_CAP) as BidStrategy,
    bid_amount: '',
    lifetime_budget: '',
    optimization_goal: optimizationGoal,
    billing_event: fbAdSetManager.getDefaultBillingEvent(l1Object),
    hasSpendLimits: false,
    frequency_control_specs: _.get(l1Object, 'fb.objective') === L1ObjectObjective.AWARENESS && optimizationGoal === L2ObjectOptimizationGoal.REACH ? {
      event: 'IMPRESSIONS',
      interval_days: 7,
      max_frequency: 1
    } : undefined,
    targeting: {
      include: _.compact([
        defaultUserOsTA,
        {
          type: 'age_min',
          value: initAgeAndGender.ageMin
        },
        {
          type: 'age_max',
          value: initAgeAndGender.ageMax
        },
        initAgeAndGender.gender !== FinalReportGender.ALL ? {
          type: 'genders',
          value: initAgeAndGender.gender
        } : undefined
      ])
    },
    promoted_object: defaultPromotedObject,
    destination_type: destinationType,
    dayPart: defaultDayPart,
    pacing_type: defaultPacingType
  };
};

const getTASummaryData = (fbAdSetManager, targeting) => {
  const summaryData = fbAdSetManager.getTASummaryData(targeting);
  return _.omitBy({
    general: {
      title: i18n.t<string>('campaignSummary.titles.general'),
      titlePrefixColor: SummaryTitleColor.DARK,
      content: summaryData.general
    },
    include: {
      title: i18n.t<string>('campaignSummary.titles.inc'),
      titlePrefixColor: SummaryTitleColor.GREEN,
      content: summaryData.include
    },
    exclude: {
      title: i18n.t<string>('campaignSummary.titles.exc'),
      titlePrefixColor: SummaryTitleColor.RED,
      content: summaryData.exclude
    }
  }, _.isEmpty);
};

const getGeneralSummary = (l1Object: L1Object, adSet: FbAdSetFormData) => {
  return {
    title: i18n.t<string>('adSetSetupFlow.mainStep.fieldset.basicTitle'),
    content: _.compact([
      {
        label: i18n.t<string>('adSetSetupFlow.mainStep.field.name'),
        value: adSet.name
      },
      l1Object.autoOptimise ?
        undefined : {
          label: i18n.t<string>('adSetSetupFlow.mainStep.field.lifetimeBudget'),
          value: adSet.lifetime_budget
        },
      adSet.pacing_type ? {
        label: i18n.t<string>('adSetSetupFlow.mainStep.field.pacing'),
        value: i18n.t<string>(`adSet.pacing.${adSet.pacing_type.toLowerCase()}`)
      } : undefined,
      {
        label: i18n.t<string>('adSetSetupFlow.mainStep.field.dateRange'),
        value: `${moment(adSet.start_time).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(adSet.end_time).format('YYYY-MM-DD HH:mm:ss')}`
      }
    ])
  };
};

const getOptimizeSummary = (currency: string, adSet: FbAdSetFormData) => {
  const frequencyControl = _.get(adSet, 'frequency_control_specs');
  return {
    title: i18n.t<string>('adSetSetupFlow.mainStep.fieldset.optimizationTitle'),
    content: _.compact([
      {
        label: i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal'),
        value: i18n.t<string>(`optimizationGoal.${adSet.optimization_goal!.toLowerCase()}`)
      },
      _.get(adSet, 'promoted_object.custom_event_type') ? {
        label: i18n.t<string>('adSetSetupFlow.mainStep.field.promoted_object_custom_event_type'),
        value: _.startCase(_.lowerCase(_.get(adSet, 'promoted_object.custom_event_type')))
      } : undefined,
      adSet.bid_amount ? {
        label: i18n.t<string>('adSetSetupFlow.mainStep.field.bidControl'),
        value: formatPriceWithCurrency(currency, +(adSet.bid_amount))
      } : undefined,
      {
        label: i18n.t<string>('adSetSetupFlow.mainStep.field.bidStrategy'),
        value: i18n.t<string>(`l1Object.labels.bidStrategy.${adSet.bid_strategy.toLowerCase()}`)
      },
      {
        label: i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent'),
        value: i18n.t<string>(`adSet.billingEvent.${adSet.billing_event!.toLowerCase()}`)
      },
      adSet.hasSpendLimits ? {
        label: i18n.t<string>('adSetSetupFlow.mainStep.field.hasSpendLimits'),
        value: renderFBAdSetSpendLimit(
            adSet.lifetime_min_spend_target ? formatPriceWithCurrency(currency, +(adSet.lifetime_min_spend_target)) : 0,
            adSet.lifetime_spend_cap ? formatPriceWithCurrency(currency, +(adSet.lifetime_spend_cap)) : 0)
      } : undefined,
      adSet.frequency_control_specs ? {
        label: i18n.t<string>('adSetSetupFlow.mainStep.field.frequencyControl'),
        value: i18n.t<string>('adSetSetupFlow.mainStep.labels.frequencyControl', {
          event: i18n.t<string>('adSetSetupFlow.mainStep.labels.impression'),
          eventPlural: +(frequencyControl.max_frequency) > 1 ? 's' : '',
          interval_days: frequencyControl.interval_days,
          max_frequency: frequencyControl.max_frequency,
          unit: i18n.t<string>('common.units.day'),
          unitPlural: +(frequencyControl.interval_days) > 1 ? 's' : ''
        })
      } : undefined,
      _.get(adSet, 'dayPart.enabled') ? {
        label: i18n.t<string>('campaignSummary.labels.dayPart'),
        value: getDayPartSummary(adSet.dayPart)
      } : undefined
    ])
  };
};

const getSummaryData = (l1Object, currency, fbAdSetManager, adSet: FbAdSetFormData) => {
  if (!adSet) {
    return undefined;
  }

  const basicSummary = {
    title: i18n.t<string>('adSetSetupFlow.summaryStep.basicTitle'),
    backStep: MAIN_STEP_INDEX,
    backSubStep: FbAdSetMainStepTab.BASIC,
    data: _.omitBy({
      general: getGeneralSummary(l1Object, adSet),
      optimization: getOptimizeSummary(currency, adSet)
    }, _.isUndefined)
  };

  const targeting = adSet.targeting;
  let targetingSummary;
  if (!_.isEmpty(targeting)) {
    targetingSummary = {
      title: i18n.t<string>('adSetSetupFlow.summaryStep.targetingTitle'),
      backStep: MAIN_STEP_INDEX,
      backSubStep: FbAdSetMainStepTab.TARGETINIG,
      data: getTASummaryData(fbAdSetManager, targeting)
    };
  }

  return {
    basicSummary,
    targetingSummary
  };
};

const onFbCountriesFetched = (fbCountries, limitationPreset, adSet) => {
  const findGeoLocationsOfPreset = (preset, fbCountries) => {
    const countryPreset = preset.find(limitation => limitation.type === 'geography');
    const countryPresetCodes = _.compact(_.get(countryPreset, 'value', []).map(country => getAlpha2ByAlpha3(country.value)));
    if (fbCountries) {
      const fbCountryPreset = fbCountries.filter(fbCountry => countryPresetCodes.includes(fbCountry.value));
      return fbCountryPreset.map(country => ({ label: country.value, value: country.value, extra: 'country' }));
    }
    return undefined;
  };
  const countries = findGeoLocationsOfPreset(_.get(limitationPreset, 'include', []), fbCountries);
  const excludeCountries = findGeoLocationsOfPreset(_.get(limitationPreset, 'exclude', []), fbCountries);
  return {
    ...adSet,
    targeting: _.omitBy({
      ...adSet.targeting,
      include: _.compact([
        ..._.get(adSet, 'targeting.include', []),
        countries.length > 0 ? {
          type: 'geo_locations',
          value: countries
        } : undefined
      ]),
      exclude: _.compact([
        ..._.get(adSet, 'targeting.exclude', []),
        excludeCountries.length > 0 ? {
          type: 'geo_locations',
          value: excludeCountries
        } : undefined
      ])
    }, _.isEmpty)
  };
};

const validateBudget = (order, l1Object, initAdSet: FbAdSet, value: FbAdSetFormData, minBudget: number) => {
  const initBudget = +(_.get(initAdSet, 'lifetime_budget', '0'));
  const budget = +(_.get(value, 'lifetime_budget', 0).toString());
  if (!initAdSet.isDraft &&
    initBudget === budget &&
    initAdSet.start_time === value.start_time &&
    initAdSet.end_time === value.end_time &&
    initAdSet.bid_amount === value.bid_amount &&
    validateMinimum(_.get(value, 'lifetime_budget'), 1) === undefined
  ) {
    return;
  }
  const totalBudget = initAdSet.isDraft ?
    l1Object.budgetBalance :
    l1Object.budgetBalance + initBudget;
  const remainBudget = totalBudget - budget;
  if (remainBudget < 0) {
    return renderOverBudgetWording(order.currency, totalBudget);
  }
  return validateMinimum(
    value.lifetime_budget,
    getPriceValue(order.currency, minBudget),
    'campaign.descriptions.smallerThanBudgetMinimum',
    order.currency
  );
};
const validateCampaignBudget = (value: FbAdSetFormData, order: Order, l1Object: L1Object, minBudget: number, otherAdsetMinBudget: number) => {
  const fbCampaignMinBudget = otherAdsetMinBudget + Math.max(minBudget, +_.defaultTo(value.lifetime_min_spend_target, 0));
  if (fbCampaignMinBudget > +_.defaultTo(l1Object.budget, 0)) {
    return renderIncreaseFBCampaignBudgetHint(order, l1Object.l1ObjectId, fbCampaignMinBudget);
  }
};
const validateFrequencyControlSpecs = (frequencyControlSpecs) => {
  const minIntervalDays = 1;
  const maxIntervalDays = 7;
  const minMaxFrequency = 1;
  const maxMaxFrequency = 20;
  const maxFrequency = frequencyControlSpecs.max_frequency;
  const intervalDays = frequencyControlSpecs.interval_days;
  const emptyMaxFrequency = validateEmpty(maxFrequency) && i18n.t<string>('adSetSetupFlow.mainStep.errors.emptyMaxFrequency');
  const emptyIntervalDays = validateEmpty(intervalDays) && i18n.t<string>('adSetSetupFlow.mainStep.errors.emptyIntervalDays');
  const intervalDaysOutofRange = validateMinMax(intervalDays, minIntervalDays, maxIntervalDays) &&
    i18n.t<string>('adSetSetupFlow.mainStep.errors.intervalDaysOutofRange', { min: minIntervalDays, max: maxIntervalDays });
  const maxFrequencyOutofRange = validateMinMax(maxFrequency, minMaxFrequency, maxMaxFrequency) &&
    i18n.t<string>('adSetSetupFlow.mainStep.errors.maxFrequencyOutofRange', { min: minMaxFrequency, max: maxMaxFrequency });
  return _.omitBy({
    max_frequency: emptyMaxFrequency || maxFrequencyOutofRange,
    interval_days: emptyIntervalDays || intervalDaysOutofRange
  }, _.isUndefined);
};
const validateLifetimeMinSpendTarget = (value: FbAdSetFormData, currency: string, minBudget: number) => {
  const minSpendTarget = value.lifetime_min_spend_target;
  const spendCap = value.lifetime_spend_cap;
  if (minSpendTarget) {
    if (spendCap) {
      if (+minSpendTarget > +spendCap) {
        return i18n.t<string>('fbAdSetMainStep.labels.minSpendLargerThanSpendCap');
      }
      if (+minSpendTarget > +spendCap * 0.9) {
        return i18n.t<string>('fbAdSetMainStep.labels.minSpendTooLarge');
      }
      if (+spendCap - +minSpendTarget < minBudget) {
        return i18n.t<string>('fbAdSetMainStep.labels.spendDiffSmallerThanMinBudget', { minBudget: formatPriceWithCurrency(currency, minBudget) });
      }
    }
  }
};
const validateLifetimeSpendCap = (value: FbAdSetFormData, currency: string, minBudget: number) => {
  const minSpendTarget = value.lifetime_min_spend_target;
  const spendCap = value.lifetime_spend_cap;
  if (spendCap) {
    if (+spendCap < minBudget) {
      return i18n.t<string>('fbAdSetMainStep.labels.spendCapSmallerThanMinBudget', { minBudget: formatPriceWithCurrency(currency, minBudget) });
    }
    if (minSpendTarget) {
      if (+minSpendTarget > +spendCap) {
        return i18n.t<string>('fbAdSetMainStep.labels.minSpendLargerThanSpendCap');
      }
      if (+spendCap - +minSpendTarget < minBudget) {
        return i18n.t<string>('fbAdSetMainStep.labels.spendDiffSmallerThanMinBudget', { minBudget: formatPriceWithCurrency(currency, minBudget) });
      }
    }
  }
};
