import React from 'react';
import _ from 'lodash';
import styles from './outdoorCreativeDetail.module.scss';
import { CreativeBasic, OutdoorType } from 'core/creative/Creative';
import { ImagePreview } from 'components/common/Image/ImagePreview';
import { VideoPreview } from 'components/common/Video/VideoPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArchive } from '@fortawesome/free-regular-svg-icons';

export const OutdoorCreativeDetail: React.FunctionComponent<{ creative: Partial<CreativeBasic> }> = (props) => {

  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div />;
  }

  const outdoorType = _.get(props.creative, 'outdoorType', OutdoorType.VIDEO);
  const videoUrl = _.get(creativeValue, 'videoUrl', '');
  const imageUrl = _.get(creativeValue, 'imageUrl', '');
  const showVideo = (outdoorType === OutdoorType.VIDEO || outdoorType === OutdoorType.VIDEO_IMAGE);

  return (
    <div className={styles.outdoorCreativeDetailContainer}>
      <div className={styles.outdoorCreativeDetail}>
        {showVideo &&
          <VideoPreview
            maxWidth={outdoorType === OutdoorType.VIDEO_IMAGE ? 189 : undefined}
            maxHeight={337}
            src={videoUrl}
          />
        }
        {(outdoorType === OutdoorType.VIDEO_IMAGE || outdoorType === OutdoorType.IMAGE_AUDIO) &&
          <ImagePreview
            size={outdoorType === OutdoorType.VIDEO_IMAGE ? { width: 189 } : { height: 337 }}
            border={false}
            url={imageUrl}
            config={{
              showName: false,
              showSize: false
            }}
          />
        }
        {(outdoorType === OutdoorType.HTML5) &&
          <div className={styles.h5Preview}>
            <FontAwesomeIcon icon={faFileArchive} />
          </div>
        }
      </div>
    </div>
  );
};
