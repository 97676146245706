import React, { useState, useEffect, useCallback } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { ImageFormProps } from './ImageFormModel';
import styles from './imageForm.module.scss';
import _ from 'lodash';
import { ImageCreativeSizeHint } from './ImageCreativeSizeHint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FormConfig } from 'components/common/form/FormConfig';
import { FormContent } from 'components/common/form/Form';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';

export const ImageForm: React.FunctionComponent<ImageFormProps & { formik: FormikContextType<any> }> = (props) => {

  const {
    model,
    hintModalData,
    renderHintModal,
    setHintModalData,
    getInitFormConfig
  } = props;

  const [sizeInstructionModalData, setSizeInstructionModalData] = useState<any>(undefined);
  const [formConfig, setFormConfig] = useState<FormConfig>(getInitFormConfig());
  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    callAPIs([() => model.init()]);
  }, [model, callAPIs]);

  const showSizeInstruction = useCallback(() => {
    const modalData = {
      component: ImageCreativeSizeHint,
      props: {
        widthHeightMap: model.validWidthHeightMap,
        closeHint: _.partial(setHintModalData, undefined)
      }
    };
    setSizeInstructionModalData(modalData);
    setHintModalData(modalData);
  }, [model.validWidthHeightMap, setHintModalData]);

  const closeSizeInstruction = useCallback(() => {
    setSizeInstructionModalData(undefined);
    setHintModalData(undefined);
  }, [setHintModalData]);

  useEffect(() => {
    if (hintModalData !== sizeInstructionModalData) {
      setSizeInstructionModalData(undefined);
    }
  }, [hintModalData, sizeInstructionModalData]);

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addFormikFileInput({
              type: 'image',
              className: styles.imageInput,
              label: i18n.t<string>('creativeSetupFlow.labels.image'),
              name: 'medias.image',
              validTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.imageCreativeTypeHint'),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              permanentHint: (
                <div
                  className={styles.sizeInstructionBtn}
                  onClick={sizeInstructionModalData ?
                    closeSizeInstruction : showSizeInstruction
                  }
                >
                  {i18n.t<string>('imageCreativeSizeHint.labels.sizeInstruction')}
                  <FontAwesomeIcon icon={faInfoCircle}/>
                </div>
              ),
              validate: props.model.validateImage
            })
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .withHintModal(renderHintModal())
        .build()
      )
      .build()
    );
  }, [
    props.basicFields,
    sizeInstructionModalData,
    props.model.validateImage,
    renderHintModal,
    showSizeInstruction,
    closeSizeInstruction
  ]);

  return (
    <>
      {loading && <LoadingIndicator />}
      <FormContent
        formConfig={formConfig}
      />
    </>
  );
};

export default connect(ImageForm);
