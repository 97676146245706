import { ModalState, ModalStateFactory } from 'containers/Common/ModalStateFactory';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { Order } from 'core/order/Order';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';
import { DraftSetupState, EdiMaxCampaignDraftSetupState, FbAdSetDraftSetupState, PICCampaignDraftSetupState, RetailCampaignDraftSetupState, RtbCampaignDraftSetupState } from './DraftSetupState';
import { DraftSummaryState, EdiMaxCampaignDraftSummaryState, FbAdSetDraftSummaryState, PICCampaignDraftSummaryState, RetailCampaignDraftSummaryState, RtbCampaignDraftSummaryState } from './DraftSummaryState';

export class DraftCreateModalStateFactory implements ModalStateFactory {

  channelStatesMap = {
    [L1ObjectChannel.RTB]: {
      setupState: RtbCampaignDraftSetupState,
      summaryState: RtbCampaignDraftSummaryState
    },
    [L1ObjectChannel.FB]: {
      setupState: FbAdSetDraftSetupState,
      summaryState: FbAdSetDraftSummaryState
    },
    [L1ObjectChannel.RETAIL_MEDIA]: {
      setupState: RetailCampaignDraftSetupState,
      summaryState: RetailCampaignDraftSummaryState
    },
    [L1ObjectChannel.EDIMAX]: {
      setupState: EdiMaxCampaignDraftSetupState,
      summaryState: EdiMaxCampaignDraftSummaryState
    },
    [L1ObjectChannel.PIC]: {
      setupState: PICCampaignDraftSetupState,
      summaryState: PICCampaignDraftSummaryState
    }
  };

  constructor (
    private order: Order,
    private l1Object: L1Object,
    private l2ObjectsToCreateDraft: {
      id: number | string, isDraft: boolean
    }[],
    private orderManager: OrderManager = new DefaultOrderManager()
  ) {}

  async initStates (goModalStateFn: (state: ModalState) => void, closeModalFn: (dirty) => void) {
    const orderOptions = await this.orderManager.getOrderOptions(this.order.orderType);
    const channelStates = this.channelStatesMap[this.l1Object.channel];
    const draftSetupState: DraftSetupState =
      new channelStates.setupState(orderOptions, this.l1Object, this.l2ObjectsToCreateDraft);
    const draftSummaryState: DraftSummaryState =
      new channelStates.summaryState(this.l1Object, this.l2ObjectsToCreateDraft);
    draftSetupState.next = () => goModalStateFn(draftSummaryState);
    draftSetupState.close = () => closeModalFn(false);
    draftSummaryState.back = () => goModalStateFn(draftSetupState);
    draftSummaryState.close = closeModalFn;
    return draftSetupState;
  }
}
