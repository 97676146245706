import { OutdoorType } from 'core/creative/Creative';
import { BasicOutdoorFormModel, OutdoorFormProps } from './OutdoorFormModel';
import { OutdoorForm } from './OutdoorForm';

export class EdiMaxFormModel extends BasicOutdoorFormModel<EdiMaxFormModel> {

  get adFormat () {
    return 'EDIMAX_OUTDOOR';
  }

  get useMediaDuration () {
    return false;
  }

  getFormContent (): (props: OutdoorFormProps<EdiMaxFormModel>) => JSX.Element {
    return OutdoorForm<EdiMaxFormModel>;
  }

  getDefaultDuration = (newType: OutdoorType, oldDuration: number) => {
    const notInDurationOption = oldDuration !== 0 && oldDuration !== 30;
    if (
      notInDurationOption ||
      [OutdoorType.IMAGE_AUDIO, OutdoorType.HTML5].includes(newType)
    ) {
      return 30;
    }

    return oldDuration;
  }
}
