import _ from 'lodash';
import client from './RestClient';
import { AuditLogRecord } from '../core/auditLog/AuditLog';
import { Pagination } from 'core/pagination/Pagination';
import { Pageable } from './Pageable';
import { AxiosInstance } from 'axios';

export interface AuditLogWebService {
  getOrderAndL1ObjectAuditLogs (orderId: number, pageable: Pageable, searchString: string): Promise<AuditLogWithPagination>;
  getOrderAndL1ObjectAuditLogFields (orderId: number, l1ObjectIds: (number | string)[]): Promise<string[]>;
  getCampaignAuditLog (pageable: Pageable, searchString: string): Promise<AuditLogWithPagination>;
  getCampaignAuditLogFields (campaignIds: (number | string)[]): Promise<string[]>;
  getAgencyAuditLog (agencyId: number, pageable: Pageable, searchString: string): Promise<AuditLogWithPagination>;
  getAgencyAuditLogFields (agencyId: number): Promise<string[]>;
}

export type AuditLogWithPagination = {
  pagination: Pagination,
  records: AuditLogRecord[]
};

function wrapPagination (json: any): Pagination {
  return {
    page: _.get(json, 'page', 1),
    size: _.get(json, 'size', 10),
    totalCount: _.get(json, 'totalCount', 0)
  };
}
export class RestfulAuditLogWebService implements AuditLogWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getOrderAndL1ObjectAuditLogs (orderId: number, pageable: Pageable, searchString?: string): Promise<AuditLogWithPagination> {
    let { page, sizePerPage, sort, direction } = pageable;
    direction = direction ? direction : 'desc';
    let url = `/v2/auditLogs/orders/${orderId}/l1-objects?page=${page}&size=${sizePerPage}&sort=${sort},${direction}`;
    if (searchString) {
      url += `&${searchString}`;
    }
    const response = await this.restClient.get(url);
    return {
      records: _.get(response, 'data.records', []),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async getOrderAndL1ObjectAuditLogFields (orderId: number, l1ObjectIds: (number | string)[]): Promise<string[]> {
    let url = `/v2/auditLogs/orders/${orderId}/l1-objects/msgKeys?l1ObjectIds=${l1ObjectIds.join(',')}`;
    const response = await this.restClient.get(url);
    return _.get(response, 'data.records', []).filter((field: string) => !['l1ObjectId', 'goGanGroupId'].includes(field));
  }

  async getCampaignAuditLog (pageable: Pageable, searchString?: string): Promise<AuditLogWithPagination> {
    let { page, sizePerPage, sort, direction } = pageable;
    direction = direction ? direction : 'desc';
    let url = `/v2/auditLogs/campaigns?page=${page}&size=${sizePerPage}&sort=${sort},${direction}`;
    if (searchString) {
      url += `&${searchString}`;
    }
    const response = await this.restClient.get(url);
    return {
      records: _.get(response, 'data.records', []),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async getCampaignAuditLogFields (campaignIds: (number | string)[]): Promise<string[]> {
    let url = `/v2/auditLogs/campaigns/msgKeys?campaignIds=${campaignIds.join(',')}`;
    const response = await this.restClient.get(url);
    return _.get(response, 'data.records', []);
  }

  async getAgencyAuditLog (agencyId: number, pageable: Pageable, searchString?: string): Promise<AuditLogWithPagination> {
    let { page, sizePerPage, sort, direction } = pageable;
    direction = direction ? direction : 'desc';
    let url = `/v2/auditLogs/agencies/${agencyId}?page=${page}&size=${sizePerPage}&sort=${sort},${direction}`;
    if (searchString) {
      url += `&${searchString}`;
    }
    const response = await this.restClient.get(url);
    return {
      records: _.get(response, 'data.records', []),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async getAgencyAuditLogFields (agencyId: number): Promise<string[]> {
    let url = `/v2/auditLogs/agencies/${agencyId}/msgKeys`;
    const response = await this.restClient.get(url);
    return _.get(response, 'data.records', []).filter((field: string) => !['l1ObjectId', 'goGanGroupId'].includes(field));
  }
}
