import { SelectOptions } from 'components/common/commonType';
import { ALL_SELECT_VALUE, AUTO_UPDATE_VALUE, ProductGroupFormValue, ProductGroupReadDTO, ProductGroupType, SpaceChannel } from 'core/limitation/ProductGroup';
import { DefaultProductGroupManager, ProductGroupManager, toServerPayload } from 'core/limitation/ProductGroupManager';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { defaultTo, get, isEmpty, omit, omitBy } from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import coreContext from 'contexts/coreContext';
import i18n from 'i18n';
import { useCallAPI } from 'hooks/useCallAPI';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';

const defaultProductGroupManager: ProductGroupManager = new DefaultProductGroupManager();
const defaultAdRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager();

const validate = (value: ProductGroupFormValue): ValidationResult<ProductGroupFormValue> => {
  return omitBy({
    groupName: validateEmpty(value.groupName),
    groupValues: validateEmpty([...value.groupValues, ...value.customGroupValues])
  }, isEmpty);
};

export type ProductGroupFormData = {
  title: string;
  loading: boolean;
  initProductGroup?: ProductGroupFormValue;
  productGroupTypeOptions: SelectOptions[];
  spaceOptions: SelectOptions[];
  domainOptions: SelectOptions[];
  setSpaceOptions: Dispatch<SetStateAction<SelectOptions[]>>;
  setDomainOptions: Dispatch<SetStateAction<SelectOptions[]>>;
  validate: (values: ProductGroupFormValue) => ValidationResult<ProductGroupFormValue>;
  save: (values: ProductGroupFormValue) => Promise<void>;
};

export const useCreateProductGroupFormModel = (
  productGroupManager: ProductGroupManager = defaultProductGroupManager
): ProductGroupFormData => {

  const core = useContext(coreContext);
  const agencyIds = core && core.authenticationManager.actor && core.authenticationManager.actor.agencyId ?
    [core.authenticationManager.actor.agencyId] :
    [];

  const initProductGroup: ProductGroupFormValue = {
    groupName: '',
    groupType: ProductGroupType.DOMAIN,
    description: '',
    groupValues: [],
    customGroupValues: [],
    agencyIds
  };

  const { loading, callAPIs } = useCallAPI();
  const [spaceOptions, setSpaceOptions] = useState<SelectOptions[]>([]);
  const [domainOptions, setDomainOptions] = useState<SelectOptions[]>([]);
  const productGroupTypeOptions = useMemo(() => createSelectOptionsFromEnum(ProductGroupType, 'productGroupForm.labels.'), []);

  const save = useCallback(async (saveValue: ProductGroupFormValue) => {
    return callAPIs([
      () => productGroupManager.createProductGroup(
        toServerPayload(
          saveValue,
          saveValue.groupType === ProductGroupType.ADSPACE ? spaceOptions.slice(2) : domainOptions
        )
      )
    ]);
  }, [productGroupManager, spaceOptions, domainOptions, callAPIs]);

  return {
    title: i18n.t<string>('productGroupForm.labels.createTitle'),
    loading: loading,
    initProductGroup,
    productGroupTypeOptions,
    spaceOptions,
    domainOptions,
    setSpaceOptions,
    setDomainOptions,
    validate,
    save
  };
};

export const useEditProductGroupFormModel = (
  productGroupId: number,
  productGroupManager: ProductGroupManager = defaultProductGroupManager,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
): ProductGroupFormData => {

  const [initProductGroup, setInitProductGroup] = useState<ProductGroupFormValue | undefined>(undefined);

  const { loading, callAPIs } = useCallAPI();
  const [spaceOptions, setSpaceOptions] = useState<SelectOptions[]>([]);
  const [domainOptions, setDomainOptions] = useState<SelectOptions[]>([]);

  const productGroupTypeOptions = useMemo(() => createSelectOptionsFromEnum(ProductGroupType, 'productGroupForm.labels.'), []);

  useEffect(() => {
    callAPIs([
      productGroupManager.getProductGroupById.bind(productGroupManager, productGroupId)
    ], (productGroup: ProductGroupReadDTO) => {
      const isSpace = productGroup.groupType === ProductGroupType.ADSPACE;
      const isAutoUpdate = productGroup.autoUpdate;
      setInitProductGroup(isSpace ? {
        ...omit(productGroup, ['id', 'state', 'autoUpdate']),
        groupType: ProductGroupType.ADSPACE,
        agencyIds: get(productGroup, 'agencyIds', []),
        groupValues: isAutoUpdate ? [
          { label: i18n.t<string>('productGroupForm.labels.conditionSpace'), value: AUTO_UPDATE_VALUE }
        ] : get(productGroup, 'groupValues', []),
        adFormat: defaultTo(productGroup.adFormat, ''),
        channel: productGroup.channel as SpaceChannel,
        attributes: defaultTo(productGroup.attributes, {})
      } : {
        ...productGroup,
        groupType: ProductGroupType.DOMAIN,
        agencyIds: get(productGroup, 'agencyIds', []),
        groupValues: get(productGroup, 'groupValues', []),
        attributes: undefined
      });
    });
  }, [productGroupId, productGroupManager, callAPIs]);

  useEffect(() => {
    if (initProductGroup?.groupType !== ProductGroupType.ADSPACE || !initProductGroup.attributes) {
      return;
    }

    callAPIs([
      () => adRequestSourceManager.getSpacesWithConditions(initProductGroup.attributes, initProductGroup.adFormat, initProductGroup.channel)
    ], spaces => {
      setSpaceOptions([
        { label: i18n.t<string>('productGroupForm.labels.selectAllSpace'), value: ALL_SELECT_VALUE },
        { label: i18n.t<string>('productGroupForm.labels.conditionSpace'), value: AUTO_UPDATE_VALUE },
        ...spaces
      ]);
    });
  }, [initProductGroup, adRequestSourceManager, callAPIs]);

  const save = useCallback(async (saveValue: ProductGroupFormValue) => {
    return callAPIs([
      () => productGroupManager.updateProductGroup({
        id: productGroupId,
        ...toServerPayload(
          saveValue,
          saveValue.groupType === ProductGroupType.ADSPACE ? spaceOptions.slice(2) : domainOptions
        )
      })
    ]);
  }, [productGroupId, productGroupManager, spaceOptions, domainOptions, callAPIs]);

  return {
    title: i18n.t<string>('productGroupForm.labels.editTitle'),
    loading: loading,
    initProductGroup,
    productGroupTypeOptions,
    spaceOptions,
    domainOptions,
    setSpaceOptions,
    setDomainOptions,
    validate,
    save
  };
};
