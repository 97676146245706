import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { ReportManager, DefaultReportManager } from 'core/report/ReportManager';
import { ReportType, ReportDimension, ReportGran, ReportData, ReportRecord, Metrics } from 'core/report/ReportData';
import { LocaleMeta, MOMENT_TIMEZONE, Actor, AddonFeatureManager, Currency } from 'core';
import { SelectOptions } from 'components/common/commonType';
import { isSystemAdmin } from 'helper/ActorHelper';
import { getDataProvider } from 'components/ReportTable/ReportDataProviderFactory';
import { ReportDataProvider } from 'components/ReportTable/ReportDataProvider';
import { emptyReportRecord } from 'components/ReportTable/ReportDataHelper';
import moment, { unitOfTime } from 'moment-timezone';
import i18n from 'i18n';
import _, { get } from 'lodash';
import { DateRange } from 'components/common/DateRangePicker/DateRangePicker';

export const SUPPORT_TIMEZONE = [
  '+07:00',
  '+08:00'
];

export interface ReportContentModel {
  readonly state: ReportContentState;
  readonly event: UpdateEventListener<ReportContentModel>;
  readonly reportTypes: Array<SelectOptions>;
  readonly reportGrans: Array<SelectOptions>;
  readonly reportTimezones: Array<SelectOptions>;
  readonly isSysAdmin: boolean;
  readonly searchString: string;
  readonly defaultReportType: ReportType;
  readonly defaultReportDimension: ReportDimension;
  readonly defaultReportGran: ReportGran;
  readonly needSpecifyAgency: boolean;
  readonly title: string;
  dateTimeShortCut: () => DateRange[];
  initReportData: () => Promise<void>;
  setUpReportTableData: () => Promise<void>;
  getFilterOptions: (filterName: string) => Array<SelectOptions>;
  updateReportType: (type: string | number) => void;
  updateGran: (type: string | number) => void;
  updateTimezone: (type: string) => void;
  updateDayRange: (from: string | undefined, to: string | undefined) => void;
  updateUseAppsflyerConversion: (useAppsflyerConversion: boolean) => void;
  addFilter: (filterName: string, filterValue: string | number) => Promise<void>;
  updateReportData: (params) => Promise<void>;
  updateSearchPath: (replace: boolean) => void;
  queryDataWithFilter: (filterName: string, filterValue: string | number) => void;
  queryDataWithDimension: (dimension: string) => void;
  addEmptyFilter: (filterName: string) => void;
  removeFilter: (filterName: string) => Promise<void>;
  handleOnSearch: (searchString: string) => void;
  handleOnTagFilterClicked: (tag: string) => void;
  toggleShowDimensionSelectArea (): void;
  toggleShowReportChart (): void;
  canFilterSelect (filterName: string): boolean;
  canFilterRemove (filterName: string): boolean;
  download: () => Promise<void>;
  showAlertModal: () => void;
  hideModal: () => void;
  isFilterWithoutPerformance: (filterName: string) => boolean;
}

export type ReportContentProps = {
  readonly search: string;
  readonly lang: string;
  readonly customDimensionComponent?: any;
  readonly model: ReportContentModel;
};

export type ReportContentState = {
  readonly loading: boolean;
  readonly reportData?: ReportData;
  readonly reportType: ReportType;
  readonly dimension: ReportDimension;
  readonly gran: ReportGran;
  readonly timezone: string;
  readonly filter: any;
  readonly from: string;
  readonly to: string;
  readonly searchPath: string;
  readonly tableDimensions: any;
  readonly tableColumnSettings: any;
  readonly tableData: any;
  readonly redirectPath: string;
  readonly selectedTagFilter: Array<string>;
  readonly tags: Array<string>;
  readonly dayRangeError?: string;
  readonly showDimensionSelectArea: boolean;
  readonly showReportChart: boolean;
  readonly modalData: any;
  readonly useAppsflyerConversion: boolean;
};

const MAX_QUERY_DAYS = 93;
const MAX_QUERY_HOURS = 168;

export abstract class AbstactReportContentModel implements ReportContentModel {
  event: FireableUpdateEventListener<ReportContentModel>;
  loading: boolean;
  searchString: string;
  reportManager: ReportManager;
  reportData?: ReportData;
  reportType: ReportType;
  dimension: ReportDimension;
  gran: ReportGran;
  timezone: string;
  filter: any;
  from: string;
  to: string;
  filterOptions: any;
  cachedReportQuery: any;
  dataProvider: ReportDataProvider;
  redirectPath: any;
  filteredRecords: Array<ReportRecord>;
  selectedTagFilter: Array<string>;
  tableData: Array<any>;
  tableColumnSettings: Array<any>;
  reportDimensions: Array<ReportDimension>;
  debouncedUpdateFilteredRecords: any;
  dayRangeError?: string;
  updateSearchPath: (replace: boolean) => void;
  showDimensionSelectArea: boolean;
  showReportChart: boolean;
  modalData: any;
  useAppsflyerConversion: boolean = true;
  cachedFilterOptionMap: { [key: string]: SelectOptions } = {};

  constructor (
    private actor: Actor | null,
    protected localeMeta: LocaleMeta | undefined,
    updateSearchPath: (newSearchPath, replace) => void,
    protected addonFeatureManager: AddonFeatureManager,
    reportManager: ReportManager = new DefaultReportManager()
  ) {
    this.showDimensionSelectArea = true;
    this.showReportChart = true;
    this.event = new FireableUpdateEventListener<ReportContentModel>();
    this.loading = true;
    this.selectedTagFilter = [];
    this.searchString = '';
    this.reportManager = reportManager;
    this.filteredRecords = [];
    this.reportType = ReportType.PERFORMANCE;
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick, this.onEditClick);
    this.dimension = ReportDimension.DAY;
    this.gran = ReportGran.DAY;
    this.filter = {};
    this.from = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.to = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.timezone = this.localeMeta ? this.localeMeta.timezone : '+08:00';
    this.updateSearchPath = (replace: boolean) => {
      updateSearchPath(this.searchPath, replace);
    };
    this.cachedReportQuery = {};
    this.filterOptions = {};
    this.tableData = [];
    this.tableColumnSettings = [];
    this.reportDimensions = this.dataProvider.getReportDimensions(this.actor);
    this.debouncedUpdateFilteredRecords = _.debounce(this.updateFilteredRecords.bind(this), 1000);
  }

  abstract get title ();

  abstract initReportData ();
  abstract download ();
  abstract get needSpecifyAgency ();
  abstract get defaultReportType ();
  abstract get defaultReportDimension ();
  abstract get defaultReportGran ();
  abstract get validDimensions ();

  optionValueToString = (option) => {
    return {
      ...option,
      value: option.value.toString()
    };
  }

  get isSysAdmin (): boolean {
    return isSystemAdmin(this.actor);
  }

  get tags (): Array<string> {
    return _.compact(_.uniq(_.flatten(this.tableData.map(data => data.tags))));
  }

  abstract get reportTypes ();

  abstract get reportGrans ();

  get reportTimezones () {
    return SUPPORT_TIMEZONE.map(timezone => {
      return {
        label: i18n.t<string>(`report.labels.${timezone.replace(':', '')}`),
        value: timezone
      };
    });
  }

  get state (): ReportContentState {
    return {
      loading: this.loading,
      reportData: this.reportData,
      reportType: this.reportType,
      dimension: this.dimension,
      gran: this.gran,
      timezone: this.timezone,
      filter: this.filter,
      from: this.from,
      to: this.to,
      searchPath: this.searchPath,
      tableDimensions: this.reportDimensions,
      tableColumnSettings: this.tableColumnSettings,
      tableData: this.filteredRecords,
      redirectPath: this.redirectPath,
      selectedTagFilter: this.selectedTagFilter,
      tags: this.tags,
      dayRangeError: this.dayRangeError,
      showDimensionSelectArea: this.showDimensionSelectArea,
      showReportChart: this.showReportChart,
      modalData: this.modalData,
      useAppsflyerConversion: this.useAppsflyerConversion
    };
  }

  get defaultReportData (): ReportData {
    return {
      allowMetrics: [Metrics.IMPRES, Metrics.CLICKS],
      records: [],
      dimension: this.dimension,
      filter: {},
      from: this.getDateWithTimezone(this.from),
      to: this.getDateWithTimezone(this.to),
      currency: Currency.NTD
    };
  }

  isFilterWithoutPerformance = (filterName: string) => {
    if (this.filter[filterName] === undefined || !this.filterOptions[filterName]) {
      return false;
    }
    return !this.getOptionByValue(this.filter[filterName], this.filterOptions[filterName]);
  }

  toggleShowDimensionSelectArea = () => {
    this.showDimensionSelectArea = !this.showDimensionSelectArea;
    this.updateState(false);
  }

  toggleShowReportChart = () => {
    this.showReportChart = !this.showReportChart;
    this.updateState(false);
  }

  get searchPath (): string {
    const filterQueryParams = Object.keys(this.filter)
      .filter(key => this.filter[key] !== undefined)
      .map(key => `${key}=${this.filter[key]}`)
      .join('&');
    let searchPath = `?type=${this.reportType}&dimension=${this.dimension}&gran=${this.gran}&from=${encodeURIComponent(this.from)}&to=${encodeURIComponent(this.to)}&timezone=${encodeURIComponent(this.timezone)}`;
    if (this.isSysAdmin && !_.isNil(this.useAppsflyerConversion)) {
      searchPath = `${searchPath}&useAppsflyerConversion=${this.useAppsflyerConversion}`;
    }
    return _.isEmpty(filterQueryParams) ? searchPath : `${searchPath}&${filterQueryParams}`;
  }

  async fetchFilterOptions (filterType, filters = this.filter) {
    try {
      this.filterOptions[filterType] = await this.reportManager.getFilterOptions(this.reportType, this.gran, filterType, {
        from: this.getDateWithTimezone(this.from),
        to: this.getDateWithTimezone(this.to),
        ...filters
      });
    } catch (e) {
      this.filterOptions[filterType] = [];
    }
  }

  updateAllFilterOptions = async () => {
    const filterKeys = Object.keys(this.filter);
    await this.updateFilterOptions(filterKeys.reverse());
  }

  updateReportType = async (type: string | number) => {
    const oriType = this.reportType;
    this.setUpReportType(type);
    if (this.reportType !== oriType) {
      await this.updateAllFilterOptions();
    } else {
      this.updateState(false);
    }
  }

  updateGran = async (gran: string | number) => {
    const oriGran = this.gran;
    this.setUpGran(gran);
    if (
      (oriGran === ReportGran.HOUR && this.gran !== ReportGran.HOUR) ||
      (oriGran !== ReportGran.HOUR && this.gran === ReportGran.HOUR)
    ) {
      await this.updateAllFilterOptions();
    } else {
      this.updateState(false);
    }
  }

  updateTimezone = async (timezone: string) => {
    const oriTimezone = this.timezone;
    this.setUpTimezone(timezone);
    if (this.timezone !== oriTimezone) {
      await this.updateAllFilterOptions();
    } else {
      this.updateState(false);
    }
  }

  updateDayRange = async (from: string | undefined, to: string | undefined) => {
    this.setUpDayRange(from, to);
    if (!this.dayRangeError) {
      await this.updateAllFilterOptions();
    } else {
      this.updateState(false);
    }
  }

  updateUseAppsflyerConversion = (useAppsflyerConversion: boolean) => {
    this.useAppsflyerConversion = useAppsflyerConversion;
    this.updateState(false);
  }

  updateFilterOptions = async (filtersToCheck: string[]) => {
    this.updateState(true);
    let otherFilters = { ...this.filter };
    for (let filterToCheck of filtersToCheck) {
      delete otherFilters[filterToCheck];
      await this.fetchFilterOptions(filterToCheck, otherFilters);
    }
    this.updateState(false);
  }

  cacheFilterOption = (filterType: string, filterValue: string | number) => {
    const options = this.filterOptions[filterType];
    if (!options) {
      return;
    }
    const optionToCache = this.getOptionByValue(filterValue, options);
    if (!optionToCache) {
      return;
    }
    this.cachedFilterOptionMap[filterType] = optionToCache;
  }

  addFilter = async (filterName: string, filterValue: string | number) => {
    const validTypes = this.state.tableDimensions.filter(dimension => dimension !== ReportDimension.DAY);
    if (!validTypes.includes(filterName)) {
      return;
    }
    this.updateState(true);
    this.filter = {
      ...this.filter,
      [filterName]: filterValue
    };

    this.cacheFilterOption(filterName, filterValue);

    const filterKeys = Object.keys(this.filter);
    const filterKeyToChangeIndex = filterKeys.indexOf(filterName);
    const keysToCheck = filterKeys.filter(key => filterKeys.indexOf(key) > filterKeyToChangeIndex).reverse();
    await this.updateFilterOptions(keysToCheck);
  }

  queryDataWithDimension = (dimension: string) => {
    let targetDimension = dimension;
    if (this.isDateDimension(dimension)) {
      targetDimension = this.gran;
    }
    const reportDimension = _.find(Object.values(ReportDimension), value => value === targetDimension);
    if (reportDimension) {
      this.dimension = reportDimension;
      this.updateSearchPath(false);
    }
  }

  queryDataWithFilter = async (filterType: string, filterValue: string | number) => {
    this.updateState(true);
    await this.fetchFilterOptions(filterType);
    const option = this.getOptionByValue(filterValue, this.filterOptions[filterType]);
    if (option) {
      this.filter = {
        ...this.filter,
        [filterType]: filterValue
      };
      this.cachedFilterOptionMap[filterType] = option;
    }
    this.updateState(false);
    this.updateSearchPath(false);
  }

  onEditClick = (editPath) => {
    this.redirectPath = editPath;
    this.updateState(false);
  }

  isDateDimension = (dimension) => {
    return dimension === ReportDimension.MONTH ||
      dimension === ReportDimension.DAY ||
      dimension === ReportDimension.HOUR;
  }

  abstract onDateClick (date);

  abstract canFilterSelect (filterName: string);

  abstract canFilterRemove (filterName: string);

  getFilterOptions = (filterType: string) => {
    const cachedFilterOption = this.cachedFilterOptionMap[filterType];
    if (!cachedFilterOption) {
      return this.filterOptions[filterType];
    }
    if (!this.getOptionByValue(cachedFilterOption.value, this.filterOptions[filterType])) {
      return [...this.filterOptions[filterType], cachedFilterOption];
    }
    return this.filterOptions[filterType];
  }

  setUpReportTableData = async () => {
    this.updateState(true);
    const reportData = await this.getReportData();
    this.tableData = this.dataProvider.getReportTableData(reportData.records);
    this.reportDimensions = this.dataProvider.getReportDimensions(this.actor);
    const filterKeys = Object.keys(this.filter);
    if (![ReportDimension.L2_OBJECT, ReportDimension.L1_OBJECT, ReportDimension.L3_CHANNEL]
      .some(dimension => filterKeys.includes(dimension))
    ) {
      _.remove(reportData.allowMetrics, metric => metric === Metrics.UU);
    }
    this.reportData = reportData;
    this.updateFilteredRecords();
  }

  async getReportData (): Promise<ReportData> {
    const defaultReportData = _.cloneDeep(this.defaultReportData);
    if (this.dayRangeError) {
      return defaultReportData;
    }
    try {
      const cacheKey = this.isDateDimension(this.dimension) ? ReportDimension.DAY : this.dimension;
      const useCache = this.cachedReportQuery[cacheKey] && this.cachedReportQuery[cacheKey].searchPath === this.searchPath;
      if (useCache) {
        return this.cachedReportQuery[cacheKey].result;
      }
      const from = this.getDateWithTimezone(this.from);
      const to = this.getDateWithTimezone(this.to);
      const reportData = await this.reportManager.getReportData(
        this.reportType,
        this.dimension,
        this.gran,
        _.omitBy(this.filter, _.isUndefined),
        from,
        to,
        this.isSysAdmin ? this.useAppsflyerConversion : undefined
      );
      if (this.isDateDimension(this.dimension)) {
        this.fillDateRecords(reportData);
      }
      this.cachedReportQuery[cacheKey] = {
        searchPath: this.searchPath,
        result: reportData
      };
      return reportData;
    } catch (e) {}
    return defaultReportData;
  }

  dateTimeShortCut = (): DateRange[] => {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    return [
      {
        label: i18n.t<string>('daypick.labels.today'),
        dateRange: [new Date(moment().startOf('day').format(dateFormat)), new Date(moment().endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.yesterday'),
        dateRange: [new Date(moment().subtract(1, 'day').startOf('day').format(dateFormat)), new Date(moment().subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.thisWeek'),
        dateRange: [new Date(moment().startOf('week').format(dateFormat)), new Date(moment().endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.thisMonth'),
        dateRange: [new Date(moment().startOf('month').format(dateFormat)), new Date(moment().endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.last7Days'),
        dateRange: [new Date(moment().subtract(1, 'week').startOf('day').format(dateFormat)), new Date(moment().subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.last30Days'),
        dateRange: [new Date(moment().subtract(30, 'day').startOf('day').format(dateFormat)), new Date(moment().subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.lastWeek'),
        dateRange: [new Date(moment().startOf('week').subtract(1, 'week').startOf('day').format(dateFormat)), new Date(moment().startOf('week').subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.lastMonth'),
        dateRange: [new Date(moment().startOf('month').subtract(1, 'month').startOf('day').format(dateFormat)), new Date(moment().startOf('month').subtract(1, 'day').endOf('day').format(dateFormat))]
      }
    ];
  }

  fillDateRecords = (reportData: ReportData) => {
    const dataFromServer = {};
    const dataFinal = {};
    let dateFormat = 'YYYY-MM-DD';
    if (this.dimension === ReportDimension.MONTH) {
      dateFormat = 'YYYY-MM';
    } else if (this.dimension === ReportDimension.HOUR) {
      dateFormat = 'YYYY-MM-DD HH';
    }
    reportData.records.forEach(data => {
      const dimensionName = moment(data.dimensionName).format(dateFormat);
      dataFromServer[dimensionName] = {
        ...data,
        dimensionName
      };
    });
    const dimension = this.dimension as unitOfTime.DurationConstructor;
    const start = moment(this.from).startOf(dimension);
    const to = moment(this.to).endOf(dimension);
    let current = start;
    while (current.isBefore(to)) {
      const dimensionName = current.format(dateFormat);
      if (dimensionName in dataFromServer) {
        dataFinal[dimensionName] = dataFromServer[dimensionName];
      } else {
        dataFinal[dimensionName] = { ...emptyReportRecord(dimensionName) };
      }
      current = current.add(1, dimension);
    }
    reportData.records = _.values(dataFinal);
    reportData.records = reportData.records.reverse();
  }

  showAlertModal = () => {
    if (this.dimension === ReportDimension.CREATIVE_TYPE) {
      this.modalData = {
        title: i18n.t<string>('reportTable.labels.alertModalTitle'),
        message: i18n.t<string>('reportTable.labels.alertModalContent'),
        confirmBtnData: {
          title: i18n.t<string>('common.buttons.confirm'),
          callback: this.hideModal.bind(this)
        }
      };
      this.updateState(false);
    }
  }

  hideModal = () => {
    this.modalData = undefined;
    this.updateState(false);
  }

  setUpReportType (typeParam) {
    const reportTypeOption = _.find(this.reportTypes, reportTypeOption => reportTypeOption.value === typeParam);
    if (reportTypeOption) {
      this.reportType = reportTypeOption.value;
    } else {
      this.reportType = this.defaultReportType;
    }
  }

  async setUpDimension (dimensionParam) {
    if (this.validDimensions.indexOf(dimensionParam) === -1) {
      this.dimension = this.defaultReportDimension;
      return;
    }
    this.dimension = dimensionParam;
    if (this.dimension === ReportDimension.RETAIL && !this.filter[ReportDimension.RETAIL]) {
      await this.fetchFilterOptions(ReportDimension.RETAIL);
    }
    this.showAlertModal();
  }

  setUpGran (granParam) {
    const granOption = _.find(this.reportGrans, granOption => granOption.value === granParam);
    if (granOption) {
      this.gran = granOption.value;
    } else {
      this.gran = this.defaultReportGran;
    }
    if (this.gran === ReportGran.DAY && this.dimension === ReportDimension.HOUR) {
      this.setUpTimezone(this.localeMeta ? this.localeMeta.timezone : '+08:00');
    }
    // dimension should same with gran
    if (this.isDateDimension(this.dimension)) {
      this.setUpDimension(this.gran);
    }
    this.setUpDayRange(this.from, this.to);
  }

  setUpTimezone (timezoneParam) {
    const actorTimezone = this.localeMeta ? this.localeMeta.timezone : '+08:00';
    const useActorTimezone =
      this.gran !== ReportGran.HOUR ||
      !this.isSysAdmin ||
      !(timezoneParam in MOMENT_TIMEZONE);

    if (useActorTimezone) {
      this.timezone = actorTimezone;
      return;
    }
    this.timezone = timezoneParam;
  }

  setUpDayRange (from, to) {
    if (from) {
      this.from = moment(from).startOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    }
    if (to) {
      this.to = moment(to).endOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    }
    if (this.gran === ReportGran.MONTH) {
      this.dayRangeError = undefined;
      return;
    }
    let start = new Date(this.from);
    let end = new Date(this.to);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    if (this.gran === ReportGran.DAY) {
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this.dayRangeError = diffDays > MAX_QUERY_DAYS ? i18n.t<string>('campaign.descriptions.moreThanDays', { days: MAX_QUERY_DAYS }) : undefined;
    } else {
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60));
      this.dayRangeError = diffDays > MAX_QUERY_HOURS ? i18n.t<string>('campaign.descriptions.moreThanHours', { hours: MAX_QUERY_HOURS }) : undefined;
    }
  }

  getOptionByValue = (filterValue: string | number, options: SelectOptions[]) => {
    if (!options) {
      return undefined;
    }
    return options.find(option => {
      if (option.value.toString() === filterValue.toString()) {
        return true;
      }
      if (option.options) {
        return option.options.find(subOption => subOption.value.toString() === filterValue.toString()) !== undefined;
      }
      return false;
    });
  }

  async setUpFilter (filterParams) {
    const filterTypes = this.state.tableDimensions.filter(dimension => dimension !== ReportDimension.DAY);
    this.filter = {};
    const filterKeys = Object.keys(filterParams);
    for (let key of filterKeys) {
      if (filterTypes.indexOf(key) !== -1) {
        // if params equals user inputs, don't need to fetch filter options
        const cachedFilterOption = this.cachedFilterOptionMap[key];
        if (cachedFilterOption && cachedFilterOption.value.toString() === filterParams[key].toString()) {
          this.filter[key] = cachedFilterOption.value;
          continue;
        }
        await this.fetchFilterOptions(key);
        const option = this.getOptionByValue(filterParams[key], this.filterOptions[key]);
        if (option) {
          this.filter[key] = option.value;
          this.cachedFilterOptionMap[key] = option;
        }
      }
    }

    if (this.needSpecifyAgency && !this.filter[ReportDimension.AGENCY]) {
      await this.fetchFilterOptions(ReportDimension.AGENCY);
      if (get(this.filterOptions[ReportDimension.AGENCY], '0')) {
        await this.addFilter(ReportDimension.AGENCY, this.filterOptions[ReportDimension.AGENCY][0].value);
      } else {
        this.addEmptyFilter(ReportDimension.AGENCY);
      }
    }
  }

  getDateWithTimezone (time) {
    const momentTimezone = MOMENT_TIMEZONE[this.timezone] ? MOMENT_TIMEZONE[this.timezone] : MOMENT_TIMEZONE['+08:00'];
    return moment.tz(time, momentTimezone).format();
  }

  async updateReportData (params) {
    this.updateState(true);
    const reportTypeParam = params.get('type');
    this.setUpReportType(reportTypeParam);
    params.delete('type');
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick, this.onEditClick);
    this.reportDimensions = this.dataProvider.getReportDimensions(this.actor);
    const dimensionParam = params.get('dimension');
    await this.setUpDimension(dimensionParam);
    params.delete('dimension');
    const granParam = params.get('gran');
    this.setUpGran(granParam);
    params.delete('gran');
    const fromParam = params.get('from');
    const toParam = params.get('to');
    this.setUpDayRange(fromParam, toParam);
    params.delete('from');
    params.delete('to');
    const timezoneParam = params.get('timezone');
    this.setUpTimezone(timezoneParam);
    params.delete('timezone');
    const useAppsflyerConversion = params.get('useAppsflyerConversion');
    if (!_.isNil(useAppsflyerConversion)) {
      this.useAppsflyerConversion = useAppsflyerConversion === 'true' ? true : false;
    }
    params.delete('useAppsflyerConversion');
    const filterParams = {};
    params.forEach((value, key) => {
      filterParams[key] = value;
    });
    await this.setUpFilter(filterParams);
    const needRefreshUrl =
      this.reportType !== reportTypeParam ||
      this.dimension !== dimensionParam ||
      this.gran !== granParam ||
      this.from !== fromParam ||
      this.to !== toParam ||
      this.timezone !== timezoneParam ||
      _.xor(Object.keys(_.omitBy(this.filter, _.isNil)), Object.keys(filterParams)).length > 0;
    needRefreshUrl ? this.updateSearchPath(true) : this.setUpReportTableData();
  }

  addEmptyFilter = async (filterType: string) => {
    this.filter = {
      ...this.filter,
      [filterType]: undefined
    };
    this.updateState(true);
    await this.fetchFilterOptions(filterType);
    this.updateState(false);
  }

  removeFilter = async (filterName: string) => {
    const filterKeys = Object.keys(this.filter);
    const filterKeyToRemoveIndex = filterKeys.indexOf(filterName);
    const keysToCheck = filterKeys.filter(key => filterKeys.indexOf(key) > filterKeyToRemoveIndex).reverse();
    delete this.filter[filterName];
    delete this.cachedFilterOptionMap[filterName];
    this.filter = {
      ...this.filter
    };
    await this.updateFilterOptions(keysToCheck);
  }

  handleOnSearch = (searchString: string) => {
    this.searchString = searchString;
    if (this.searchString === '') {
      this.debouncedUpdateFilteredRecords && this.debouncedUpdateFilteredRecords.cancel();
      this.updateFilteredRecords();
    } else {
      this.debouncedUpdateFilteredRecords();
    }
  }

  handleOnTagFilterClicked = (tag: string) => {
    if (_.indexOf(this.selectedTagFilter, tag) === -1) {
      this.selectedTagFilter.push(tag);
    } else {
      _.remove(this.selectedTagFilter, tagFilter => tagFilter === tag);
    }
    this.updateFilteredRecords();
  }

  updateFilteredRecords () {
    if (!this.loading) {
      this.updateState(true);
    }
    _.defer(() => {
      this.filteredRecords = _.filter(this.tableData,
        record => {
          const recordName = _.get(record, 'name', '');
          const nameIsMatch = recordName.toLowerCase().includes(this.searchString.toLowerCase());
          const tagsIsMatch = this.selectedTagFilter.length === 0 || _.intersection(record.tags, this.selectedTagFilter).length > 0;
          return nameIsMatch && (this.dimension !== ReportDimension.CAMPAIGN || tagsIsMatch);
        }
      );
      const currency = _.get(this.reportData, 'currency', Currency.NTD);
      this.tableColumnSettings = this.reportData ? this.dataProvider.getReportTableColumnSettings(this.reportData.allowMetrics, this.filteredRecords, this.dimension, currency) : [];
      this.updateState(false);
    });
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
