import { OutdoorType } from 'core/creative/Creative';
import { OutdoorCreativeFields } from 'core/creative/OutdoorCreative';
import { useMemo } from 'react';

export const useOutdoorProperties = (values: OutdoorCreativeFields) => {

  const outdoorType = values.typeProperties.outdoorType;
  const creativeSize = values.typeProperties.creativeSize;
  const [width, height] = useMemo(
    () => (creativeSize ? creativeSize.split('x').map(value => +value) : []),
    [creativeSize]
  );

  return {
    width,
    height,
    creativeSize,
    duration: values.typeProperties.duration,
    outdoorType: values.typeProperties.outdoorType,
    useAudio: outdoorType === OutdoorType.IMAGE_AUDIO ? values.typeProperties.useAudio : undefined,
    temperatureEnable: values.typeProperties.temperatureEnable
  };
};
