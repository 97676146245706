import {
  AuditLogWithPagination,
  AuditLogWebService,
  RestfulAuditLogWebService
} from 'ws/AuditLogWebService';
import { Pageable } from 'ws/Pageable';

export interface AuditLogManager {
  getOrderAndL1ObjectAuditLogs (
    orderId: number,
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination>;
  getOrderAndL1ObjectAuditLogsFields (
    orderId: number,
    l1ObjectIds: (number | string)[]
  ): Promise<string[]>;
  getCampaignAuditLog (
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination>;
  getCampaignAuditLogFields (
    campaignIds: (number | string)[]
  ): Promise<string[]>;
  getAgencyAuditLog (
    agencyId: number,
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination>;
  getAgencyAuditLogFields (
    agencyId: number
  ): Promise<string[]>;
}

export class DefaultAuditLogManager implements AuditLogManager {

  constructor (
    private webService: AuditLogWebService = new RestfulAuditLogWebService()
  ) {}

  async getOrderAndL1ObjectAuditLogs (
    orderId: number,
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination> {
    const auditLogWithPagination = await this.webService.getOrderAndL1ObjectAuditLogs(orderId, pageable, searchString);
    return auditLogWithPagination;
  }

  async getOrderAndL1ObjectAuditLogsFields (
    orderId: number,
    l1ObjectIds: (number | string)[]
  ): Promise<string[]> {
    return this.webService.getOrderAndL1ObjectAuditLogFields(orderId, l1ObjectIds);
  }

  async getCampaignAuditLog (
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination> {
    const auditLogWithPagination = await this.webService.getCampaignAuditLog(pageable, searchString);
    return auditLogWithPagination;
  }

  async getCampaignAuditLogFields (
    campaignIds: (number | string)[]
  ): Promise<string[]> {
    return this.webService.getCampaignAuditLogFields(campaignIds);
  }

  async getAgencyAuditLog (
    agencyId: number,
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination> {
    const auditLogWithPagination = await this.webService.getAgencyAuditLog(agencyId, pageable, searchString);
    return auditLogWithPagination;
  }

  async getAgencyAuditLogFields (
    agencyId: number
  ): Promise<string[]> {
    return this.webService.getAgencyAuditLogFields(agencyId);
  }
}
