import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ObjectFitContainer } from 'components/common/ObjectFitContainer/ObjectFitContainer';
import { Modal } from 'react-bootstrap';
import styles from './imageCreativeSizeHint.module.scss';
import i18n from 'i18n';

export const ImageCreativeSizeHint: React.FunctionComponent<{
  widthHeightMap: {[width: number]: number[]}
  closeHint: () => void
}> = (props) => {

  const [size, setSize] = useState<[width: number, height: number]>();

  const renderImageSize = useCallback(() => {
    return Object.keys(props.widthHeightMap).map((width) => {
      const heights = props.widthHeightMap[+width];
      return heights.map((height) => {
        const size = `${width} x ${height}`;
        const onImageSizeClick = () => {
          setSize([+width, height]);
        };
        return (
          <div
            className={styles.imageSizeInstance}
            key={size}
            onClick={onImageSizeClick}
          >
            <div/>
            {size}
          </div>
        );
      });
    });
  }, [props.widthHeightMap]);

  const hideSizeDetail = useCallback(() => {
    setSize(undefined);
  }, []);

  return (
    <div className={styles.imageCreativeSizeHint}>
      <div className={styles.title}>
        <span>{i18n.t<string>('imageCreativeSizeHint.labels.sizeInstruction')}</span>
        <FontAwesomeIcon icon={faTimes} onClick={props.closeHint}/>
      </div>
      <div className={styles.imageSize}>
        <div className={styles.imageSizeContent}>
          {renderImageSize()}
        </div>
      </div>
      {size &&
        <Modal
          show
          centered
          animation={false}
          onHide={hideSizeDetail}
          className={styles.sizeDetailModal}
        >
          <Modal.Body>
            <ObjectFitContainer
              containerWidth={460}
              containerHeight={460}
              onlyScaleDown={true}
            >
              <div
                className={styles.sizeBackground}
                style={{
                  width: size[0],
                  height: size[1]
                }}
              />
            </ObjectFitContainer>
            <div className={styles.sizeHint}>
              {`${size[0]} x ${size[1]}`}
            </div>
          </Modal.Body>
        </Modal>
      }
    </div>
  );
};
