import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, PropsWithChildren } from 'react';
import styles from './withAddIcon.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const WithAddIcon: React.FC<PropsWithChildren<{
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}>> = ({
  children,
  className,
  onClick
}) => {
  return (
    <div className={cx(styles.withAddIcon, className)} onClick={onClick}>
      <FontAwesomeIcon icon={faPlus} />
      <div>
        {children}
      </div>
    </div>
  );
};
