import { useMemo } from 'react';
import { FormConfig, FormField } from 'components/common/form/FormConfig';
import i18n from 'i18n';
import { isNil } from 'lodash';

export const useOutdoorDurationFields = (
  as: 'select' | 'input' | 'label' = 'select',
  validateDuration?: (value: number) => string | JSX.Element | undefined
): FormField[] => {

  const options = useMemo(
    () => [
      {
        label: i18n.t<string>('outdoorForm.labels.duration30'),
        value: 30
      },
      {
        label: i18n.t<string>('outdoorForm.labels.duration0'),
        value: 0
      }
    ],
    []
  );

  const fields = useMemo(() => {
    const builder = new FormConfig.FieldsBuilder();
    if (as === 'label') {
      builder.addFormikLabel({
        label: i18n.t<string>('creativeSetupFlow.labels.outdoorDuration'),
        name: 'typeProperties.duration',
        formatter: value => {
          if (value === '' || isNil(value)) {
            return `0 ${i18n.t<string>('common.units.seconds')}`;
          }
          return +value === 0
            ? i18n.t<string>(`outdoorForm.labels.duration${+value}`)
            : `${value} ${i18n.t<string>('common.units.seconds')}`;
        }
      });
    } else if (as === 'select') {
      builder.addFormikSelect({
        label: i18n.t<string>('creativeSetupFlow.labels.outdoorDuration'),
        name: 'typeProperties.duration',
        simpleValue: true,
        options
      });
    } else {
      builder.addFormikInputGroup({
        label: i18n.t<string>('creativeSetupFlow.labels.outdoorDuration'),
        name: 'typeProperties.duration',
        type: 'number',
        fieldContentWidth: 'auto',
        postText: i18n.t<string>('common.units.seconds'),
        validate: validateDuration
      });
    }

    return builder.build();
  }, [as, options, validateDuration]);

  return fields;
};
