import React from 'react';
import { RtbCampaignSetupFlowPageProps, RtbCampaignSetupFlowPageState } from './RtbCampaignSetupFlowPageModel';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { StepPage } from 'containers/StepPage/StepPage';
import { ChooseAdTypeStep } from './FlowSteps/ChooseAdTypeStep';
import { RtbCampaignSetupStep } from './FlowSteps/RtbCampaignSetupStep';
import { RtbCampaignSummaryStep } from './FlowSteps/RtbCampaignSummaryStep';
import { DefaultChooseAdTypeStepModel } from './FlowSteps/ChooseAdTypeStepModel';
import { DefaultRtbCampaignSetupStepModel } from './FlowSteps/RtbCampaignSetupStepModel';
import styles from './rtbCampaignSetupFlowPage.module.scss';
import i18n from 'i18n';
import { AppFooter } from 'components/AppFooter';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import _ from 'lodash';
import { SavedTAManagement } from 'containers/Limitations/SavedTAManagement/SavedTAManagement';
import { L1ObjectObjective } from 'core/l1Object/L1Object';

export class RtbCampaignSetupFlowPage extends React.Component<RouteComponentProps<{}> & RtbCampaignSetupFlowPageProps, RtbCampaignSetupFlowPageState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  onPromptCancel = () => {
    this.props.model.setRedirectPath(undefined);
  }

  renderTitle = () => {
    return (
      <div className={styles.topArea}>
        <TitleWithBreadcrumbs
          title={this.props.model.getTitle()}
          routes={[
            { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
            {
              path: '/orders/:orderNumber',
              breadcrumb: DynamicBreadcrumb,
              props: {
                label: _.get(this.props.model.order, 'projectName'),
                matchParam: 'orderNumber'
              }
            },
            {
              path: '/orders/:orderNumber/campaign-groups/:l1ObjectId',
              breadcrumb: DynamicBreadcrumb,
              props: {
                label: _.get(this.props.model.l1Object, 'name'),
                matchParam: 'l1ObjectId'
              }
            },
            { path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/campaigns/new', breadcrumb: this.props.model.getTitle() },
            {
              path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/campaigns/:campaignId/edit',
              breadcrumb: DynamicBreadcrumb,
              props: {
                prefix: this.props.model.getTitle(),
                label: _.get(this.props.model.state.campaign, 'basic.name'),
                matchParam: 'campaignId'
              }
            },
            {
              path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/drafts/:draftId/edit',
              breadcrumb: DynamicBreadcrumb,
              props: {
                prefix: this.props.model.getTitle(),
                label: _.get(this.props.model.state.campaign, 'basic.name'),
                matchParam: 'draftId'
              }
            }
          ]}
        />
      </div>
    );
  }

  renderAdTypeStep = ({ goNext }) => {
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <ChooseAdTypeStep model={new DefaultChooseAdTypeStepModel(this.props.model, goNext)}/>
        </div>
      </>
    );
  }

  renderMainStep = ({
    subStepIndex,
    goSubStep,
    goNext,
    goLast,
    registerValidateMethod
  }) => {
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <RtbCampaignSetupStep
            model={new DefaultRtbCampaignSetupStepModel(
              this.props.model,
              subStepIndex,
              goSubStep,
              goNext,
              goLast,
              registerValidateMethod
            )}
          />
        </div>
      </>
    );
  }

  renderSummaryStep = ({ goLast, goStep }) => {
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <RtbCampaignSummaryStep model={this.props.model.getRtbCampaignSummaryStepModel(goLast, goStep)} />
        </div>
      </>
    );
  }

  render () {
    const model = this.props.model;
    const redirectPath = model.state.redirectPath;
    if (!model.state.campaign || !model.campaignBasicFormModel) {
      return <LoadingIndicator />;
    }
    // remove dailyTargetBudget check
    if (model.l1Object.objective === L1ObjectObjective.UNSPECIFIED) {
      return <Redirect to={`${this.props.location.pathname}/error403`}/>;
    }
    return (
      <div className={styles.campaignSetupFlowPages}>
        {model.state.loading && <LoadingIndicator />}
        <NavigationPrompt when={!model.state.finished} onCancel={this.onPromptCancel}/>
        {model.state.showTAManagement &&
          <SavedTAManagement
            onImport={model.importLimitation}
            onDelete={model.onDeleteSaveTA}
            onClose={_.partial(model.setShowTAManagement, false)}
            getSummaryData={model.getLimitationsSummaryData}
            savedTAList={model.savedTAList}
          />
        }
        <StepPage cancel={this.props.model.cancel}>
          {this.props.model.type === 'create' &&
            <StepPage.Step
              label={i18n.t<string>('campaign.labels.adType')}
              renderStepContent={this.renderAdTypeStep}
            />
          }
          <StepPage.Step
            label={i18n.t<string>('campaign.labels.campaignSetting')}
            renderStepContent={this.renderMainStep}
          >
            <StepPage.SubStep label={i18n.t<string>('stepSideBar.labels.basic')}/>
            <StepPage.SubStep label={i18n.t<string>('stepSideBar.labels.targeting')}/>
            {this.props.model.needSetupTracking && <StepPage.SubStep label={i18n.t<string>('stepSideBar.labels.strategyAndTracking')}/>}
          </StepPage.Step>
          <StepPage.Step
            label={i18n.t<string>('campaign.labels.summary')}
            renderStepContent={this.renderSummaryStep}
          />
          <StepPage.SideBarFooter>
            <AppFooter/>
          </StepPage.SideBarFooter>
        </StepPage>
        {redirectPath && <Redirect to={redirectPath} />}
      </div>
    );
  }
}
