import { Page } from 'components/Page/Page';
import { ProductGroupType, ProductGroupFormValue, AUTO_UPDATE_VALUE } from 'core/limitation/ProductGroup';
import { GeneralSection } from './GeneralSection';
import { SelectGroupSection } from './SelectGroupSection';
import { FilterSpaceSection } from './FilterSpaceSection';
import { OtherSection } from './OtherSection';
import { Formik } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form } from 'components/common/form/Form';
import { SelectOptions } from 'components/common/commonType';
import { FetchDomain } from './FetchDomain';
import { ProductGroupFormData } from './ProductGroupFormModel';
import { FetchAgency } from './FetchAgency';

export type ProductGroupFormProps = {
  useModel: () => ProductGroupFormData
};

export const ProductGroupForm: React.FC<ProductGroupFormProps> = ({
  useModel
}) => {

  const {
    title,
    loading,
    initProductGroup,
    productGroupTypeOptions,
    spaceOptions,
    domainOptions,
    setSpaceOptions,
    setDomainOptions,
    validate,
    save
  } = useModel();

  const [redirectPath, setRedirectPath] = useState<string>();

  const [adFormatAndChannelOptions, setAdFormatAndChannelOptions] = useState<SelectOptions[]>([]);
  const [agencyOptions, setAgencyOptions] = useState<SelectOptions[]>([]);
  // {"Outdoor": { "nearbyPlaceLabel": options, "cityGroup": options... }, "Banner": {...}}
  const [spaceAttributesOptions, setSpaceAttributesOptions] = useState<{
    [adFormat: string]: { [attribute: string]: SelectOptions[] }
  }>({});

  const onSubmit = useCallback(async (values: ProductGroupFormValue) => {
    if (values.groupType === ProductGroupType.ADSPACE && values.groupValues.find(option => option.value === AUTO_UPDATE_VALUE)) {
      values.groupValues = spaceOptions;
    }
    await save(values);
    setRedirectPath('/system/product-groups');
  }, [spaceOptions, save]);

  const renderFormBtns = useCallback(() => (
    <div>
      <Form.SubmitButton/>
      <Form.CancelButton/>
    </div>
  ), []);

  if (!initProductGroup) {
    return <LoadingIndicator />;
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <Page title={title} minWidth={1000}>
      {loading && <LoadingIndicator />}
      <Formik
        initialValues={initProductGroup}
        validate={validate}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        {(formikProps) => {
          const { values } = formikProps;
          const productGroupType = values.groupType;
          const isSpaceGroup = productGroupType === ProductGroupType.ADSPACE;
          const disable = (values.groupValues && values.groupValues.length > 0)
            || (values.customGroupValues && values.customGroupValues.length > 0);
          const adFormat = isSpaceGroup
            ? values.adFormat === ''
              ? undefined
              : values.adFormat
            : undefined;
          const channel = isSpaceGroup
            ? values.channel === ''
              ? undefined
              : values.channel
            : undefined;
          return (
            <Form
              formikProps={formikProps}
              renderFormBtns={renderFormBtns}
            >
              <GeneralSection productGroupTypeOptions={productGroupTypeOptions}/>
              {
                isSpaceGroup ?
                  <FilterSpaceSection
                    disable={disable}
                    onSpacesOptionsFetched={setSpaceOptions}
                    adFormatAndChannelOptions={adFormatAndChannelOptions}
                    setAdFormatAndChannelOptions={setAdFormatAndChannelOptions}
                    spaceAttributes={spaceAttributesOptions}
                    setSpaceAttributes={setSpaceAttributesOptions}
                  /> :
                  <FetchDomain needFetch={domainOptions.length === 0} onDomainOptionsFetched={setDomainOptions}/>
              }
              <FetchAgency adFormat={adFormat} channel={channel} onAgencyOptionsFetched={setAgencyOptions}/>
              <SelectGroupSection options={isSpaceGroup ? spaceOptions : domainOptions}/>
              <OtherSection agencyOptions={agencyOptions}/>
            </Form>
          );
        }}
      </Formik>
    </Page>
  );
};
