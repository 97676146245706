import { L1ObjectObjective } from 'core/l1Object/L1Object';
import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { LIMITATION_TYPE, LimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';
import { getOutdoorLimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/outdoorLimitationInventorySettings';

export interface EdiMaxFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}

abstract class AbstractEdiMaxFormModel extends DefaultRtbCampaignBasicFormModel
  implements EdiMaxFormModel {

  override get isOutdoorType (): boolean {
    return true;
  }

  override get campaignAdType (): AdType {
    return AdType.EDIMAX;
  }

  override getRtbOptionsMap () {
    return {
      [L1ObjectObjective.AWARENESS]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.IMPRESSIONS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS
        ],
        [RtbCampaignPlanType.FCPM]: [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPV]: [
          L2ObjectOptimizationGoal.VIDEO_VIEWS
        ]
      },
      [L1ObjectObjective.TRAFFIC]: {},
      [L1ObjectObjective.SALES]: {},
      [L1ObjectObjective.UNSPECIFIED]: {
        [RtbCampaignPlanType.FCPM]: [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      }
    };
  }

  override getLimitationInventorySetting (
    requiredOperateOfTaTypes: {[key: string]: string[]}
  ): LimitationInventorySettings[] {
    return getOutdoorLimitationInventorySettings(
      requiredOperateOfTaTypes,
      LIMITATION_TYPE.CAMPAIGN,
      this.campaignAdType
    );
  }
}

export class EditEdiMaxFormModel extends AbstractEdiMaxFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateEdiMaxFormModel extends AbstractEdiMaxFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
