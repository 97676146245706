import { useCallback, useMemo } from 'react';
import i18n from 'i18n';
import { FormConfig, FormField } from 'components/common/form/FormConfig';
import { faFileAudio } from '@fortawesome/free-solid-svg-icons';
import { useOutdoorDurationFields } from './useOutdoorDurationFields';
import { useFormikContext } from 'formik';
import { floor } from 'lodash';
import { OutdoorType } from 'core/creative/Creative';
import styles from './outdoorFields.module.scss';
import { useOutdoorProperties } from './useOutdoorProperties';
import { ImageAudioOutdoorCreativeFields } from 'core/creative/OutdoorCreative';
import { OutdoorFormModel } from '../OutdoorFormModel';

export const useImageAudioOutdoorFields = <T extends OutdoorFormModel<T>>(
  model: T
): FormField[] => {

  const { values, setFieldValue } = useFormikContext<ImageAudioOutdoorCreativeFields>();
  const isEditing = !!values.creativeId;
  const {
    outdoorType,
    width,
    height,
    duration,
    useAudio
  } = useOutdoorProperties(values);

  const durationFields = useOutdoorDurationFields(useAudio && !isEditing && !model.useMediaDuration ? 'select' : 'label');

  const onMediaFileChange = useCallback(
    fileData => {
      fileData &&
        setFieldValue('typeProperties.duration', floor(fileData.duration));
    },
    [setFieldValue]
  );

  const onUseAudioChange = useCallback(() => {
    setFieldValue('typeProperties.duration', model.getDefaultDuration(outdoorType, duration));
  }, [outdoorType, duration, model, setFieldValue]);

  const fields = useMemo(() => {
    if (outdoorType !== OutdoorType.IMAGE_AUDIO) {
      return [];
    }

    const builder = new FormConfig.FieldsBuilder();
    builder.addFormikFileInput({
      type: 'image',
      label: i18n.t<string>('creativeSetupFlow.labels.image'),
      name: 'medias.image',
      className: styles[`imageInput${width}x${height}`],
      maxWidth: width / 3,
      hints: [
        i18n.t<string>('creativeSetupFlow.labels.typeHint'),
        i18n.t<string>('creativeSetupFlow.labels.imageSizeHint', { size: `${width} x ${height}` }),
        i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
      ],
      validate: value => model.validateImage(width, height, value)
    })
    .addFormikSwitch({
      label: i18n.t<string>('creativeSetupFlow.labels.outdoorAudioSwitch'),
      name: 'typeProperties.useAudio',
      onChange: onUseAudioChange
    });

    if (useAudio) {
      builder.addFormikFileInput({
        type: 'audio',
        label: i18n.t<string>('creativeSetupFlow.labels.audio'),
        name: 'medias.audio',
        className: styles.fileInput,
        icon: faFileAudio,
        validTypes: ['audio/mpeg'],
        hints: model.getAudioHints(),
        validate: model.validateAudio,
        onChange: model.useMediaDuration ? onMediaFileChange : undefined
      });
    }

    return [...builder.build(), ...durationFields];
  }, [model, outdoorType, width, height, useAudio, durationFields, onMediaFileChange, onUseAudioChange]);

  return fields;
};
