import { SelectOptions } from 'components/common/commonType';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { ProductGroupType, ProductGroupFormValue } from 'core/limitation/ProductGroup';
import { useFormikContext } from 'formik';
import { useCallAPI } from 'hooks/useCallAPI';
import { useEffect } from 'react';

const defaultAdRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager();
export const FetchDomain: React.FC<{
  needFetch: boolean;
  onDomainOptionsFetched: (options: SelectOptions[]) => void;
}> = ({ needFetch, onDomainOptionsFetched }) => {

  const { values } = useFormikContext<ProductGroupFormValue>();
  const groupType = values.groupType;
  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    if (!needFetch) {
      return;
    }
    if (groupType === ProductGroupType.DOMAIN) {
      callAPIs([() => defaultAdRequestSourceManager.getDomains()], options => {
        onDomainOptionsFetched(options);
      });
    }
  }, [groupType, needFetch, onDomainOptionsFetched, callAPIs]);

  return (
    <>
      {loading && <LoadingIndicator/>}
    </>
  );
};
