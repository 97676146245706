import { ObjectFitContainer } from 'components/common/ObjectFitContainer/ObjectFitContainer';
import Progress from 'components/common/Progress/Progress';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18n';
import styles from './campaignDashboard.module.scss';
import { addOnEnabled, hasFuncs, hasRoles, isPmax3Order } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faBalanceScaleLeft, faChartArea, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { RoleNames } from 'core';
import moment from 'moment';
import { camelCase, floor, partial, upperFirst } from 'lodash';
import { numberWithCommas } from 'utils/StringUtil';
import { ADDONFEATURE } from 'core/agency/AddonFeature';

const getReportLink = (campaign) => {
  const timeFormat = 'YYYY-MM-DD HH:mm:ss';
  const from = encodeURIComponent(moment(campaign.startDate).startOf('day').format(timeFormat));
  const to = encodeURIComponent(moment(campaign.endDate).endOf('day').format(timeFormat));
  const dimension = 'l2ChannelId';
  return `/reports/performance?dimension=${dimension}&from=${from}&to=${to}&${dimension}=${campaign.id}`;
};

const getCampaignTypeDes = (type) => {
  const key = `campaignList.labels.adType${upperFirst(camelCase(type))}`;
  if (!i18n.exists(key)) {
    return i18n.t<string>('campaignList.labels.adTypeEmpty');
  }

  return i18n.t<string>(key);
};

const nameFormatter = (id: string | number, campaign: any): any => {

  const renderLabel = () => <span className={styles.campaignName}>{campaign.campaignName}</span>;
  const editPath = `/orders/${campaign.orderNumber}/campaign-groups/${campaign.l1ObjectId}/campaigns/${id}/edit`;
  return (
    <div className={styles.info}>
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)
          .and(isPmax3Order(campaign.orderId))}
        renderOtherwise={renderLabel}
      >
        <Link
          to={editPath}
          className={`${styles.campaignName} ${styles.link}`}
        >
          {campaign.campaignName}
        </Link>
      </PermissionChecker>
      <div className={styles.campaignID}>
        <div>
          {`ID: ${id}`}
        </div>
        <div>
          <ObjectFitContainer
            key={i18n.language}
            containerHeight={16}
            onlyScaleDown={true}
            alignItems='auto'
            justifyContent='auto'
            margin={0}
          >
            {getCampaignTypeDes(campaign.adType)}
          </ObjectFitContainer>
        </div>
      </div>
    </div>
  );
};

const progressFormatter = (executeRate, campaign: any): any => {
  const predictRate = Math.min(campaign.predictRate, 1);
  const warnignLine = campaign.budget * 1.1;
  const discrepancy = predictRate - executeRate;

  const spent = `${campaign.currency} ${numberWithCommas(floor(campaign.spent, 2).toFixed(2))}`;
  const budget = `${campaign.currency} ${numberWithCommas(floor(campaign.budget, 2).toFixed(2))}`;
  const danger = campaign.spents > warnignLine;
  const deepWarning = discrepancy > 0.03;
  const warning = discrepancy > 0.01 && discrepancy <= 0.03;

  return (
    <div className={styles.progressContent}>
      <div className={styles.progress}>
        <Progress
          progress={executeRate}
          danger={danger}
          deepWarning={deepWarning}
          warning={warning}
        />
      </div>
      {`${spent} / ${budget}`}
      <br />
      {`${(executeRate * 100).toFixed(2)}% / ${(predictRate * 100).toFixed(2)}%`}
    </div>
  );
};

const floatingEditBtnsFormatter = (cell: any, campaign: any, _1, formatExtraData): any => {
  const campaignId: number = campaign.id;
  const editPath = `/orders/${campaign.orderNumber}/campaign-groups/${campaign.l1ObjectId}/campaigns/${campaignId}/edit`;

  const handleShowManageBidWeightModal = partial(formatExtraData.showManageBidWeightModal, campaignId);

  return (
    <TableRowToolBar className={styles.campaignFloatingEditArea}>
      <PermissionChecker
        permissionAware={
          hasFuncs(Permission.CAMPAIGN_WRITE).and(isPmax3Order(campaign.orderId))
        }
      >
        <Link to={editPath}>
          <IconWithTooltip
            icon={faPencilAlt}
            tooltipProps={{
              id: `campaignListEditTip-${campaignId}`,
              tooltip: i18n.t<string>('campaignList.labels.editHint')
            }}
          />
        </Link>
      </PermissionChecker>
      <PermissionChecker permissionAware={hasFuncs(Permission.REPORT_ADS)}>
        <Link to={getReportLink(campaign)}>
          <IconWithTooltip
            icon={faChartArea}
            tooltipProps={{
              id: `campaignListReportTip-${campaignId}`,
              tooltip: i18n.t<string>('campaignList.labels.reportHint')
            }}
          />
        </Link>
      </PermissionChecker>
      <PermissionChecker
        permissionAware={
          hasRoles(RoleNames.sysAdmin).or(
            hasRoles(RoleNames.agencyAdmin).and(addOnEnabled(ADDONFEATURE.CAMPAIGN.BID_WEIGHT_SETTING))
          )
        }
      >
        <IconWithTooltip
          onClick={handleShowManageBidWeightModal}
          icon={faBalanceScaleLeft}
          tooltipProps={{
            id: `campaignListBidWeightTip-${campaignId}`,
            tooltip: i18n.t<string>('campaignList.labels.manageBidWeightModalTitle')
          }}
        />
      </PermissionChecker>
    </TableRowToolBar>
  );
};

const formatters = {
  nameFormatter,
  progressFormatter,
  floatingEditBtnsFormatter
};

export default formatters;
