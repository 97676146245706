import React from 'react';
import styles from './outdoorMediaSummary.module.scss';
import { ImagePreview } from 'components/common/Image/ImagePreview';
import { VideoPreview } from 'components/common/Video/VideoPreview';
import _ from 'lodash';

type mediaSummaryData = {
  medias: {
    image?: {
      url: string,
      file?: File
    },
    video?: {
      url: string,
      file?: File
    },
    audio?: {
      url: string,
      file?: File
    }
  }
};

export const OutdoorMediaSummary: React.FunctionComponent<{ mediaSummary: mediaSummaryData }> = props => {
  const medias = props.mediaSummary.medias;
  const audioUrl = medias.audio && medias.audio.file ? URL.createObjectURL(medias.audio.file) : _.get(medias, 'audio.url');
  const videoSrc = medias.video?.file || medias.video?.url;
  const imgSrc = medias.image?.file || medias.image?.url;
  return (
    <div className={styles.outdoorPreview}>
      {videoSrc &&
        <VideoPreview
          maxWidth={270}
          maxHeight={500}
          src={videoSrc}
        />
      }
      {imgSrc &&
        <ImagePreview
          size={{ width: 270 }}
          border={false}
          url={medias.image!.url}
          file={medias.image!.file}
          config={{
            showName: false,
            showSize: false
          }}
        />
      }
      {medias.audio &&
        <audio
          src={audioUrl}
          controls
        />
      }
    </div>
  );
};
