import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { SelectOptions } from 'components/common/commonType';
import { LimitationInventorySettings } from '../LimitationSetting/limitationConfig/limitationSettingsType';

export interface InventoryModel {
  readonly defaultSelected: string;
  readonly event: UpdateEventListener<InventoryModel>;
  readonly state: InventoryState;
  readonly inventorySetting: LimitationInventorySettings[];
  readonly sidebarTitleKey: string;
  fire ();
  changeInventory (inventory: string): Promise<void>;
  changeInventorySetting (inventorySetting: LimitationInventorySettings[]): void;
}

export type InventoryProps = {
  readonly width?: number;
  readonly height?: number;
  readonly sidebarWidth?: number;
  readonly model: InventoryModel;
};

export type InventoryState = {
  readonly loading: boolean;
  readonly selected?: string;
  readonly inventoryData?: SelectOptions[];
  readonly inventorySetting: LimitationInventorySettings[];
};

export class DefaultInventoryModel implements InventoryModel {
  event: FireableUpdateEventListener<InventoryModel>;
  loading: boolean;
  selected: string;
  inventoryData?: SelectOptions[];
  inventoryDataCache: {[key: string]: SelectOptions[] | undefined};
  inventoryValue?: SelectOptions[];
  modelInventorySetting: LimitationInventorySettings[];

  constructor (
    defaultSelected: string,
    inventorySetting: LimitationInventorySettings[],
    taOptionsCache: {[key: string]: SelectOptions[]},
    public sidebarTitleKey: string = 'limitation.labels.trafficAttribute',
    private onInventoryChange?: (inventory: string) => void
  ) {
    this.event = new FireableUpdateEventListener<InventoryModel>();
    this.loading = false;
    this.selected = defaultSelected;
    this.inventoryDataCache = taOptionsCache;
    this.modelInventorySetting = inventorySetting;
  }

  get defaultSelected (): string {
    return this.selected;
  }

  get inventorySetting (): LimitationInventorySettings[] {
    return this.modelInventorySetting;
  }

  async loadInventoryData (inventoryName: string): Promise<void> {
    const inventorySetting = this.modelInventorySetting.find(
      ({ name }) => name === inventoryName
    );

    if (!inventorySetting) {
      throw new Error(`unknow limitation inventory ${inventoryName}`);
    }

    if (!this.inventoryDataCache[inventorySetting.name]) {
      try {
        this.inventoryDataCache[inventorySetting.name] = inventorySetting.cb ? await inventorySetting.cb() : [];
      } catch (e) {
        console.error(`limitation fetch error , cause is `, e);
        this.inventoryDataCache[inventorySetting.name] = [];
      }
    }

    this.inventoryData = this.inventoryDataCache[inventorySetting.name];
  }

  async changeInventory (inventoryName: string): Promise<void> {
    this.updateState(true, inventoryName);

    await this.loadInventoryData(inventoryName);

    const inventoryData = this.inventoryDataCache[inventoryName];
    this.updateState(false, inventoryName, inventoryData);
    this.onInventoryChange && this.onInventoryChange(inventoryName);
  }

  setUpModel (
    inventorySettings: LimitationInventorySettings[],
    selectedInventory: string,
    sidebarTitleKey: string = 'limitation.labels.trafficAttribute'
  ) {
    this.modelInventorySetting = inventorySettings;
    this.selected = selectedInventory;
    this.sidebarTitleKey = sidebarTitleKey;
    try {
      this.loadInventoryData(selectedInventory);
    } catch (e) {
      this.inventoryData = [];
    }
  }

  changeInventorySetting (inventorySetting: LimitationInventorySettings[]) {
    this.modelInventorySetting = inventorySetting;
    this.fire();
  }

  fire () {
    this.event.fireEvent(this);
  }

  updateState (loading: boolean, selected: string, inventoryData?: any) {
    this.loading = loading;
    this.selected = selected;
    this.inventoryData = inventoryData;
    this.event.fireEvent(this);
  }

  get state (): InventoryState {
    return {
      loading: this.loading,
      selected: this.selected,
      inventoryData: this.inventoryData,
      inventorySetting: this.inventorySetting
    };
  }
}
