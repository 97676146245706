import { Category, CategoryFormType } from 'core/tenmaxSegment/TenmaxSegment';
import { DefaultTenmaxSegmentManager } from 'core/tenmaxSegment/TenmaxSegmentManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from 'i18n';
import { SelectOptions } from 'components/common/commonType';
import { DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { uniq } from 'lodash';

const tenmaxSegmentManager = new DefaultTenmaxSegmentManager();
const adRequestSourceManager = new DefaultAdRequestSourceManager();

export type CategoryFormModel = {
  title: string,
  loading: boolean,
  initCategory: CategoryFormType | undefined,
  redirectPath: string | undefined,
  tenmaxCategories: SelectOptions[],
  handleSubmit: (category: CategoryFormType) => void
};

export const useCategoryFormModel = (
  callAPIs: (apiMethods: Function[], callback?: Function | undefined, errorHandler?: Function | undefined) => Promise<void>
) => {
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [tenmaxCategories, setTenmaxCategories] = useState<SelectOptions[]>([]);
  const defaultTenmaxCategories: SelectOptions[] = useMemo(() => ([{
    label: 'Others',
    value: 'Others'
  }]), []);

  const fetchTenmaxCategories = useCallback(async () => {
    callAPIs([
      () => adRequestSourceManager.getTenmaxCategories()
    ], (categories: SelectOptions[]) => {
      setTenmaxCategories(uniq([
        ...categories.map(category => ({
          label: i18n.t<string>(`tenmaxCategory.labels.${category.label}`),
          value: category.value
        })),
        ...defaultTenmaxCategories
      ]));
    });
  }, [callAPIs, defaultTenmaxCategories]);

  useEffect(() => {
    fetchTenmaxCategories();
  }, [fetchTenmaxCategories]);

  return {
    tenmaxCategories,
    defaultTenmaxCategories,
    redirectPath,
    setRedirectPath
  };
};

export const useCreateCategoryFormModel = (sourceId: number): CategoryFormModel => {
  const { loading, callAPIs } = useCallAPI();
  const { tenmaxCategories, redirectPath, setRedirectPath } = useCategoryFormModel(callAPIs);

  const handleSubmit = useCallback(async (category: CategoryFormType) => {
    callAPIs([
      () => tenmaxSegmentManager.createCategory(category)
    ], () => {
      setRedirectPath(`/tenmax-segments/sources/${sourceId}`);
    });
  }, [sourceId, callAPIs, setRedirectPath]);

  const initCategory = useMemo(() => ({
    name: '',
    sourceId: 0,
    tenmaxCategory: ''
  }), []);

  return {
    title: i18n.t<string>('categoryForm.titles.create'),
    loading,
    initCategory,
    redirectPath,
    handleSubmit,
    tenmaxCategories
  };
};

export const useEditCategoryFormModel = (sourceId: number): CategoryFormModel => {
  const { loading, callAPIs } = useCallAPI();
  const { tenmaxCategories, redirectPath, setRedirectPath } = useCategoryFormModel(callAPIs);

  const [initCategory, setInitCategory] = useState<CategoryFormType | undefined>();
  const { categoryId } = useParams<{ categoryId: string }>();

  const handleSubmit = useCallback(async (category: CategoryFormType) => {
    callAPIs([
      () => tenmaxSegmentManager.updateCategory({
        ...category,
        id: +categoryId
      })
    ], () => {
      setRedirectPath(`/tenmax-segments/sources/${sourceId}`);
    });
  }, [sourceId, categoryId, callAPIs, setRedirectPath]);

  const fetchCategory = useCallback(async () => {
    callAPIs([
      () => tenmaxSegmentManager.getCategory(sourceId, +categoryId)
    ], (category: Category) => {
      setInitCategory(category);
    });
  }, [sourceId, categoryId, callAPIs]);

  useEffect(() => {
    fetchCategory();
  }, [fetchCategory]);

  return {
    title: i18n.t<string>('categoryForm.titles.edit'),
    loading,
    initCategory,
    redirectPath,
    handleSubmit,
    tenmaxCategories
  };
};
