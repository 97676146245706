export type GoSegment = {
  name: string;
  description: string;
  duration?: number;
  audienceCount: number;
  advertiserId: number;
  dmpSegmentId: string;
  type: GoSegmentType;
};

export type GoSegmentListRecord = {
  segmentId: string;
  name: string;
  description: string;
  advertiserId: number;
  status: string;
  userList?: UserList;
  userListStatus: string;
  userListUpdatedAt: string;
  customAudience?: CustomAudience;
  customAudienceStatus: string;
  customAudienceUpdatedAt: string;
  l2ChannelIds: string[];
  max_alive_date: string;
  remaining_days: number;
  progress?: number;
  audienceBrief?: string;
  type: GoSegmentType;
  created_at: string;
};

export type GoSegmentFormData = {
  name: string;
  type?: GoSegmentType,
  description: string;
  duration?: string | number;
  advertiserId?: number;
  advertiserName?: string;
  upload?: any;
  updateMode?: GoSegmentUpdateMode;
  audienceBrief?: string;
  lookalike?: Lookalike;
};

export type GoSegmentProductFocus = {
  product_focus: string;
  segments: string[]
};

export enum GoSegmentType {
  UPLOAD = 'UPLOAD',
  MANUAL = 'MANUAL',
  CUSTOM = 'CUSTOM',
  LOOKALIKE = 'LOOKALIKE'
}

export enum GoSegmentUpdateMode {
  OVERRIDE = 'OVERRIDE',
  APPEND = 'APPEND'
  // REMOVE = 'REMOVE'
}

export type UserList = {
  status: string;
};

export type CustomAudience = {
  delivery_status: {
    code: number;
    description: string;
  };
};

export type Lookalike = {
  seed_segment_id: number | string;
  ratio: number;
  country: string;
};

export type EstimatedAudience = {
  estimateReady: boolean;
  upperBound: number;
  lowerBound: number;
  warning?: string;
  estimatedHint?: string;
};
