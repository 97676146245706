import { Creative, CreativeBasic, OutdoorType } from 'core/creative/Creative';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { BasicFormProps } from './BasicFormProps';
import { AbstractFormContentModel } from './FormContentModel';
import { get, isEmpty, omit, omitBy } from 'lodash';
import i18n from 'i18next';
import JSZip from 'jszip';
import { SelectOptions } from 'components/common/commonType';

const mimeDB = require('mime-db');

export type OutdoorFormProps<T> = {
  readonly model: T;
} & BasicFormProps;

export interface OutdoorFormModel<T> {
  adFormat: string;
  useMediaDuration: boolean;
  getFormContent (): (props: OutdoorFormProps<T>) => JSX.Element;
  getDefaultDuration (newType: OutdoorType, oldDuration: number): number;
  getOutdoorTypeOptions (creativeSize?: `${number} x ${number}`): SelectOptions[];
  getVideoHints (width: number, height: number): string[];
  getAudioHints (): string[];
  validateDuration?: (value: number) => string | undefined;
  validateZip: (fileData: FileFieldData) => Promise<string | JSX.Element | undefined>;
  validateVideo (targetWidth: number, targetHeight: number, fileData: MediaFieldData): string | JSX.Element | undefined;
  validateAudio (fileData: MediaFieldData): string | JSX.Element | undefined;
  validateImage: (targetWidth: number, targetHeight: number, fileData: ImageFieldData) => string | JSX.Element | undefined;
}

export abstract class BasicOutdoorFormModel<T> extends AbstractFormContentModel implements OutdoorFormModel<T> {

  public needBannerUrl: boolean = false;

  constructor () {
    super();
    this.validateImage = this.validateImage.bind(this);
    this.validateVideo = this.validateVideo.bind(this);
    this.validateAudio = this.validateAudio.bind(this);
    this.validateZip = this.validateZip.bind(this);
  }

  abstract get adFormat (): string;

  abstract get useMediaDuration (): boolean;

  abstract getFormContent (): (props: OutdoorFormProps<T>) => JSX.Element;

  abstract getDefaultDuration (newType: OutdoorType, oldDuration: number): number;

  addLimitation?: (
    operate: string,
    limitationType: string,
    label: string,
    value: string
  ) => void;

  setLimitationHook (
    addLimitation: (
      operate: string,
      limitationType: string,
      label: string,
      value: string
    ) => void
  ): void {
    this.addLimitation = addLimitation;
  }

  getOutdoorTypeOptions (creativeSize?: `${number} x ${number}`) {
    if (!creativeSize) {
      return [];
    }

    const allOptions = [
      {
        label: i18n.t<string>('outdoorForm.labels.outdoorType1'),
        value: OutdoorType.VIDEO
      },
      {
        label: i18n.t<string>('outdoorForm.labels.outdoorType2'),
        value: OutdoorType.IMAGE_AUDIO
      },
      {
        label: i18n.t<string>('outdoorForm.labels.outdoorType3'),
        value: OutdoorType.VIDEO_IMAGE
      },
      {
        label: i18n.t<string>('outdoorForm.labels.outdoorType4'),
        value: OutdoorType.HTML5
      }
    ];

    if (creativeSize === '1080 x 1920') {
      return allOptions;
    }

    return allOptions.filter(
      option => option.value !== OutdoorType.VIDEO_IMAGE
    );
  }

  getInitTypeProperties () {
    return {
      creativeSize: undefined,
      outdoorType: undefined,
      temperatureEnable: false,
      temperatureRange: [25, 30],
      duration: 30,
      useAudio: false
    };
  }

  getCreativeSize (creativeBasic: CreativeBasic): `${number} x ${number}` {
    const creativeValueString = get(creativeBasic, 'creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const videoWidth = get(creativeValue, 'videoW');
    const videoHeight = get(creativeValue, 'videoH');
    const imageWidth = get(creativeValue, 'imgW');
    const imageHeight = get(creativeValue, 'imgH');
    const htmlWidth = get(creativeValue, 'htmlW');
    const htmlHeight = get(creativeValue, 'htmlH');
    switch (creativeBasic.outdoorType) {
      case OutdoorType.VIDEO:
        return `${videoWidth} x ${videoHeight}`;
      case OutdoorType.VIDEO_IMAGE:
        return '1080 x 1920';
      case OutdoorType.IMAGE_AUDIO:
        return `${imageWidth} x ${imageHeight}`;
      case OutdoorType.HTML5:
        return `${htmlWidth} x ${htmlHeight}`;
    }
    return '1080 x 1920';
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = omit(creative.basic, [
      'id',
      'srcType',
      'creativeValues',
      'bannerExtra',
      'duration',
      'outdoorType'
    ]);
    const creativeValueString = get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const imageUrl = get(creativeValue, 'imageUrl');
    const videoUrl = get(creativeValue, 'videoUrl');
    const htmlUrl = get(creativeValue, 'htmlUrl');
    const audioUrl = get(creativeValue, 'audioUrl');
    const toUrlObject = url => url ? { url } : undefined;
    const mediaData =
      imageUrl || videoUrl || htmlUrl || audioUrl
        ? omitBy(
          {
            image: toUrlObject(imageUrl),
            video: toUrlObject(videoUrl),
            html: toUrlObject(htmlUrl),
            audio: toUrlObject(audioUrl)
          },
            isEmpty
          )
        : undefined;
    const useTempControl = get(
      creative.basic,
      'bannerExtra.useTempControl',
      false
    );
    const minTemp = useTempControl
      ? get(creative.basic, 'bannerExtra.minTemp')
      : 25;
    const maxTemp = useTempControl
      ? get(creative.basic, 'bannerExtra.maxTemp')
      : 30;
    const useAudio = get(creative.basic, 'bannerExtra.useAudio', false);

    return {
      basic: {
        ...creativeBasic,
        typeProperties: {
          outdoorType: get(creative.basic, 'outdoorType', OutdoorType.VIDEO),
          duration: get(creative.basic, 'duration', 30),
          temperatureEnable: useTempControl,
          temperatureRange: [minTemp, maxTemp],
          useAudio,
          creativeSize: this.getCreativeSize(creative.basic)
        },
        medias: mediaData
      },
      limitations: creative.limitations
    };
  }

  getVideoHints (width: number, height: number) {
    return [
      i18n.t<string>('creativeSetupFlow.labels.videoTypeHint'),
      i18n.t<string>('outdoorForm.labels.videoSizeHint', {
        size: `${width} x ${height}`
      }),
      i18n.t<string>('creativeSetupFlow.labels.storageHint', {
        storage: '490MB'
      })
    ];
  }

  getAudioHints () {
    return [
      i18n.t<string>('creativeSetupFlow.labels.audioTypeHint'),
      i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
    ];
  }

  isFileFieldEmpty (
    fileData: FileFieldData | EmptyFileFieldData
  ): fileData is EmptyFileFieldData {
    return !fileData || (!fileData.file && !fileData.url);
  }

  hasFile (
    fileData: FileFieldData | EmptyFileFieldData
  ): fileData is {
    file: File;
    url?: string;
  } {
    return !!fileData.file;
  }

  validateImage (
    targetWidth: number,
    targetHeight: number,
    fileData: ImageFieldData
  ) {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (this.isFileFieldEmpty(fileData)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', {
        type: extensions[0]
      });
    }

    const errors: any[] = [];
    const width = fileData.width;
    const height = fileData.height;
    if (
      Math.abs(width - targetWidth) > 1 ||
      Math.abs(height - targetHeight) > 1
    ) {
      errors.push(
        i18n.t<string>('creativeSetupFlow.labels.sizeErrorHint2', {
          size1: `${width} x ${height}`,
          size2: `${targetWidth} x ${targetHeight}`
        })
      );
    }

    if (file.size > 2097152) {
      errors.push(
        i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', {
          storage1: formatBytes(file.size),
          storage2: '2MB'
        })
      );
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateVideo (
    targetWidth: number,
    targetHeight: number,
    fileData: MediaFieldData
  ) {
    const validTypes = ['video/mp4'];
    if (this.isFileFieldEmpty(fileData)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      return i18n.t<string>('creativeSetupFlow.labels.videoTypeHint');
    }

    const errors: any[] = [];
    const width = fileData.width;
    const height = fileData.height;
    if (
      Math.abs(width - targetWidth) > 1 ||
      Math.abs(height - targetHeight) > 1
    ) {
      errors.push(
        i18n.t<string>('outdoorForm.labels.videoSizeHint', {
          size: `${targetWidth} x ${targetHeight}`
        })
      );
    }

    if (file.size > 513802240) {
      errors.push(
        i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', {
          storage1: formatBytes(file.size),
          storage2: '490MB'
        })
      );
    }
    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateAudio (fileData: MediaFieldData) {
    if (this.isFileFieldEmpty(fileData)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const validTypes = ['audio/mpeg'];
    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', {
        type: extensions[0]
      });
    }

    const errors: any[] = [];
    if (file.size > 2097152) {
      errors.push(
        i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', {
          storage1: formatBytes(file.size),
          storage2: '2MB'
        })
      );
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  async validateZip (fileData: FileFieldData) {
    if (this.isFileFieldEmpty(fileData)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const validTypes = ['application/zip', 'application/x-zip-compressed'];
    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('html5Form.labels.typeErrorHint', {
        type: extensions[0]
      });
    }

    const errors: any[] = [];
    if (file.size > 2097152) {
      errors.push(
        i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', {
          storage1: formatBytes(file.size),
          storage2: '2MB'
        })
      );
    }

    const zipData = await JSZip.loadAsync(file);
    const contents: string[] = [];
    zipData.forEach((_1, zipEntry) => {
      // @ts-ignore
      contents.push(zipEntry.name);
    });
    if (!contents.includes('index.html')) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.zipContentError'));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }
}
