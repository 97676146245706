import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { AdLogo } from 'core/adLogo/AdLogo';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Creative } from 'core/creative/Creative';
import i18n from 'i18n';
import _, { defaultTo, uniq } from 'lodash';
import { formatBytes } from 'utils/StringUtil';
import { BasicFormProps } from './BasicFormProps';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { AbstractFormContentModel } from './FormContentModel';
import ImageForm from './ImageForm';
import ImageFormMutliple from './ImageFormMultiple';
import { CreativeManager, DefaultCreativeManager } from 'core/creative/CreativeManager';
const mimeDB = require('mime-db');

export type ImageFormProps = {
  readonly model: ImageFormModel;
  readonly hintModalData?: any;
  readonly setHintModalData: (modalData: any) => void;
} & BasicFormProps;

export type CreateImageFormProps = {
  readonly model: CreateImageFormModel;
  readonly hintModalData?: any;
  readonly setHintModalData: (modalData: any) => void;
} & BasicFormProps;

export class ImageFormModel extends AbstractFormContentModel {

  public needBannerUrl: boolean = true;
  public validWidthHeightMap: {[width: number]: number[]} = {};

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel,
    private creativeManager: CreativeManager = new DefaultCreativeManager()
  ) {
    super();
  }

  override async init () {
    try {
      const sizes = await this.creativeManager.getCreativeSizesByAdFormat('BANNER');
      sizes.forEach(({ width, height }) => {
        const heightsOfWidth = defaultTo(this.validWidthHeightMap[width], []);
        this.validWidthHeightMap[width] = uniq([...heightsOfWidth, height]);
      });
    } catch (e) {
      console.error(e);
      // fallback width height
      this.validWidthHeightMap = {
        300: [100, 250, 600],
        320: [50, 100],
        336: [280],
        728: [90],
        160: [600],
        468: [60],
        970: [90, 250]
      };
    }
  }

  public get defaultAdLogo (): AdLogo | undefined {
    if (this.flowPageModel.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.CUSTOMIZE_ADLOGO)) {
      return this.flowPageModel.defaultAdLogo;
    }
    return undefined;
  }

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = (): any => {
    return ImageForm;
  }

  validateImage = (fileData) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.imageCreativeTypeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];
    const width = fileData.width;
    const height = fileData.height;
    if (!Object.keys(this.validWidthHeightMap).includes(width.toString()) || !this.validWidthHeightMap[width].includes(height)) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.imageSizeErrorHint', { size: `${width} x ${height}` }));
    }

    if (file.size > 2097152) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getInitTypeProperties () {
    return undefined;
  }

  getFormModelData (creative: Creative) {
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const medias = _.omitBy({
      image: _.omitBy({
        url: _.get(creativeValue, 'imageUrl'),
        width: _.get(creativeValue, 'w'),
        height: _.get(creativeValue, 'h')
      }, _.isUndefined)
    }, _.isEmpty);
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    return {
      basic: {
        ...creativeBasic,
        medias
      },
      limitations: creative.limitations
    };
  }
}

export class CreateImageFormModel extends ImageFormModel {

  getFormContent = () => {
    return ImageFormMutliple;
  }

  validateImages = (fileData: {
    [key: string]: {
      width: number,
      height: number,
      file: File
    }
  }) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    const fileDataArray = Object.values(_.defaultTo(fileData, {}));
    if (fileDataArray.length === 0) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    const typeErrorImageList: string[] = fileDataArray
      .filter(data => validTypes.indexOf(data.file.type) === -1)
      .map(data => data.file.name);
    if (typeErrorImageList.length > 0) {
      return `${i18n.t<string>('creativeSetupFlow.labels.imageMultipleTypeErrorHint')}: ${typeErrorImageList.join(', ')}`;
    }

    const errors: string[] = [];
    const widthHeightErrorImageList: string[] = fileDataArray
      .filter(data => {
        const width = data.width;
        const height = data.height;
        return !Object.keys(this.validWidthHeightMap).includes(width.toString()) ||
          !this.validWidthHeightMap[width].includes(height);
      })
      .map(data => data.file.name);
    widthHeightErrorImageList.length > 0 && errors.push(`${i18n.t<string>('creativeSetupFlow.labels.imageMultipleSizeErrorHint')}: ${widthHeightErrorImageList.join(', ')}`);

    const sizeErrorImageList: string[] = fileDataArray
      .filter(data => data.file.size > 2097152)
      .map(data => data.file.name);
    sizeErrorImageList.length > 0 && errors.push(`${i18n.t<string>('creativeSetupFlow.labels.storageMultipleErrorHint', { storage: '2MB' })}: ${sizeErrorImageList.join(', ')}`);

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    return {
      basic: {
        ...creativeBasic,
        medias: {}
      },
      limitations: creative.limitations
    };
  }
}
