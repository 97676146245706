import { useCallback, useMemo } from 'react';
import { FormConfig, FormField } from 'components/common/form/FormConfig';
import { useOutdoorDurationFields } from './useOutdoorDurationFields';
import { useFormikContext } from 'formik';
import { floor } from 'lodash';
import { OutdoorType } from 'core/creative/Creative';
import i18n from 'i18n';
import { useOutdoorProperties } from './useOutdoorProperties';
import { VideoOutdoorCreativeFields } from 'core/creative/OutdoorCreative';
import { OutdoorFormModel } from '../OutdoorFormModel';

export const useVideoOutdoorFields = <T extends OutdoorFormModel<T>>(
  model: T
): FormField[] => {

  const { values, setFieldValue } = useFormikContext<VideoOutdoorCreativeFields>();
  const isEditing = !!values.creativeId;
  const {
    outdoorType,
    width,
    height
  } = useOutdoorProperties(values);

  const durationFields = useOutdoorDurationFields(
    isEditing || model.useMediaDuration ? 'label' : 'select'
  );

  const onMediaFileChange = useCallback(
    fileData => {
      fileData &&
        setFieldValue('typeProperties.duration', floor(fileData.duration));
    },
    [setFieldValue]
  );

  const fields = useMemo(() => {
    if (outdoorType !== OutdoorType.VIDEO) {
      return [];
    }

    const builder = new FormConfig.FieldsBuilder();
    builder.addFormikFileInput({
      type: 'video',
      label: i18n.t<string>('videoForm.labels.tenMaxVideoInput'),
      name: 'medias.video',
      maxWidth: width / 3,
      maxHeight: height / 3,
      hints: model.getVideoHints(width, height),
      validate: value => model.validateVideo(width, height, value),
      onChange: model.useMediaDuration ? onMediaFileChange : undefined
    });

    return [...builder.build(), ...durationFields];
  }, [
    model,
    outdoorType,
    width,
    height,
    durationFields,
    onMediaFileChange
  ]);

  return fields;
};
