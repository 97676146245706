import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Order } from 'core/order/Order';
import { AddonFeatureManager, PermissionItem } from 'core';
import { DefaultStateModalModel, StateModalModel } from 'containers/Common/StateModalModel';
import _ from 'lodash';
import { useCallAPI } from 'hooks/useCallAPI';
import { L1ObjectChannelDetailModel, L1ObjectComponentsData, getL1ObjectChannelDetailModel } from './L1ObjectChannelDetailModel';
import { History } from 'history';
import { useCoreContext } from 'contexts/coreContext';

export type L1ObjectDetailModelData = {
  viewData: any;
  loading: boolean;
  currentUrl: string;
  l1Object?: L1Object;
  creativeManageModal?: StateModalModel;
  redirectPath?: string;
  componentsData?: L1ObjectComponentsData;
  l2ObjectList: any[];
  modifyPermissionItem: PermissionItem;
  canDonwloadRtbReport: boolean;
  showDownloadRtbReportModal: boolean;
  setShowDownloadRtbReportModal: (show: boolean) => void;
};

const defaultL1ObjectManager = new DefaultL1ObjectManager();

export const useL1ObjectDetailModel = (
  l1ObjectId: number | string,
  order: Order,
  addonFeatureManager: AddonFeatureManager,
  l1ObjectManager: L1ObjectManager = defaultL1ObjectManager
): L1ObjectDetailModelData => {

  const [l1Object, setL1Object] = useState<L1Object | undefined>(undefined);
  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);
  const {
    loading,
    callAPIs
  } = useCallAPI();
  const [channelDetailModel, setChannelDetailModel] = useState<L1ObjectChannelDetailModel | undefined>();
  const [showDownloadRtbReportModal, setShowDownloadRtbReportModal] = useState(false);

  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const searchString = query.get('search');
  const core = useCoreContext();
  const localeMeta = _.get(core, 'accountManager.localeMeta');

  const fetchL1Object = useCallback(async () => {
    callAPIs(
      [l1ObjectManager.getL1Object.bind(l1ObjectManager, l1ObjectId)],
      async (l1Object) => {
        const channelDetailModel = getL1ObjectChannelDetailModel(
          order,
          l1Object,
          addonFeatureManager,
          fetchL1Object,
          localeMeta
        );
        if (l1Object.adsOrderId !== order.id || !channelDetailModel) {
          const redirectPath =
            `/orders/${order.orderNumber}/campaign-groups/${l1Object.l1ObjectId}/error404`;
          setRedirectPath(redirectPath);
          return;
        }
        setL1Object(l1Object);

        callAPIs(
          [channelDetailModel.initChannelModel.bind(channelDetailModel, searchString)],
          () => setChannelDetailModel(channelDetailModel)
        );
      }
    );
  }, [
    callAPIs,
    searchString,
    l1ObjectManager,
    l1ObjectId,
    order,
    addonFeatureManager,
    localeMeta
  ]);

  const invokeFetchL1Object = useCallback((invoke: boolean) => {
    invoke && fetchL1Object();
  }, [fetchL1Object]);

  useEffect(() => {
    invokeFetchL1Object(!match.isExact && l1Object === undefined);
  }, [l1Object, invokeFetchL1Object, location.pathname, match.isExact]);

  useEffect(() => {
    invokeFetchL1Object(match.isExact);
  }, [invokeFetchL1Object, match.isExact]);

  const creativeManageModal = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const factoryModel = getCreativeManagementStateFactory(query, l1Object, channelDetailModel, history);
    if (!factoryModel) {
      return;
    }

    return new DefaultStateModalModel(
      factoryModel,
      (dirty) => {
        history.replace(location.pathname);
        invokeFetchL1Object(dirty);
      }
    );
  }, [location.search, location.pathname, invokeFetchL1Object, history, l1Object, channelDetailModel]);

  const viewData = useMemo(() => {
    if (!l1Object || !channelDetailModel) {
      return;
    }
    return _.omitBy({
      basic: channelDetailModel.getL1ObjectBasicViewData(),
      channelData: channelDetailModel.getL1ObjectChannelViewData()
    }, _.isUndefined);
  }, [l1Object, channelDetailModel]);

  const canDonwloadRtbReport = useMemo(() => {
    if (!l1Object) {
      return false;
    }
    if ([L1ObjectChannel.RTB, L1ObjectChannel.RETAIL_MEDIA].includes(l1Object.channel)) {
      return true;
    }
    if (l1ObjectManager.isOutdoorChannel(l1Object.channel)) {
      return true;
    }
    return false;
  }, [l1Object, l1ObjectManager]);

  return {
    loading,
    l1Object: l1Object,
    currentUrl: match.url,
    viewData,
    canDonwloadRtbReport,
    creativeManageModal,
    redirectPath,
    componentsData: _.get(channelDetailModel, 'componentsData'),
    l2ObjectList: _.get(channelDetailModel, 'l2ObjectList', []),
    modifyPermissionItem: l1ObjectManager.getModifyPermissionItem(l1Object),
    showDownloadRtbReportModal,
    setShowDownloadRtbReportModal
  };
};

const getCreativeManagementStateFactory = (
  query: URLSearchParams,
  l1Object: L1Object | undefined,
  channelDetailModel: L1ObjectChannelDetailModel | undefined,
  history: History
) => {
  const draftIdsParam = query.get('draftIds');
  const campaignIdsParam = query.get('campaignIds');
  const actionParam = query.get('action');
  if (!actionParam || (!campaignIdsParam && !draftIdsParam) || !l1Object) {
    return undefined;
  }

  if (!channelDetailModel) {
    return undefined;
  }

  const campaignIds = _.defaultTo(campaignIdsParam, '').split(',');
  const draftIds = _.defaultTo(draftIdsParam, '').split(',');
  return channelDetailModel.getCreativeManagementStateFactory(campaignIds, draftIds, actionParam, history);
};
