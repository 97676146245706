// import { useCallback, useState } from 'react';
import styles from './customOutdoorDevice.module.scss';
import { SelectComponent, SelectComponentModel } from '../SelectComponent';
// import { Button } from 'react-bootstrap';
// import i18n from 'i18n';
// import { MapContainer } from './MapContainer';

export type CustomOutdoorDeviceProps = {
  model: SelectComponentModel;
};

export const CustomOutdoorDevice: React.FC<CustomOutdoorDeviceProps> = ({
  model
}) => {

  // const [showMap, setShowMap] = useState<boolean>(false);

  // const triggerMapVisibility = useCallback(() => {
  //   setShowMap(prev => !prev);
  // }, []);

  return (
    <>
      {/* <Button
        className={styles.switchButton}
        variant='secondary'
        size='sm'
        onClick={triggerMapVisibility}
      >
        {showMap
          ? i18n.t<string>('customOutdoorDevice.buttons.switchToList')
          : i18n.t<string>('customOutdoorDevice.buttons.switchToMap')}
      </Button> */}
      <div
        className={styles.componentArea}
        // style={{ visibility: showMap ? 'hidden' : 'visible' }}
      >
        <SelectComponent model={model} />
      </div>
      {/* <div
        className={styles.componentArea}
        style={{ visibility: showMap ? 'visible' : 'hidden' }}
      >
        <MapContainer model={model} />
      </div> */}
    </>
  );
};
