import React from 'react';
import { AdPreview } from './AdPreview';

export const renderFBPreviews = (fbPreviewData, iframeConfig?: any) => {
  return Object.keys(fbPreviewData).map(previewType => (
    <AdPreview
      key={previewType}
      iframeString={fbPreviewData[previewType]}
      iframeConfig={iframeConfig}
    />
  ));
};
