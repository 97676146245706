import { FormConfig } from 'components/common/form/FormConfig';
import { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { validateEmpty } from 'utils/ValidateUtils';
import i18n from 'i18n';
import { Formik } from 'formik';
import { Form } from 'components/common/form/Form';
import { Page } from 'components/Page/Page';
import { CategoryFormModel } from './CategoryFormModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';

export const CategoryForm: React.FC<{
  sourceId: number;
  useModel: (sourceId: number) => CategoryFormModel;
}> = ({ sourceId, useModel }) => {

  const [formConfig, setFormConfig] = useState<FormConfig>();

  const {
    title,
    loading,
    initCategory,
    redirectPath,
    handleSubmit,
    tenmaxCategories
  } = useModel(sourceId);

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder()
            .addFormikInput({
              label: i18n.t<string>('category.labels.name'),
              name: 'name',
              validate: validateEmpty
            })
            .addFormikSelect({
              label: i18n.t<string>('category.labels.tenmaxCategory'),
              name: 'tenmaxCategory',
              options: tenmaxCategories,
              validate: validateEmpty,
              simpleValue: true
            })
            .build()
        )
        .build()
      )
      .build()
    );
  }, [tenmaxCategories]);

  const renderForm = useCallback((formikProps) => {
    const renderFormBtns = () => (
      <>
        <Form.SubmitButton/>
        <Form.CancelButton/>
      </>
    );
    return (
      <Form
        formikProps={formikProps}
        formConfig={formConfig}
        renderFormBtns={renderFormBtns}
      />
    );
  }, [formConfig]);

  if (!initCategory) {
    return <LoadingIndicator/>;
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <Page title={title}>
      {loading && <LoadingIndicator/>}
      <Formik
        initialValues={{
          ...initCategory,
          sourceId
        }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {renderForm}
      </Formik>
    </Page>
  );
};
