import { toast } from 'react-toastify';
import { AbstractAuditLogModel } from './AuditLogModel';
import _ from 'lodash';
import { renderColumn } from 'components/TableColumn/TableColumn';
import formatters from './AuditLogFormatters';
import { SelectOptions } from 'components/common/commonType';
import { AUDITLOG_SRC, AuditLogRecord } from 'core/auditLog/AuditLog';
import i18n from 'i18n';
import { AuditLogManager, DefaultAuditLogManager } from 'core/auditLog/AuditLogManager';
import { ColumnDescription, ExpandRowProps } from 'react-bootstrap-table-next';
import { L1Object } from 'core/l1Object/L1Object';

export enum AUDITLOG_COLUMN {
  DATE = 'ctime',
  SOURCE_INFO = 'srcID',
  EMAIL = 'email',
  EVENT = 'funcType',
  DETAIL = 'msg'
}

export type AuditLogListRow = {
  id: number,
  [AUDITLOG_COLUMN.DETAIL]: { [key: string]: {
    adminOnly?: boolean,
    org: string,
    to: string
  } }[],
  [AUDITLOG_COLUMN.DATE]: string;
  [AUDITLOG_COLUMN.SOURCE_INFO]: {
    id: number;
    type: AUDITLOG_SRC;
    name: string
  },
  [AUDITLOG_COLUMN.EMAIL]: string;
  [AUDITLOG_COLUMN.EVENT]: string;
};

export class CampaignGroupAuditLogModel extends AbstractAuditLogModel<AuditLogListRow> {

  nonExpandRowKey: number[] = [];
  l1ObjectTarget?: number | string;

  constructor (
    private orderId: number,
    private isAdmin: boolean,
    public l1ObjectList: L1Object[],
    auditLogManager: AuditLogManager = new DefaultAuditLogManager()
  ) {
    super(auditLogManager);
  }

  getSearchString () {
    let searchString = super.getSearchString();
    const l1ObjectTarget = this.l1ObjectTarget !== 0 && this.l1ObjectTarget !== undefined ?
      this.l1ObjectTarget :
      this.l1ObjectList.map(l1Object => l1Object.l1ObjectId).join(',');

    if (!_.isEmpty(searchString)) {
      return `${searchString}&l1ObjectIds=${l1ObjectTarget}`;
    }
    return `l1ObjectIds=${l1ObjectTarget}`;
  }

  getAuditLogDetail = (messages) => {
    return messages && Array.isArray(messages) ?
      messages.filter(msg => {
        if ('goGanGroupId' in msg) {
          return false;
        }
        if ('l1ObjectId' in msg) {
          return false;
        }
        const values: any = _.defaultTo(Object.values(msg), []);
        if (values.length === 0) return true;
        const content = values[0];
        return content.adminOnly ? content.adminOnly && this.isAdmin : true;
      }) :
      messages;
  }

  getAuditLogDataOfRecord = (record: AuditLogRecord) => {
    if (!record.msg || record.msg.length === 0) {
      this.nonExpandRowKey.push(record.id);
    }
    let srcName = '';
    if (record.srcType === AUDITLOG_SRC.L1Object) {
      const srcL1Object = this.l1ObjectList.find(l1Object => l1Object.l1ObjectId === record.srcID);
      srcName = _.get(srcL1Object, 'name', '');
    }
    return {
      id: record.id,
      [AUDITLOG_COLUMN.DETAIL]: this.getAuditLogDetail(record.msg),
      [AUDITLOG_COLUMN.DATE]: record.ctime,
      [AUDITLOG_COLUMN.SOURCE_INFO]: {
        id: record.srcID,
        type: record.srcType,
        name: srcName
      },
      [AUDITLOG_COLUMN.EMAIL]: record.email,
      [AUDITLOG_COLUMN.EVENT]: record.funcType
    };
  }

  async initAuditLog (page: number = 1) {
    try {
      const pageable = { page: page, sizePerPage: 10, sort: this.sortField === AUDITLOG_COLUMN.EMAIL ? 'userId' : this.sortField , direction: this.sortOrder };
      const auditLogFromServer = await this.auditLogManager.getOrderAndL1ObjectAuditLogs(this.orderId, pageable, this.getSearchString());
      this.pagination = auditLogFromServer.pagination;
      this.nonExpandRowKey = [];
      this.auditLogData = auditLogFromServer.records.map(this.getAuditLogDataOfRecord);
      this.updateFilteredAuditLogData();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.auditLogData = [];
    }
  }

  async getAuditLogFields () {
    try {
      this.auditLogFields = await this.auditLogManager.getOrderAndL1ObjectAuditLogsFields(this.orderId, this.l1ObjectTarget ? [this.l1ObjectTarget] : this.l1ObjectList.map(l1Object => l1Object.l1ObjectId));
      this.auditLogFields.sort();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
  }

  updateFilteredAuditLogData () {
    this.filteredAuditLogData = this.auditLogData ? this.auditLogData : [];
    const l1ObjectTarget = this.l1ObjectTarget;
    if (l1ObjectTarget) {
      this.filteredAuditLogData = this.filteredAuditLogData.filter(data => {
        return data.srcID.id === l1ObjectTarget && data.srcID.type === AUDITLOG_SRC.L1Object;
      });
    }

    return this.filteredAuditLogData;
  }

  getColumnFormatter (columnName) {
    switch (columnName) {
      case AUDITLOG_COLUMN.SOURCE_INFO:
        return formatters.srcInfoFormatter;
      case AUDITLOG_COLUMN.EVENT:
        return formatters.i18nFormatter;
      default:
        return;
    }
  }

  getSubFilters (): {
    [key: string]: {
      options: SelectOptions[],
      value?: string | number,
      onSelect: (l1ObjectTarget: string | number) => void;
    }} {
    return {
      l1ObjectFilter: {
        options: this.getL1ObjectOptions(),
        value: this.l1ObjectTarget,
        onSelect: this.setL1ObjectTarget
      }
    };
  }

  setL1ObjectTarget = (l1ObjectTarget) => {
    this.l1ObjectTarget = l1ObjectTarget;
    this.init(1, true);
  }

  getL1ObjectOptions (): SelectOptions[] {
    let options = this.l1ObjectList.map(l1Object => {
      return {
        label: `${l1Object.l1ObjectId}:${l1Object.name}`,
        value: l1Object.l1ObjectId
      };
    });
    options.unshift({
      label: i18n.t<string>('common.placeholder.all'),
      value: 0
    });
    return options;
  }

  getColumns (): ColumnDescription<AuditLogListRow>[] {
    const definitions = Object.values(AUDITLOG_COLUMN).map(columnToRender => {
      if (columnToRender === AUDITLOG_COLUMN.DETAIL) {
        return undefined;
      }
      return renderColumn(this.getAuditLogColumnDefinition(columnToRender), this.getColumnFormatter(columnToRender));
    });
    return _.compact(definitions);
  }

  getExpandRenderer (): ExpandRowProps<AuditLogListRow, number> {
    return {
      nonExpandable: this.nonExpandRowKey,
      renderer: formatters.detailExpandRenderer
    };
  }
}
