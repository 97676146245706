import { useState, useCallback } from 'react';
import _ from 'lodash';
import { ChartConfig, Column, Note, TargetKPI } from 'core/finalReport/FinalReport';
import { GROUP_LIMIT_MAP, SUMMABLE_COLUMNS } from 'core/finalReport/FinalReportConfig';

export const ELEMENT_SCALE_LV1 = 13;
export const ELEMENT_SCALE_LV2 = 15;

export const useFetchData = () => {
  const [error, setError] = useState<any>();

  const retryFetch = useCallback(async (fetchMethod, callback, tryCount) => {
    try {
      if (tryCount === 0) {
        throw Error;
      }
      const response = await fetchMethod();
      setError(null);
      // console.log('retry response', response);
      if (response.status === 'WAITING') {
        setTimeout(() => {
          retryFetch(fetchMethod, callback, tryCount - 1);
        }, 5000);
      } else {
        callback && callback(response);
      }
    } catch (err) {
      if (tryCount === 0) {
        console.error(err);
        setError(err);
      }
    }
  }, []);

  return [error, retryFetch];
};

export const handleNumberOrText = (
  value: number | string,
  decimals: number = 2,
  isPercentage: boolean = false
) => {
  if (_.isNil(value)) {
    return 'n/a';
  }
  if (typeof value !== 'number') {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    if (isPercentage) {
      let percentage = (value * 100).toFixed(decimals);
      return `${percentage}%`;
    } else {
      return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
};

export const handleStatistics = (
  type: string,
  payload: number | string,
  decimals: number = 2,
  currency: string = 'TWD'
) => {
  if (typeof payload === 'number') {
    switch (type) {
      case Column.NEW_USERS:
      case Column.LAPSE_USERS:
      case Column.EXISTING_USERS:
      case Column.CTR:
      case Column.VTR:
        return handleNumberOrText(payload, decimals, true);
      case Column.CPC:
      case Column.CPM:
      case Column.CPA:
      case Column.CPV:
      case Column.SPEND:
        let value = (payload !== 0) ? payload.toFixed(decimals) : 'n/a';
        return payload !== 0 ? `${currency} ${handleNumberOrText(value, decimals, false)}` : 'n/a';
      default:
        break;
    }
  }

  return handleNumberOrText(payload);
};

export const handleDateString = (
  dateString: string
) => {
  const shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const parts = dateString.split(' ');
  const year = parseInt(parts[2], 10);
  const month = _.findIndex(shortMonths, shortMonth => shortMonth === parts[1]);
  const day = parseInt(parts[0], 10);

  return new Date(year, month, day);
};

export const handleToAbbreviateText = (
  legend: string | null,
  length: number = 45
) => {
  if (legend === null) {
    return '';
  }
  if (legend.length <= length) {
    return handleNumberOrText(legend);
  }
  legend = legend.substring(0, length);
  return handleNumberOrText(legend + '...');
};

export const handleColumnsTemplate = (
  template: Column[],
  targetKPI: TargetKPI,
  showConversions: boolean
): Column[] => {
  let ignoreColumns: Column[] = (!showConversions) ? [Column.CONVS, Column.CPA] : [];
  if (targetKPI === TargetKPI.VIEW) {
    ignoreColumns = [...ignoreColumns, Column.CLICKS, Column.CTR, Column.CPC];
  } else {
    ignoreColumns = [...ignoreColumns, Column.VIEWS, Column.VTR, Column.CPV];
  }
  return _.filter(template, column => !_.includes(ignoreColumns, column));
};

export const handleNotesTemplate = (
  template: Note[],
  targetKPI: TargetKPI
): Note[] => {
  let ignoreNotes: Note[] = [];
  if (targetKPI === TargetKPI.VIEW) {
    ignoreNotes = [Note.CLICKS, Note.CTR, Note.CPC];
  } else {
    ignoreNotes = [Note.VIEWS, Note.VTR, Note.CPV];
  }
  return _.filter(template, note => !_.includes(ignoreNotes, note));
};

export const handleChartColorGradients = (
  colorStart: string,
  colorEnd: string,
  colorCount: number
  ): string[] => {

  const hex = (c: number) => {
    const s = '0123456789abcdef';
    if (c === 0 || _.isNaN(c)) return '00';
    const round = Math.round(Math.min(Math.max(0, c), 255));
    return s.charAt((round - round % 16) / 16) + s.charAt(round % 16);
  };

  const convertToRGB = (hex: string) => {
    const trimmedHex = (hex.charAt(0) === '#') ? hex.substring(1, 7) : hex;
    return [
      parseInt(trimmedHex.substring(0, 2), 16),
      parseInt(trimmedHex.substring(2, 4), 16),
      parseInt(trimmedHex.substring(4, 6), 16)
    ];
  };

  const gradientEnable: boolean = (!!colorStart) && (!!colorEnd) && (colorStart !== colorEnd);
  const defaultColorStart = '#007f90';
  const defaultColorEnd = '#d1eff1';
  const start = gradientEnable ? convertToRGB(colorStart) : convertToRGB(defaultColorStart);
  const end = gradientEnable ? convertToRGB(colorEnd) : convertToRGB(defaultColorEnd);
  const colorNums: number[] = [];
  const saida: string[] = [];

  if (colorCount === 1) {
    return [colorStart];
  }

  for (let i = 0; i < colorCount; i++) {
    const alpha = i * (1.0 / (colorCount - 1));
    colorNums[0] = start[0] * (1 - alpha) + alpha * end[0];
    colorNums[1] = start[1] * (1 - alpha) + alpha * end[1];
    colorNums[2] = start[2] * (1 - alpha) + alpha * end[2];

    const convertedHex = `#${hex(colorNums[0])}${hex(colorNums[1])}${hex(colorNums[2])}`;
    saida.push(convertedHex);
  }

  return saida;
};

export const preprocessReportsData = (
  sourceData: any[],
  group: string,
  templateColumns: string[],
  priority: string[],
  notAvailableColumns: string[] = [],
  identityColName: string = 'dimension'
) => {
  let columns: string[] = [group, ...templateColumns];
  let rows: {
    [key: string]: string | number
  } [] = [];

  const sortColumn = (columns) => {
    if (_.isEmpty(priority)) return columns;
    return columns.sort((key1, key2) =>
      priority.indexOf(key1) > priority.indexOf(key2) ? 1 : -1
    );
  };
  const sortReportRow = (row) => {
    return sortColumn(Object.keys(row)).reduce((result, key) => {
      result[key] = row[key];
      return result;
    }, {});
  };

  _.forEach(sourceData, (information) => {
    let identityValue = _.get(information, identityColName, '');
    columns = sortColumn([group, ...templateColumns]);
    rows = [
      ...rows,
      {
        [group]: identityValue,
        ..._.reduce(templateColumns, (result, column) => ({
          ...result,
          [column]: _.get(information, column, 'n/a')
        }), {}),
        ..._.reduce(notAvailableColumns, (result, column) => ({
          ...result,
          [column]: 'n/a'
        }), {})
      }
    ].map(row => sortReportRow(row));
  });

  return {
    columns,
    rows
  };
};

export const calPerformance = (performance: object) => {
  const columns: string[] = Object.keys(performance);
  const clicks = _.get(performance, Column.CLICKS, 0);
  const impressions = _.get(performance, Column.IMPRESSIONS, 0);
  const convs = _.get(performance, Column.CONVS, 0);
  const views = _.get(performance, Column.VIEWS, 0);
  const spend = _.get(performance, Column.SPEND, 0);
  _.forEach(columns, column => {
    switch (column) {
      case Column.CTR:
        performance[column] = (impressions !== 0) ? clicks / impressions : 0;
        break;
      case Column.CPM:
        performance[column] = (impressions !== 0) ? spend / impressions * 1000 : 0;
        break;
      case Column.CPC:
        performance[column] = (clicks !== 0) ? spend / clicks : 0;
        break;
      case Column.VTR:
        performance[column] = (impressions !== 0) ? views / impressions : 0;
        break;
      case Column.CPV:
        performance[column] = (views !== 0) ? spend / views : 0;
        break;
      case Column.CPA:
        performance[column] = (convs !== 0) ? spend / convs : 0;
        break;
      default:
        break;
    }
  });
  return performance;
};

export const mergeOthers = (group: string, performances: object[], kpiSortKey: Column) => {
  const limit = GROUP_LIMIT_MAP[group];
  const othersPerformance = _.find(performances, performance => _.get(performance, 'dimension') === 'Others');
  if (othersPerformance && limit) {
    const sortedPerformances: object[] = _.flow([
      performances => _.filter(performances, performance => _.get(performance, 'dimension') !== 'Others'),
      performances => _.sortBy(performances, performance => performance[kpiSortKey]).reverse()
    ])(performances);
    const newPerformances = _.slice(sortedPerformances, 0, limit - 1);
    const restPerformances = _.slice(sortedPerformances, limit - 1);
    const newOthersPerformance = _.reduce(restPerformances, (result, performance) => {
      _.forEach(Object.keys(performance), column => {
        if (SUMMABLE_COLUMNS.includes(column)) {
          result[column] = _.get(result, column, 0) + _.get(performance, column, 0);
        }
      });
      return result;
    }, {
      ...othersPerformance
    });
    return _.concat(newPerformances, newOthersPerformance);
  }
  return performances;
};

const dateSorter = (date1: string, date2: string) => {
  let date1Object = new Date(date1);
  let date2Object = new Date(date2);

  if (date1Object.getFullYear() !== date2Object.getFullYear()) {
    return date1Object.getFullYear() - date2Object.getFullYear();
  }
  if (date1Object.getMonth() !== date2Object.getMonth()) {
    return date1Object.getMonth() - date2Object.getMonth();
  }
  return date1Object.getDate() - date2Object.getDate();
};

export const generateChartConfigs = (
  targetKPI: TargetKPI,
  chartConfigs: ChartConfig[],
  orderStartDate: Date | string = '',
  orderEndDate: Date | string = ''
) => {
  const uniqueFields = _.uniq(_.map(chartConfigs, config => config.field));
  const uniqueDates = _.uniq(_.map(chartConfigs, config => config.date));
  const sortedDates = uniqueDates.sort(dateSorter);

  const startDate = new Date(_.defaultTo(orderStartDate, sortedDates[0]));
  const endDate = new Date(_.defaultTo(orderEndDate, sortedDates[sortedDates.length - 1]));

  let labels: string[] = [];
  let contents: {
    name: string,
    value: number[]
  } [] = [];
  let totals: number[] = [];
  let maxValueScaleX = 0;
  let maxValueScaleY = 0;

  let date = new Date(startDate);
  while (date <= endDate) {
    labels = [
      ...labels,
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    ];
    date.setDate(date.getDate() + 1);
  }

  _.forEach(uniqueFields, field => {
    contents = [
      ...contents,
      {
        name: field,
        value: Array(labels.length).fill(0)
      }
    ];
  });

  const totalImpressions = Array(labels.length).fill(0);
  const totalUnits = Array(labels.length).fill(0);

  _.forEach(chartConfigs, config => {
    let indexOfDate = _.indexOf(labels, config.date);
    let indexOfField = _.findIndex(contents, content => content.name === config.field);
    if (indexOfDate !== -1) {
      contents[indexOfField].value[indexOfDate] = config.impressions;
      totalImpressions[indexOfDate] += config.impressions;
      totalUnits[indexOfDate] += config.units;
    }
  });

  const SCALE = totalImpressions.reduce((a, b) => a + b, 0) / totalImpressions.length < 100000 ? 1000 : 1000000;
  const scale = SCALE === 1000 ? '(in thsnd.)' : '(in mil.)';

  contents = _.map(contents, content => {
    return {
      ...content,
      value: _.map(content.value, value => parseFloat((value / SCALE).toFixed(3)))
    };
  });

  const rateLabel = (targetKPI === TargetKPI.VIEW) ? Column.VTR : Column.CTR;
  const rate = _.map(totalImpressions, (impressions, index) => {
    let initUnit = impressions !== 0 ? totalUnits[index] / impressions : 0;
    let parsedDigit = 1 - Math.floor(Math.log(initUnit) / Math.log(10));
    parsedDigit = _.inRange(parsedDigit, 0, 101) ? parsedDigit : 3;
    let processedRate = parseFloat(initUnit.toFixed(parsedDigit)) * 100;

    maxValueScaleY = Math.max(maxValueScaleY, processedRate);

    return parseFloat(processedRate.toFixed(parsedDigit));
  });

  contents = _.isEmpty(contents) ? contents : [
    ...contents,
    {
      name: rateLabel,
      value: rate
    }
  ];

  totals = _.map(labels, (_1, labelIndex) => {
    let total = 0;
    _.forEach(contents, (content) => {
      if (content.name !== rateLabel) {
        total += content.value[labelIndex];
      }
    });
    maxValueScaleX = Math.max(maxValueScaleX, total);

    return parseFloat(total.toFixed(3));
  });

  return {
    scale,
    labels,
    contents,
    totals,
    maxValueScaleX,
    maxValueScaleY
  };
};

export const isTableHasOverFlown = (
  rows: {[key: string]: any} [],
  group: string
) => {
  let hasOverFlown = false;

  _.forEach(rows, (row) => {
    _.forEach(Object.keys(row), (property) => {
      if (property !== group) {
        let rawValue = !_.isNil(row[property]) ? row[property] : 'n/a';
        let text = handleStatistics(property, rawValue);
        hasOverFlown = (text.length >= ELEMENT_SCALE_LV1) || hasOverFlown;
      }
    });
  });

  return hasOverFlown;
};
