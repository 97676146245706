import { SelectOptions } from 'components/common/commonType';
import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import { LocaleMeta } from 'core';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import i18n from 'i18n';
import { DefaultRdpManager } from 'core/rdp/RdpManager';
import { LIMITATION_TYPE, LimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';
import { getRetailLimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/retailLimitationInventorySetting';

const rdpManager = new DefaultRdpManager();

export interface RetailFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractRetailFormModel extends DefaultRtbCampaignBasicFormModel
 implements RetailFormModel {

  override get campaignAdType (): AdType {
    return AdType.RETAIL;
  }

  override async init () {
    if (this.l1Object?.channel !== L1ObjectChannel.RETAIL_MEDIA) {
      return;
    }
    try {
      const retailers = await rdpManager.getRetails();
      this.retailerOptions = retailers ? retailers.map(retail => ({
        value: retail.id,
        label: i18n.t<string>(`retailers.${retail.id}`)
      })) : undefined;
    } catch (e) {}
  }

  override getLimitationInventorySetting (
    requiredOperateOfTaTypes: {[key: string]: string[]},
    campaignBasic: RtbCampaignBasic,
    segments: SelectOptions[],
    localeMeta: LocaleMeta
  ): LimitationInventorySettings[] {
    return getRetailLimitationInventorySettings(
      requiredOperateOfTaTypes,
      campaignBasic.advertiserId,
      LIMITATION_TYPE.CAMPAIGN,
      segments,
      localeMeta,
      campaignBasic.retailType
    );
  }
}

export class EditRetailFormModel extends AbstractRetailFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateRetailFormModel extends AbstractRetailFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
