import { DefaultGoSegmentManager, GoSegmentManager } from 'core/goSegment/GoSegmentManager';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import { GoSegmentFormData } from 'core/goSegment/GoSegment';
import { defaultItemSetting } from 'components/CampaignLimitation/SelectItemComponent';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { GoSegmentFormModelData, useCreateGoSegmentFormModel, useEditGoSegmentFormModel } from './GoSegmentFormModel';
import { useCallAPI } from 'hooks/useCallAPI';
import { DEFAULT_INVENTORY, LimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';

const useFetchSegmentLimitationTypeObject = (audienceBrief = '') => {

  const [inventorySettings, setInventorySettings] = useState<any[]>([]);
  const [limitationTypeObject, setLimitationTypeObject] = useState<any>({});
  const { callAPIs } = useCallAPI();

  const initLimitationTypeObject = useCallback((audienceBrief, inventorySettings) => {
    const itemGroups = audienceBrief.split('AND');
    const items = _.flatMap(itemGroups
      .map(itemGroup => itemGroup
        .split('OR')
        .map(item => item.replace(/\(|\)/gi, '').trim())
      )
    );
    let limitationTypeObject = {};
    for (let inventorySetting of inventorySettings) {
      const options = inventorySetting.cb();
      if (!Array.isArray(options)) {
        continue;
      }
      const selectedOptions = _.remove(items, item => !!options.find(option => option.value === item));
      if (selectedOptions.length > 0) {
        limitationTypeObject[inventorySetting.name] = {
          type: inventorySetting.name,
          value: selectedOptions.map(item => ({ value: item, label: item }))
        };
      }
    }
    return limitationTypeObject;
  }, []);

  useEffect(() => {
    const manager: GoSegmentManager = new DefaultGoSegmentManager();
    callAPIs([
      manager.getGoSegmentProductFocus.bind(manager)
    ], goSegmentProductFocus => {
      const inventorySettings: LimitationInventorySettings[] = [
        { name: DEFAULT_INVENTORY }
      ];
      goSegmentProductFocus.forEach(data => {
        inventorySettings.push({
          name: data.product_focus,
          title: data.product_focus,
          cb: () => (data.segments.map(segment => ({
            label: segment,
            value: segment
          }))),
          singleSelect: false,
          itemSetting: defaultItemSetting(),
          searchPlaceholder: '',
          component: SelectComponent
        });
      });
      setInventorySettings(inventorySettings);
      setLimitationTypeObject(initLimitationTypeObject(audienceBrief, inventorySettings));
    });
  }, [initLimitationTypeObject, audienceBrief, callAPIs]);

  return {
    inventorySettings,
    limitationTypeObject,
    setLimitationTypeObject
  };
};

export const useCreateGoSegmentSelectFormModel = (): GoSegmentFormModelData => {

  const { validate: basicValidate , ...basicProps } = useCreateGoSegmentFormModel();
  const extraData = useFetchSegmentLimitationTypeObject();
  const validate = (value: Partial<GoSegmentFormData>) => {
    const basicError = basicValidate(value);
    return _.omitBy({
      ...basicError,
      audienceBrief: validateEmpty(value.audienceBrief)
    }, _.isEmpty);
  };

  return {
    ...basicProps,
    extraData,
    formType: 'create',
    validate
  };
};

export const useEditGoSegmentSelectFormModel = (
  segmentId: number | string,
  audienceBrief: string
): GoSegmentFormModelData => {
  const { validate: basicValidate, ...basicProps } = useEditGoSegmentFormModel(segmentId);
  const extraData = useFetchSegmentLimitationTypeObject(audienceBrief);
  const validate = (value: Partial<GoSegmentFormData>) => {
    const basicError = basicValidate(value);
    return _.omitBy({
      ...basicError,
      audienceBrief: validateEmpty(value.audienceBrief)
    }, _.isEmpty);
  };

  return {
    ...basicProps,
    extraData,
    formType: 'edit',
    validate
  };
};
