import React from 'react';
import { VariableSizeList as List } from 'react-window';
import ReactSelect, { createFilter, components } from 'react-select';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './select.module.scss';
import classnames from 'classnames/bind';
import { SelectOptions } from '../commonType';
import i18n from 'i18n';

type SelectProps = {
  labelKey: string;
  valueKey: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  options: SelectOptions[];
  simpleValue?: boolean;
  value?: any;
  name?: string;
  className?: any;
  onChange: any;
  isClearable?: boolean;
  placeholder?: string;
  defaultValue?: any;
  maxHeight?;
  getValue?;
  closeMenuOnSelect?: boolean;
  maxMenuHeight?: number;
  optionComponent?: any;
  singleValue?: any;
  multiValueLabel?: any;
  componentWidthFitParent?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
};

const DropdownIndicator = (props) => {
  const { innerProps, selectProps } = props;
  const menuIsOpen = selectProps.menuIsOpen;
  const icon = menuIsOpen ? faCaretUp : faCaretDown;

  return (
      <div
        {...innerProps}
        className={styles.dropDown}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
  );
};

const ClearIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps }
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <FontAwesomeIcon icon={faTimes} />
    </div>
  );
};

const IndicatorSeparator = (props) => (<span/>);

const isOptionDisabled = (option) => option.disabled;
class Select extends React.Component<SelectProps> {
  cssClass: any;
  static defaultProps = {
    labelKey: 'label',
    valueKey: 'value',
    isMulti: false,
    isDisabled: false,
    isLoading: false,
    options: [],
    simpleValue: false,
    onChange: _.noop,
    isClearable: false,
    placeholder: 'common.placeholder.pleaseSelect',
    closeMenuOnSelect: true,
    defaultValue: []
  };

  constructor (props) {
    super(props);
    this.hanldeChange = this.hanldeChange.bind(this);
    this.cssClass = classnames.bind(styles);
    this.getMenuList = this.getMenuList.bind(this);
  }

  shouldComponentUpdate (nextProps) {
    return nextProps.options !== this.props.options ||
      nextProps.value !== this.props.value ||
      nextProps.className !== this.props.className ||
      nextProps.onChange !== this.props.onChange ||
      nextProps.isDisabled !== this.props.isDisabled;
  }

  getSelectOptionValue (value) {
    const { simpleValue, isMulti, valueKey, options } = this.props;
    if (!simpleValue) {
      return value;
    }
    if (_.isNil(value)) {
      return isMulti ? [] : null;
    }
    const filterSimpleSingleValue = (option: SelectOptions[]) => {
      const allOptions = _.flatten(option.map(o => o.options ? o.options : [])).concat(option);
      return allOptions.find(o => o[valueKey] === value) || null;
    };
    const filterSimpleMutipleValue = (option: SelectOptions[]) => {
      const allOptions = _.flatten(option.map(o => o.options ? o.options : [])).concat(option);
      return allOptions.filter(o => value.includes(o[valueKey]));
    };
    const filterSimpleValue = isMulti
      ? filterSimpleMutipleValue
      : filterSimpleSingleValue;
    return filterSimpleValue(options);
  }

  getValue () {
    const { value } = this.props;
    return this.getSelectOptionValue(value);
  }

  getDefaultValue () {
    const { defaultValue } = this.props;
    return this.getSelectOptionValue(defaultValue);
  }

  hanldeChange (optionValue) {
    const {
      simpleValue = false,
      isMulti,
      valueKey,
      onChange: parentHandleChange = _.noop
    } = this.props;
    if (simpleValue && optionValue) {
      const selectValue = isMulti
        ? optionValue.map(o => o[valueKey])
        : optionValue[valueKey];
      parentHandleChange(selectValue);
    } else {
      parentHandleChange(optionValue);
    }
  }

  getMenuList ({ children, options, maxHeight, getValue, ...props }) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const getItemSize = index => {
      if (!ctx) {
        return 35;
      }
      const predictionWidth = ctx.measureText(children[index]['props']['label']).width;
      if (predictionWidth < 262) {
        return 35;
      }
      if (predictionWidth > 352) {
        return 70;
      }
      return 50;
    };

    if (!children.length) {
      // @ts-ignore
      return <components.NoOptionsMessage {...props} />;
    }

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={getItemSize}
        initialScrollOffset={0}
      >
        {({ index, style }) => <div style={style} >{children[index]}</div>}
      </List>
    );
  }

  render () {
    const {
      labelKey,
      valueKey,
      isMulti,
      isDisabled,
      isLoading,
      options,
      name,
      className: parentClass,
      isClearable,
      placeholder,
      closeMenuOnSelect,
      maxMenuHeight,
      optionComponent,
      singleValue,
      multiValueLabel,
      componentWidthFitParent,
      menuPlacement
    } = this.props;
    const value = this.getValue();
    const defaultValue = this.getDefaultValue();
    const getOptionLabel = (option: any) => option[labelKey];
    const getOptionValue = (option: any) => option[valueKey];
    const className = this.cssClass(parentClass, ['selectComponent'], {
      isMulti: isMulti,
      widthFitParent: componentWidthFitParent
    });
    let components = { DropdownIndicator, IndicatorSeparator, ClearIndicator };
    if (optionComponent) {
      // @ts-ignore
      components = { ...components, Option: optionComponent };
    }
    if (singleValue) {
      // @ts-ignore
      components = { ...components, SingleValue: singleValue };
    }
    if (multiValueLabel) {
      // @ts-ignore
      components = { ...components, MultiValueLabel: multiValueLabel };
    }
    if (options.length > 400) {
      // @ts-ignore
      components = { ...components, Option: optionComponent ? optionComponent : CustomOption, MenuList: this.getMenuList };
    }
    return (
      <ReactSelect
        menuPlacement={menuPlacement ? menuPlacement : 'auto'}
        inputId={name}
        components={components}
        className={className}
        value={value}
        defaultValue={defaultValue}
        name={name}
        options={options}
        onChange={this.hanldeChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={isDisabled}
        isLoading={isLoading}
        classNamePrefix='react-select'
        isClearable={isClearable}
        filterOption={createFilter({ ignoreAccents: false })}
        placeholder={placeholder && i18n.t<string>(placeholder)}
        maxMenuHeight={maxMenuHeight}
        isOptionDisabled={isOptionDisabled}
      />
    );
  }
}

class CustomOption extends React.Component<any> {

  render () {
    const { innerProps, isFocused, ...otherProps } = this.props;
    const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
    const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };
    return (
        // @ts-ignore
      <components.Option {...newProps} className='react-select__option'>{this.props.children}
      </components.Option>
    );
  }
}

export default Select;
