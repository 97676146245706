// For UI usage
export enum Performance {
  OVERALL = 'OVERALL',
  DEEP_DIVE = 'DEEP_DIVE'
}

// For server DS usage
export enum ReportSection {
  PLATFORM = 'platform',
  USERS = 'users',
  L1OBJECT = 'l1Object',
  // UI Merged l1Objects in order section
  ORDER = 'order'
}

// For UI and i18n usage
export enum NoteSection {
  PLATFORM = 'platform',
  ADS_IMPACT = 'ads_impact',
  ADS_IMPACT_USERS = 'ads_impact_users',
  USERS = 'users',
  L1OBJECT = 'l1Object'
}

// For server DS usage
export enum Platform {
  FACEBOOK = 'facebook',
  MESSENGER = 'messenger',
  AUDIENCE_NETWORK = 'audience_network',
  INSTAGRAM = 'instagram',
  OPEN_RTB = 'open_rtb',
  RETAIL_MEDIA = 'retail_media',
  EDIMAX = 'edimax',
  MESSAGE = 'message',
  UNKNOWN = 'unknown'
}

// For server DS usage
export enum BasicSetting {
  CAMPAIGN_NAME = 'campaign_name',
  PERIOD = 'period',
  BUDGET = 'budget',
  ORDER_NAME = 'order_name'
}

// For server DS usage
export enum TargetKPI {
  IMPRESSION = 'impression',
  CLICK = 'click',
  CONVERSION = 'conversion',
  VIEW = 'view'
}

// For server DS usage
export enum Group {
  PLATFORM = 'platform',
  AGE_GROUP = 'age_group',
  GENDER = 'gender',
  AUDIENCE_SEGMENT = 'audience_segment',
  DOMAIN = 'domain',
  REGION = 'region',
  CREATIVE = 'creative',
  SERVICE_AREA = 'service_area',
  L1OBJECT = 'l1Object',
  TENMAX_SEGMENT = 'tenmax_segment',
  TRAFFIC_CATEGORY = 'traffic_category'
}

// For server DS usage
export enum Column {
  IMPRESSIONS = 'impressions',
  RAW_IMPRESSIONS = 'rawImpressions',
  REACH = 'reach',
  CLICKS = 'clicks',
  VIEWS = 'views',
  CTR = 'CTR',
  VTR = 'VTR',
  CPC = 'CPC',
  CPM = 'CPM',
  CPV = 'CPV',
  SALES = 'sales',
  TRANSACTIONS = 'transactions',
  ITEMS_SOLD = 'items_sold',
  NEW_USERS = 'new_users',
  LAPSE_USERS = 'lapse_users',
  EXISTING_USERS = 'existing_users',
  BASKET_SIZE = 'basket_size',
  CONVS = 'convs',
  CPA = 'CPA',
  SPEND = 'spend'
}

// For UI and i18n usage
export enum Note {
  ACTUAL_IMPRESSIONS = 'actual_impressions',
  TARGET_IMPRESSIONS = 'target_impressions',
  IMPRESSIONS = 'impressions',
  REACH = 'reach',
  CLICKS = 'clicks',
  VIEWS = 'views',
  CTR = 'CTR',
  VTR = 'VTR',
  CPC = 'CPC',
  CPM = 'CPM',
  CPV = 'CPV',
  FACEBOOK = 'facebook',
  SALES = 'sales',
  TRANSACTIONS = 'transactions',
  ITEMS_SOLD = 'items_sold',
  NEW_USERS = 'new_users',
  LAPSE_USERS = 'lapse_users',
  EXISTING_USERS = 'existing_users',
  BASKET_SIZE = 'basket_size',
  SERVICE_AREA = 'service_area',
  BENCHMARKS = 'benchmarks',
  OTHERS = 'others'
}

// For server DS usage
export enum BenchmarkObjective {
  AWARENESS = 'AWARENESS',
  TRAFFIC = 'TRAFFIC',
  MIXED = 'MIXED'
}

export type ChartConfig = {
  field: string;
  date: string;
  units: number;
  impressions: number;
};
