import { SelectOptions } from 'components/common/commonType';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { OrderType } from 'core/order/Order';
import { compact } from 'lodash';
import { DEFAULT_INVENTORY, LimitationInventorySettings } from './limitationSettingsType';
import { fbTAInventories } from '../fbTAInventory';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { defaultItemSetting, ItemType } from 'components/CampaignLimitation/SelectItemComponent';
import i18n from 'i18n';

export const getAdSetLimitationInventorySettings = (
  operate: string,
  optimizationGoal: L2ObjectOptimizationGoal | undefined,
  fbCountries: SelectOptions[],
  goSegments: SelectOptions[],
  orderType: OrderType
): LimitationInventorySettings[] => compact([
  {
    name: DEFAULT_INVENTORY
  },
  {
    ...fbTAInventories.geo_locations,
    requiredOperate: optimizationGoal !== L2ObjectOptimizationGoal.SALES ? ['include'] : undefined,
    ignoreAddonFeature: true,
    cb: () => fbCountries,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      hideExtra: true
    },
    searchPlaceholder: 'limitation.placeholders.searchCountryCity',
    component: SelectComponent,
    supportOperates: ['include', 'exclude']
  },
  {
    ...fbTAInventories.publisher_platforms,
    ignoreAddonFeature: true,
    cb: () => ([
      {
        label: 'Facebook',
        value: 'facebook'
      },
      {
        label: 'Instagram',
        value: 'instagram'
      },
      {
        label: 'Audience Network',
        value: 'audience_network'
      }
    ]),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'targeting.fb.placeholders.search_publisher_platforms',
    validator: (_1, value: SelectOptions[]) => {
      if (value.length === 1 && value[0].value === 'audience_network') {
        return i18n.t<string>('targeting.fb.errors.audience_network');
      }
    },
    component: SelectComponent,
    supportOperates: ['include']
  },
  orderType === OrderType.GOJEK ? {
    ...fbTAInventories.segment,
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.autoByGroupSetting,
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchGojekSegment',
    cb: () => goSegments,
    component: SelectComponent,
    supportOperates: ['include', 'exclude']
  } : undefined,
  {
    ...fbTAInventories.user_os,
    showWithLimitation: ['user_device'],
    requiredOperate: optimizationGoal === L2ObjectOptimizationGoal.SALES ? ['include'] : undefined,
    ignoreAddonFeature: true,
    disable: optimizationGoal === L2ObjectOptimizationGoal.SALES && operate === 'edit',
    cb: () => ([{ label: 'Android', value: 'Android' }, { label: 'iOS', value: 'iOS' }]),
    singleSelect: optimizationGoal === L2ObjectOptimizationGoal.SALES,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchOs',
    component: SelectComponent,
    validator: (operate, value, limitationValue) => {
      if (optimizationGoal === L2ObjectOptimizationGoal.SALES && (!value || value.length === 0)) {
        return i18n.t<string>('targeting.fb.errors.user_os');
      }
      const userDevice = limitationValue[operate].find(ta => ta.type === 'user_device');
      if (userDevice && Array.isArray(userDevice.value) && userDevice.value.length > 0 && (!value || value.length === 0)) {
        return i18n.t<string>('targeting.fb.errors.user_device_need_os');
      }
    },
    supportOperates: ['include']
  },
  {
    ...fbTAInventories.user_device,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: fbTAInventories.user_device.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchDevice',
    cb: () => ([{ label: 'Smartphone', value: 'Smartphone' }, { label: 'Tablet', value: 'Tablet' }]),
    component: SelectComponent,
    supportOperates: ['include']
  }
]);
