import axios, { AxiosInstance } from 'axios';
import client, { clientWithoutErrorHandler } from './RestClient';
import { EstimatedAudience, GoSegment, GoSegmentFormData, GoSegmentListRecord, GoSegmentProductFocus, GoSegmentType, GoSegmentUpdateMode } from 'core/goSegment/GoSegment';
import _ from 'lodash';
import { UploadEvent } from 'UploadProgressManager';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';

export interface GoSegmentWebService {
  getGoSegment (segmentId: number | string): Promise<GoSegment>;
  getGoSegments (agencyId?: number | string): Promise<GoSegmentListRecord[]>;
  createGoSegment (segment: GoSegmentFormData): Promise<number | string>;
  updateGoSegment (segmentId: number | string, segment: GoSegmentFormData): Promise<void>;
  deleteGoSegment (segmentId: number | string): Promise<void>;
  uploadSegmentFile (segmentId: number | string, file: File, mode?: GoSegmentUpdateMode): Promise<void>;
  getGoSegmentProductFocus (): Promise<GoSegmentProductFocus[]>;
  getSegmentSQL (audienceBrief: string): Promise<string>;
  getGoSegmentCount (audienceBrief: string): Promise<any>;
  downloadSegment (segmentId: string | number): Promise<void>;
  getGoSegmentEstimate (channel: string, body: any, accountId?: string): Promise<EstimatedAudience>;
}

export class RestfulGoSegmentWebService implements GoSegmentWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client, private defualtFileDownload: any = fileDownload) {
    this.restClient = restClient;
  }

  async getGoSegment (segmentId: number | string): Promise<GoSegment> {
    const response = await this.restClient.get(`/v2/go-segments/${segmentId}`);
    return wrapGoSegment(response.data);
  }

  async getGoSegments (agencyId?: number | string): Promise<GoSegmentListRecord[]> {
    const path = agencyId ? `/v2/go-segments?agencyId=${agencyId}` : '/v2/go-segments';
    const response = await this.restClient.get(path);
    return _.get(response, 'data.records', []).map(wrapGoSegmentListRecord).filter(record => record.status !== 'DELETED');
  }

  async createGoSegment (segment: GoSegmentFormData): Promise<number | string> {
    const response = await this.restClient.post('/v2/go-segments', {
      advertiserID: segment.advertiserId,
      name: segment.name,
      description: segment.description,
      type: segment.type,
      audienceBrief: segment.audienceBrief,
      lookalike: segment.lookalike ? {
        ...segment.lookalike,
        ratio: (segment.lookalike.ratio / 100).toFixed(2)
      } : undefined
    });
    return response.data.dmpSegmentID;
  }

  async uploadSegmentFile (segmentId: number | string, file: File, mode: GoSegmentUpdateMode = GoSegmentUpdateMode.OVERRIDE): Promise<void> {
    const response = await this.restClient.get(`/v2/go-segments/${segmentId}/signed_url?mode=${mode}`);
    const {
      signed_url,
      method,
      content_type
    } = response.data;

    // 3MB per second
    const uploadEstimateSecond = file.size / 1048576 / 3;
    const event = new CustomEvent<any>(UploadEvent.UPLOAD_START, {
      detail: {
        id: segmentId,
        totalSecond: uploadEstimateSecond,
        remainSecond: uploadEstimateSecond
      }
    });
    window.dispatchEvent(event);

    const httpRequest = axios[method ? method.toLowerCase() : 'put'];
    httpRequest(signed_url, file, {
      headers: { 'Content-Type': content_type ? content_type : 'text/csv' }
    }).then(async () => {
      try {
        await this.restClient.put(`/v2/go-segments/${segmentId}/mark_upload_as_completed`);
        const event = new CustomEvent<any>(UploadEvent.UPLOAD_END, {
          detail: { id: segmentId }
        });
        window.dispatchEvent(event);
        toast.success(`Segment file of ${segmentId} uploaded`);
      } catch (e) {
        toast.success(`Upload segment file of ${segmentId} failed`);
      }
    });
  }

  async updateGoSegment (segmentId: number | string, segment: GoSegmentFormData): Promise<void> {
    return this.restClient.patch(`/v2/go-segments/${segmentId}`, {
      name: segment.name,
      description: segment.description,
      lookalike: segment.lookalike ? {
        ...segment.lookalike,
        ratio: (segment.lookalike.ratio / 100).toFixed(2)
      } : undefined
    });
  }

  async deleteGoSegment (segmentId: number | string): Promise<void> {
    // This is intentional
  }

  async getGoSegmentProductFocus (): Promise<GoSegmentProductFocus[]> {
    const response = await this.restClient.get('/v2/go-segments/gojek-core-segments');
    return _.get(response.data, 'data', []);
  }

  async getSegmentSQL (audienceBrief: string): Promise<string> {
    const response = await this.restClient.get(`/v2/go-segments/audience-brief/validate?q=${encodeURIComponent(audienceBrief)}`);
    return _.get(response.data, 'big_query_sql');
  }

  async getGoSegmentCount (audienceBrief: string): Promise<any> {
    const response = await this.restClient.get(`/v2/go-segments/audience-info-by-brief?brief=${encodeURIComponent(audienceBrief)}`);
    return {
      deviceIdCount: _.get(response.data, 'num_device_ids', 0),
      customerIdCount: _.get(response.data, 'num_customer_ids', 0)
    };
  }

  async downloadSegment (segmentId: string | number): Promise<void> {
    const response = await this.restClient.get(`/v2/go-segments/${segmentId}/export`);
    this.defualtFileDownload(response.data, `${segmentId}.csv`);
  }

  async getGoSegmentEstimate (channel: string, body: any, accountId?: string): Promise<EstimatedAudience> {
    let path = `/v2/audiences/${channel}/estimate`;
    path = accountId ? `${path}?account_id=${accountId}` : path;
    const response = await clientWithoutErrorHandler.post(path, body);
    return {
      estimateReady: _.get(response.data, 'estimate_ready', false),
      upperBound: _.get(response.data, 'upper_bound', 0),
      lowerBound: _.get(response.data, 'lower_bound', 0)
    };
  }
}

function wrapGoSegment (json): any {
  const lookalike = _.get(json, 'lookalike');
  return {
    ..._.omit(json, ['advertiserID', 'dmpSegmentID', 'lookalike']),
    lookalike: lookalike ? {
      ...lookalike,
      ratio: lookalike.ratio * 100
    } : undefined,
    advertiserId: _.get(json, 'advertiserID'),
    dmpSegmentId: _.get(json, 'dmpSegmentID'),
    type: _.get(json, 'type', GoSegmentType.UPLOAD)
  };
}

function wrapGoSegmentListRecord (json): any {
  return {
    ..._.omit(json, [
      'advertiser_id',
      'segment_id',
      'user_list',
      'user_list_status',
      'user_list_updated_at',
      'custom_audience',
      'custom_audience_status',
      'custom_audience_updated_at'
    ]),
    advertiserId: _.get(json, 'advertiser_id'),
    segmentId: _.get(json, 'segment_id'),
    userList: _.get(json, 'user_list'),
    userListStatus: _.get(json, 'user_list_status'),
    userListUpdatedAt: _.get(json, 'user_list_updated_at'),
    customAudience: _.get(json, 'custom_audience'),
    customAudienceStatus: _.get(json, 'custom_audience_status'),
    customAudienceUpdatedAt: _.get(json, 'custom_audience_updated_at'),
    audienceBrief: _.get(json, 'audienceBrief')
  };
}
