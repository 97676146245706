import { PMaxCore, AddonFeatureManager, RoleNames, DefaultAgencyManager, AgencyManager } from 'core';
import { AdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { CreativesHomeModel, DefaultCreativesHomeModel, SysAdminCreativesHomeModel } from './CreativesHome/CreativesHomeModel';
import {
  CreateCreativeSetupFlowPageModel,
  EditCreativeSetupFlowPageModel,
  CreateCreativeFromAdSetSetupFlowPageModel,
  CreativeSetupFlowPageModel,
  CreateCreativeFromRtbCampaignSetupFlowPageModel,
  CreateCreativeFromRetailMediaCampaignSetupFlowPageModel,
  CreateCreativeFromEdiMaxCampaignSetupFlowPageModel,
  CreateCreativeFroPICCampaignSetupFlowPageModel
} from './CreativeSetupFlow/CreativeSetupFlowPageModel';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { SelectOptions } from 'components/common/commonType';
import { notRoles } from 'core/permission/PermissionDSL';
import { isSystemAdmin } from 'helper/ActorHelper';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { get } from 'lodash';
import { AdLogo, AdLogoType } from 'core/adLogo/AdLogo';

export interface CreativesModel {
  readonly event: UpdateEventListener<CreativesModel>;
  readonly state: CreativesState;
  readonly addonFeatureManager: AddonFeatureManager;
  init (): void;
  getCreativesHomeModel (): CreativesHomeModel | undefined;
  getCreativeCreateModel (): CreativeSetupFlowPageModel | undefined;
  getCreativeEditModel (creativeId: number, campaignId?: string, orderNumber?: string, l1ObjectId?: string, draftId?: string): CreativeSetupFlowPageModel | undefined;
  getCreativeCreateFromCampaignModel (orderNumber: string, l1ObjectId?: string | null, campaignId?: string | null, draftId?: string | null, channel?: string | null): CreativeSetupFlowPageModel | undefined;
  onUnmount (eventHandler?: number): void;
}

export type CreativesProps = {
  readonly model: CreativesModel;
};

export type CreativesState = {
  readonly loading: boolean;
  readonly advertisers?: Array<SelectOptions>;
};

export class DefaultCreativesModel implements CreativesModel {

  advertiserManager: AdvertiserManager;
  addonFeatureManager: AddonFeatureManager;
  advertisers?: Array<SelectOptions>;
  loading: boolean;
  event: FireableUpdateEventListener<CreativesModel>;
  creativesHomeModel?: CreativesHomeModel;
  creativeCreateModel?: CreateCreativeSetupFlowPageModel;
  creativeEditModel?: EditCreativeSetupFlowPageModel;
  creativeCreateFromCampaignModel?: CreativeSetupFlowPageModel;
  canChooseAdvertiser: boolean;
  agencyId?: number;
  defaultAdLogo: AdLogo = {
    type: AdLogoType.DEFAULT
  };

  constructor (
    private core: PMaxCore,
    private agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    this.advertiserManager = core.advertiserManager;
    this.addonFeatureManager = core.addonFeatureManager;
    this.loading = true;
    this.event = new FireableUpdateEventListener<CreativesModel>();
    const chooseAdvertiserPermission = notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport);
    this.canChooseAdvertiser = chooseAdvertiserPermission.visible({ actor: core.authenticationManager.actor });
    this.agencyId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY);
  }

  get state () {
    return {
      loading: this.loading,
      advertisers: this.advertisers
    };
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      const actorAgencyId = get(this.core.authenticationManager, 'actor.agencyId');
      this.defaultAdLogo = actorAgencyId ?
        await this.agencyManager.getAdLogo(actorAgencyId) :
        {
          type: AdLogoType.DEFAULT
        };
      this.advertisers = await this.advertiserManager.getAdvertiserOptions();
      this.updateState(false);
    } catch (error) {
      this.updateState(false);
    }
  }

  onAgencyChange = async (agencyId: number) => {
    this.updateState(true);
    try {
      this.advertisers = await this.advertiserManager.getAdvertiserOptions(agencyId);
      this.agencyId = agencyId;
    } catch (error) {}
    this.updateState(false);
  }

  getCreativesHomeModel () {
    if (!this.advertisers) {
      return undefined;
    }

    const currentAdvertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    if (this.creativesHomeModel &&
      this.creativesHomeModel.advertisers === this.advertisers &&
      this.creativesHomeModel.advertiserId === currentAdvertiserId) {
      return this.creativesHomeModel;
    }

    if (isSystemAdmin(this.core.authenticationManager.actor)) {
      this.creativesHomeModel = new SysAdminCreativesHomeModel(this.advertisers, this.addonFeatureManager, this.onAgencyChange);
    } else {
      this.creativesHomeModel = new DefaultCreativesHomeModel(this.advertisers, this.addonFeatureManager);
    }
    return this.creativesHomeModel;
  }

  getCreativeCreateFromCampaignModel (orderNumber: string, l1ObjectId?: string, campaignId?: string, draftId?: string, channel?: string | null): CreativeSetupFlowPageModel | undefined {
    if (!this.advertisers) {
      return undefined;
    }

    if (this.creativeCreateFromCampaignModel &&
      this.creativeCreateFromCampaignModel.campaignId === campaignId &&
      this.creativeCreateFromCampaignModel.draftId === draftId &&
      this.creativeCreateFromCampaignModel.orderNumber === orderNumber &&
      this.creativeCreateFromCampaignModel.l1ObjectId === l1ObjectId
    ) {
      return this.creativeCreateFromCampaignModel;
    }

    if (channel === L1ObjectChannel.FB) {
      this.creativeCreateFromCampaignModel = new CreateCreativeFromAdSetSetupFlowPageModel(false, this.advertisers, this.addonFeatureManager, this.defaultAdLogo, campaignId, orderNumber, l1ObjectId, draftId);
      return this.creativeCreateFromCampaignModel;
    } else if (channel === L1ObjectChannel.RETAIL_MEDIA) {
      this.creativeCreateFromCampaignModel = new CreateCreativeFromRetailMediaCampaignSetupFlowPageModel(false, this.advertisers, this.addonFeatureManager, this.defaultAdLogo, campaignId, orderNumber, l1ObjectId, draftId);
      return this.creativeCreateFromCampaignModel;
    } else if (channel === L1ObjectChannel.EDIMAX) {
      this.creativeCreateFromCampaignModel = new CreateCreativeFromEdiMaxCampaignSetupFlowPageModel(false, this.advertisers, this.addonFeatureManager, this.defaultAdLogo, campaignId, orderNumber, l1ObjectId, draftId);
      return this.creativeCreateFromCampaignModel;
    } else if (channel === L1ObjectChannel.PIC) {
      this.creativeCreateFromCampaignModel = new CreateCreativeFroPICCampaignSetupFlowPageModel(false, this.advertisers, this.addonFeatureManager, this.defaultAdLogo, campaignId, orderNumber, l1ObjectId, draftId);
      return this.creativeCreateFromCampaignModel;
    } else {
      this.creativeCreateFromCampaignModel = new CreateCreativeFromRtbCampaignSetupFlowPageModel(false, this.advertisers, this.addonFeatureManager, this.defaultAdLogo, campaignId, orderNumber, l1ObjectId, draftId);
      return this.creativeCreateFromCampaignModel;
    }
  }

  getCreativeCreateModel (): CreativeSetupFlowPageModel | undefined {
    if (!this.advertisers) {
      return undefined;
    }

    if (this.creativeCreateModel) {
      return this.creativeCreateModel;
    }

    this.creativeCreateModel = new CreateCreativeSetupFlowPageModel(this.canChooseAdvertiser, this.advertisers, this.addonFeatureManager, this.defaultAdLogo);
    return this.creativeCreateModel;
  }

  getCreativeEditModel (creativeId: number, campaignId?: string, orderNumber?: string, l1ObjectId?: string, draftId?: string): CreativeSetupFlowPageModel | undefined {
    if (!this.advertisers) {
      return undefined;
    }

    if (this.creativeEditModel &&
      this.creativeEditModel.creativeId === creativeId &&
      this.creativeEditModel.campaignId === campaignId &&
      this.creativeEditModel.orderNumber === orderNumber &&
      this.creativeEditModel.draftId === draftId &&
      this.creativeEditModel.l1ObjectId === l1ObjectId
    ) {
      return this.creativeEditModel;
    }

    this.creativeEditModel = new EditCreativeSetupFlowPageModel(creativeId, this.canChooseAdvertiser, this.advertisers, this.addonFeatureManager, this.defaultAdLogo, campaignId, orderNumber, l1ObjectId, draftId);
    return this.creativeEditModel;
  }

  onUnmount (eventHandler: number | undefined = undefined): void {
    this.loading = true;
    if (eventHandler !== undefined) {
      this.event.remove(eventHandler);
    }
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
