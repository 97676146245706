import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Tabs, Tab, Button, Form, Row } from 'react-bootstrap';
import { RtbCampaignSetupStepProps, CampaignSetupTab } from './RtbCampaignSetupStepModel';
import styles from './rtbCampaignSetupStep.module.scss';
import { EditLimitation } from 'containers/Limitations/EditLimitation';
import CampaignBasicForm from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignForm/RtbCampaignBasicForm';
import CampaignTrackForm from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignForm/RtbCampaignTrackForm';
import { RTBCAMPAIGN_DEFAULT_AGE_MAX, RTBCAMPAIGN_DEFAULT_AGE_MIN } from 'core/rtbCampaign/RtbCampaign';
import { Formik } from 'formik';
import i18n from 'i18next';
import Select from 'components/common/Select/Select';
import { ageMaxOptions, ageMinOptions, genderOptions, getPmax2RtbAgeGroupsByAgeRange, getPmax3RtbAgeGroupsByAgeRange } from 'core/limitation/l2ObjectTAOptions';
import { defaultTo, get, partial, remove } from 'lodash';
import { FinalReportGender, Order, OrderType } from 'core/order/Order';

export class RtbCampaignSetupStep extends React.Component<RtbCampaignSetupStepProps> {

  componentDidUpdate () {
    this.scrollToErrorField();
  }

  componentWillUnmount () {
    this.props.model.onUnmount();
  }

  handleSubmit = async (values) => {};

  validate = (campaign) => {
    return this.props.model.validateCampaignBasic(campaign);
  }

  scrollToErrorField () {
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      const child = node.querySelector('.errorTip');
      child && child.scrollIntoView({
        block: 'center'
      });
    }
  }

  getOptValueOfTA = (includeTA, preferredTA, orderType: OrderType) => {
    const defaultOptValue = orderType === OrderType.TENMAX ? 'preferred' : 'include';
    return includeTA ?
      'include' :
      preferredTA ?
        'preferred' :
        defaultOptValue;
  }

  renderAgeAndGender = (limitationValueSet, order: Order, onChange: (operate: string, type: string, value: any) => void) => {
    const orderType: OrderType = order.orderType;
    const generalTAInclude = defaultTo(limitationValueSet['include'], []);
    const generalTAPreferred = defaultTo(limitationValueSet['preferred'], []);
    const ageMinInclude = generalTAInclude.find(value => value.type === 'age_min');
    const ageMinPreferred = generalTAPreferred.find(value => value.type === 'age_min');
    const ageMaxInclude = generalTAInclude.find(value => value.type === 'age_max');
    const ageMaxPreferred = generalTAPreferred.find(value => value.type === 'age_max');
    const genderInclude = generalTAInclude.find(value => value.type === 'gender');
    const genderPerferred = generalTAPreferred.find(value => value.type === 'gender');

    const ageOptValue = this.getOptValueOfTA(ageMinInclude, ageMinPreferred, orderType);
    const genderOptValue = this.getOptValueOfTA(genderInclude, genderPerferred, orderType);

    const ageMinLimitation = ageMinInclude || ageMinPreferred;
    const ageMin = get(ageMinLimitation, 'value', RTBCAMPAIGN_DEFAULT_AGE_MIN);

    const ageMaxLimitation = ageMaxInclude || ageMaxPreferred;
    const ageMax = get(ageMaxLimitation, 'value', RTBCAMPAIGN_DEFAULT_AGE_MAX);

    const gendersLimitation = genderInclude || genderPerferred;
    const gender = get(gendersLimitation, 'value[0]', genderOptions.find(option => option.value === FinalReportGender.ALL));

    const onAgeMinChange = newAgeMin => {
      if (newAgeMin > ageMax) {
        onChange(ageOptValue, 'age_max', newAgeMin);
      }
      onChange(ageOptValue, 'age_min', newAgeMin);
    };
    const onAgeMaxChange = newAgeMax => {
      if (newAgeMax < ageMin) {
        onChange(ageOptValue, 'age_min', newAgeMax);
      }
      onChange(ageOptValue, 'age_max', newAgeMax);
    };
    const onAgeOptChange = (e) => {
      const opts = ['preferred', 'include'];
      const optsToSetValue = remove(opts, opt => opt === e.target.value);
      const optToSetValue = defaultTo(optsToSetValue[0], 'preferred');
      const optToSetUndefined = defaultTo(opts[0], 'include');
      onChange(optToSetValue, 'age_min', ageMin);
      onChange(optToSetValue, 'age_max', ageMax);
      onChange(optToSetUndefined, 'age_min', undefined);
      onChange(optToSetUndefined, 'age_max', undefined);
    };

    const getGenderValue = gender => gender.value === -1 ? undefined : [gender];
    const onGendersChange = gender => {
      onChange(genderOptValue, 'gender', getGenderValue(gender));
    };
    const onGenderOptChange = (e) => {
      const opts = ['preferred', 'include'];
      const optsToSetValue = remove(opts, opt => opt === e.target.value);
      const optToSetValue = defaultTo(optsToSetValue[0], 'preferred');
      const optToSetUndefined = defaultTo(opts[0], 'include');
      onChange(optToSetValue, 'gender', getGenderValue(gender));
      onChange(optToSetUndefined, 'gender', undefined);
    };

    const ageGroupsHint = orderType === OrderType.TENMAX
      ? getPmax2RtbAgeGroupsByAgeRange(ageMin, ageMax).map(group => group.label).join(',')
      : getPmax3RtbAgeGroupsByAgeRange(ageMin, ageMax).map(group => group.label).join(',');

    return (
      <div>
        <Form.Group as={Row} controlId={'age'}>
          <Form.Label>{i18n.t<string>('limitation.labels.age')}</Form.Label>
          <div>
            <div className={styles.ageSelector}>
              <Select
                className={styles.ageMin}
                options={ageMinOptions}
                name='ageMin'
                simpleValue
                value={ageMin}
                onChange={onAgeMinChange}
              />
              ~
              <Select
                className={styles.ageMax}
                options={ageMaxOptions}
                name='ageMax'
                simpleValue
                value={ageMax}
                onChange={onAgeMaxChange}
              />
            </div>
            <span className={styles.ageGroupsHint}>{i18n.t<string>('limitation.hints.ageGroups', { groups: ageGroupsHint })}</span>
          </div>
        </Form.Group>
        {orderType === OrderType.TENMAX &&
         <Fragment>
          <Form.Group as={Row} controlId={'age-opt-preferred'} style={{ 'marginBottom': 0 }}>
            <Form.Label>{''}</Form.Label>
            <div className={styles.optRadio}>
              <Form.Check
                type={'radio'}
                label={i18n.t<string>('rtbCampaign.limitation.operationSet.preferred')}
                value={'preferred'}
                id={'age_preferred'}
                onChange={onAgeOptChange}
                checked={ageOptValue === 'preferred'}
                disabled={false}
              />
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId={'age-opt-include'}>
            <Form.Label>{''}</Form.Label>
            <div className={styles.optRadio}>
              <Form.Check
                type={'radio'}
                label={i18n.t<string>('rtbCampaign.limitation.operationSet.include')}
                value={'include'}
                id={'age_include'}
                onChange={onAgeOptChange}
                checked={ageOptValue === 'include'}
                disabled={false}
              />
            </div>
          </Form.Group>
         </Fragment>
        }
        <Form.Group as={Row} controlId={'gender'}>
          <Form.Label>{i18n.t<string>('limitation.labels.gender')}</Form.Label>
          <Select
            options={genderOptions}
            value={gender}
            onChange={onGendersChange}
          />
        </Form.Group>
        {orderType === OrderType.TENMAX && gender.value !== -1 &&
         <Fragment>
          <Form.Group as={Row} controlId={'gender-opt-preferred'} style={{ 'marginBottom': 0 }}>
            <Form.Label>{''}</Form.Label>
            <div className={styles.optRadio}>
              <Form.Check
                type={'radio'}
                label={i18n.t<string>('rtbCampaign.limitation.operationSet.preferred')}
                value={'preferred'}
                id={'gender_preferred'}
                onChange={onGenderOptChange}
                checked={genderOptValue === 'preferred'}
                disabled={false}
              />
            </div>
          </Form.Group>
          <Form.Group as={Row} controlId={'gender-opt-include'}>
            <Form.Label>{''}</Form.Label>
            <div className={styles.optRadio}>
              <Form.Check
                type={'radio'}
                label={i18n.t<string>('rtbCampaign.limitation.operationSet.include')}
                value={'include'}
                id={'gender_include'}
                onChange={onGenderOptChange}
                checked={genderOptValue === 'include'}
                disabled={false}
              />
            </div>
          </Form.Group>
         </Fragment>
        }
      </div>
    );
  }

  renderFormContent = (formikProps) => {
    const model = this.props.model;
    const order = model.flowModel.order;
    const campaignData = model.flowModel.state.campaign;
    const basicFormModel = model.getRtbCampaignBasicFormModel();
    const limitationModel = model.getLimitationModel();
    model.setFormikProps(formikProps);
    const onTabSelect = (tab) => {
      if (tab === null) {
        return;
      }
      model.goSubStep(+tab);
    };
    const onSaveDraft = model.flowModel.onSaveDraft;
    return (
      <>
        <Tabs
          id='campaignFromTab'
          activeKey={model.activeTab}
          onSelect={onTabSelect}
        >
          <Tab eventKey={CampaignSetupTab.BASIC} title={i18n.t<string>('stepSideBar.labels.basic')}>
            <CampaignBasicForm model={basicFormModel} />
          </Tab>
          <Tab eventKey={CampaignSetupTab.LIMITATION} title={i18n.t<string>('stepSideBar.labels.targeting')}>
            <EditLimitation
              model={limitationModel}
              order={order}
              renderGeneralFields={
                basicFormModel?.limitatoinConfig.supportAgeGenderLimitation
                  ? this.renderAgeAndGender
                  : undefined
              }
            />
          </Tab>
          {
            model.flowModel.needSetupTracking &&
            <Tab eventKey={CampaignSetupTab.TRACKING} title={i18n.t<string>('stepSideBar.labels.strategyAndTracking')}>
              <CampaignTrackForm conversionOptions={model.flowModel.conversionOptions} adType={campaignData.basic.adType} />
            </Tab>
          }
        </Tabs>
        <div className={styles.buttonArea}>
          <Button variant='primary' size='sm' onClick={model.goNext}>
            {i18n.t<string>('campaign.buttons.completeAndCheck')}
          </Button>
          {onSaveDraft && (
            <Button
              variant='secondary'
              size='sm'
              onClick={partial(onSaveDraft, {
                basic: formikProps.values,
                limitations: limitationModel ? limitationModel.limitationValue : {}
              })}
            >
              {i18n.t<string>('campaign.buttons.saveDraft')}
            </Button>
          )}
          {model.flowModel.type === 'create' && <Button variant='secondary' size='sm' onClick={model.goLast}>
            {i18n.t<string>('campaign.buttons.back')}
          </Button>}
        </div>
      </>
    );
  }

  render () {
    const model = this.props.model;
    const campaignData = model.flowModel.state.campaign;
    return (
      <div className={styles.setupStep}>
        <Formik
          initialValues={campaignData.basic}
          enableReinitialize
          onSubmit={this.handleSubmit}
          validate={this.validate}
          validateOnBlur={false}
        >
          {this.renderFormContent}
        </Formik>
      </div>
    );
  }
}
