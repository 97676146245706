import { Form } from 'components/common/form/Form';
import { FormConfig } from 'components/common/form/FormConfig';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';
import { DefaultCustomLayoutManager } from 'core/customLayout/CustomLayoutManager';
import { Button } from 'react-bootstrap';
import { validateEmpty, validateUrl } from 'utils/ValidateUtils';
import { defaultTo, get, isEmpty, partial } from 'lodash';
import { FormikConfigInputField } from 'components/common/form/field/FormikConfigInputField';
import { FieldConfigPage } from 'components/common/form/field/FieldConfigPage';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { CustomLayoutSetupFlowData } from './CustomLayoutSetupFlowPageModel';
import { MacroFieldPlaceHolder } from './MacroFieldPlaceholder';
import { CustomLayoutDeviceType, CustomLayoutType } from 'core/customLayout/CustomLayout';
import { FormikCustomRenderProps } from 'components/common/form/field/CustomField';

const defaultCustomLayoutManager = new DefaultCustomLayoutManager();

export const SetupStep = ({
  customLayoutSetupFlowData,
  goLast,
  goNext,
  setCustomLayoutSetupFlowData
}: {
  customLayoutSetupFlowData: CustomLayoutSetupFlowData,
  goLast: () => void,
  goNext: () => void,
  setCustomLayoutSetupFlowData: Dispatch<SetStateAction<CustomLayoutSetupFlowData>>
}) => {

  const [formConfig, setFormConfig] = useState<FormConfig>();
  const [macroToConfig, setMacroToConfig] = useState<string>();
  const { loading, callAPIs } = useCallAPI();

  const openMacroConfig = useCallback((macroToConfig) => {
    setMacroToConfig(macroToConfig);
  }, []);

  const renderEditMacroButton = useCallback((macroName) => {
    return (
      <Button style={{ width: 'max-content' }} variant='secondary' size='sm' onClick={partial(openMacroConfig, macroName)}>
        {i18n.t<string>('customLayoutSetupFlowPage.buttons.configMacro')}
      </Button>
    );
  }, [openMacroConfig]);

  const renderMacroField = useCallback(
    ({ name, values }: FormikCustomRenderProps<CustomLayoutSetupFlowData>) => {
      const macroConfig = get(values, `macros.${name}`);
      if (!macroConfig) {
        return <div />;
      }

      const placeholder = (
        <MacroFieldPlaceHolder
          name={`previewData.${name}`}
          fieldConfig={macroConfig}
          postComponent={renderEditMacroButton(name)}
        />
      );
      return (
        <FormikConfigInputField
          key={name}
          name={`previewData.${name}`}
          fieldConfig={macroConfig}
          placeholder={placeholder}
          postComponent={renderEditMacroButton(name)}
        />
      );
    },
    [renderEditMacroButton]
  );

  useEffect(() => {
    const fileds = new FormConfig.FieldsBuilder()
      .addFormikInput({
        label: i18n.t<string>('customLayoutSetupFlowPage.labels.name'),
        name: 'name',
        validate: validateEmpty
      })
      .addFormikInput({
        label: i18n.t<string>('customLayoutSetupFlowPage.labels.layoutId'),
        name: 'layoutId',
        validate: validateEmpty
      })
      .addFormikUrlInput({
        label: i18n.t<string>('customLayoutSetupFlowPage.labels.previewUrl'),
        name: 'previewUrl',
        validate: validateUrl
      })
      .addFormikSelect({
        label: i18n.t<string>('customLayoutSetupFlowPage.labels.type'),
        name: 'type',
        simpleValue: true,
        options: [
          { value: CustomLayoutType.CUSTOM_RECTANGLE, label: i18n.t<string>('customLayoutSetupFlowPage.labels.rectangleLayout') },
          { value: CustomLayoutType.CUSTOM_BOTTOM, label: i18n.t<string>('customLayoutSetupFlowPage.labels.bottomLayout') }
        ],
        validate: validateEmpty
      })
      .addFormikSelect({
        label: i18n.t<string>('customLayoutSetupFlowPage.labels.device'),
        name: 'device',
        simpleValue: true,
        options: [
          { value: CustomLayoutDeviceType.DESKTOP, label: i18n.t<string>('customLayoutSetupFlowPage.labels.desktop') },
          { value: CustomLayoutDeviceType.MOBILE, label: i18n.t<string>('customLayoutSetupFlowPage.labels.mobile') }
        ],
        validate: validateEmpty
      });
    const macros = defaultTo(customLayoutSetupFlowData.macros, {});
    Object.keys(macros).forEach(macroName => {
      fileds.addFormikCustom<CustomLayoutSetupFlowData>({
        label: macroName,
        name: macroName,
        render: renderMacroField
      });
    });
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          fileds.build()
        )
        .build()
      )
      .build()
    );
  }, [customLayoutSetupFlowData.macros, renderMacroField]);

  const handleSubmit = useCallback(async (values) => {
    callAPIs([() => defaultCustomLayoutManager.getPreviewUrl(defaultTo(customLayoutSetupFlowData.sessionId, ''), values.previewData, values.macros)], ({
      previewUrlFromServer,
      macroValueMap
    }) => {
      const newPreviewData = Object.keys(values.previewData).reduce((acc, key) => {
        acc[key] = typeof values.previewData[key] === 'object' ? {
          file: undefined,
          url: macroValueMap[key]
        } : values.previewData[key];
        return acc;
      }, {});
      setCustomLayoutSetupFlowData((prev: CustomLayoutSetupFlowData) => ({
        ...prev,
        ...values,
        previewData: newPreviewData,
        previewUrlFromServer
      }));
      goNext();
    });
  }, [customLayoutSetupFlowData.sessionId, setCustomLayoutSetupFlowData, goNext, callAPIs]);

  const closeMacroToConfig = useCallback(() => {
    setMacroToConfig(undefined);
  }, []);

  const macroConfigExtraColumns = useMemo(() => (
    new FormConfig.FieldsBuilder()
      .addFormikInput({
        label: i18n.t<string>('fieldConfigPage.labels.fieldName'),
        name: 'name',
        validate: validateEmpty
      })
      .addFormikInput({
        label: i18n.t<string>('fieldConfigPage.labels.fieldDescription'),
        name: 'description'
      })
      .build()
    ), []);

  return (
    <>
      {loading && <LoadingIndicator />}
      <Formik
        initialValues={{
          name: customLayoutSetupFlowData.name,
          previewUrl: customLayoutSetupFlowData.previewUrl,
          layoutId: customLayoutSetupFlowData.layoutId,
          type: customLayoutSetupFlowData.type,
          device: customLayoutSetupFlowData.device,
          macros: customLayoutSetupFlowData.macros,
          previewData: customLayoutSetupFlowData.previewData
        }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {(formikProps) => {
          const renderFormBtns = () => (
            <>
              <Form.SubmitButton>{i18n.t<string>('common.buttons.next')}</Form.SubmitButton>
              <Button variant='secondary' size='sm' onClick={goLast}>
                {i18n.t<string>('common.buttons.back')}
              </Button>
            </>
          );

          const setMacroConfig = (values) => {
            if (!macroToConfig) {
              return;
            }

            const valuesWithoutEmptyLimit = {
              ...values,
              limits: values.limits ? Object.keys(values.limits).reduce((acc, key) => {
                if (!isEmpty(values.limits[key])) {
                  acc[key] = values.limits[key];
                }
                return acc;
              }, {}) : undefined
            };
            // set the value without validation, because the validation is done in the macro field
            formikProps.setFieldValue(`macros.${macroToConfig}`, valuesWithoutEmptyLimit, false);
          };

          return (
            <>
              <Form
                formikProps={formikProps}
                formConfig={formConfig}
                renderFormBtns={renderFormBtns}
                withNavigationPrompt={false}
              />
              {macroToConfig &&
                <FieldConfigPage
                  title={macroToConfig}
                  config={get(formikProps.values, `macros.${macroToConfig}`)}
                  setConfig={setMacroConfig}
                  closePage={closeMacroToConfig}
                  customFields={macroConfigExtraColumns}
                />
              }
            </>
          );
        }}
      </Formik>
    </>
  );
};
