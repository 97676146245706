import { SelectOptions } from 'components/common/commonType';
import { useCallback, useEffect, useState } from 'react';
import styles from './spacesOfAttribute.module.scss';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { SpaceChannel } from 'core/limitation/ProductGroup';
import { LoadingCircle } from 'components/LoadingCircle/LoadingCircle';

type SpacesOfAttributeProps = {
  attributeType: string;
  spaceAttribute: string;
  adFormat: string;
  channel: SpaceChannel;
};

const adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager();

export const SpacesOfAttribute: React.FC<SpacesOfAttributeProps> = ({
  attributeType,
  spaceAttribute,
  adFormat,
  channel
}) => {

  const [spaces, setSpaces] = useState<SelectOptions[]>();

  useEffect(() => {
    (async () => {
      const spaces = await adRequestSourceManager.getSpacesWithConditions({ [attributeType]: [spaceAttribute] }, adFormat, channel);
      setSpaces(spaces);
    })();
  }, [attributeType, spaceAttribute, adFormat, channel]);

  const renderSpaces = useCallback((spaces: SelectOptions[]) => {
    const items = spaces.map(space => (
      <li key={space.value}>
        <div>{space.label}</div>
        <div>{`ID: ${space.value.toString().replace('\\', '')}`}</div>
      </li>
    ));
    return <ul>{items}</ul>;
  }, []);

  return (
    <div className={styles.spacesOfAttribute}>
      {
        spaces
          ? renderSpaces(spaces)
          : <LoadingCircle width={15} height={15} />
      }
    </div>
  );
};

export const renderSpacesOfAttribute = (
  attributeType: string,
  spaceAttribute: string,
  adFormat: string,
  channel: SpaceChannel
) => {
  return (
    <SpacesOfAttribute
      attributeType={attributeType}
      spaceAttribute={spaceAttribute}
      adFormat={adFormat}
      channel={channel}
    />
  );
};
