import _ from 'lodash';
import i18n from 'i18next';
import { Limitation } from 'core/limitation/Limitation';
import { rtbInventories } from 'containers/Limitations/LimitationSetting/rtbLimitationInventory';
import { SelectOptions } from 'components/common/commonType';

type AliasTuple = [name: string, alias: string];
const aliasTypeNameData: { isGroup: AliasTuple[], notGroup: AliasTuple[]} = {
  isGroup: [['systemRecommendOutdoorDevice', 'adspace']],
  notGroup: [['customOutdoorDevice', 'adspace']]
};

const getTypeAlias = (isGroup: boolean, type: string) => {
  const isGroupKey: string = isGroup ? 'isGroup' : 'notGroup';
  const matchedAlias = aliasTypeNameData[isGroupKey].find((tuple: AliasTuple) => tuple.includes(type));
  if (!matchedAlias) {
    return type;
  }
  return matchedAlias[0] === type ? matchedAlias[1] : matchedAlias[0];
};

const transformLimitationData = (useAliasName: boolean, op: string, data: Limitation) => {
  if (!Array.isArray(data.value)) {
    return [
      {
        op: op,
        limits: data.value,
        isGroup: false,
        type: data.type
      }
    ];
  }
  // adFormat special case
  if (data.type === 'adFormat' && data.value.find(limit => limit.value === 'instl')) {
    return [
      {
        op: op,
        limits: [{
          label: '蓋板',
          value: '1'
        }],
        isGroup: false,
        type: 'instl'
      }
    ];
  }
  const limits = [...data.value];
  const groupData = _.remove(limits, (value: any) => value.isGroup === true);
  const getLimitLabel = (type: string, value: SelectOptions) => {
    if (type === 'adspace') {
      return value.label.replace(`${value.value},`, '');
    }
    return type === 'contentcat' ?
      i18n.t<string>(`limitation.contentCategory.${value.label}`) :
      value.label;
  };

  const type = useAliasName ? getTypeAlias(false, data.type) : data.type;
  let result = limits.length > 0 ? [{
    op: op,
    limits: limits.map(value => {
      // [PMAX-4467] contentcat should save translated sentence to make PMAX1.0 to show correct value.
      return {
        label: getLimitLabel(type, value),
        value: value.value
      };
    }),
    isGroup: false,
    type
  }] : [];

  if (groupData.length > 0) {
    const type = useAliasName ? getTypeAlias(true, data.type) : data.type;
    result.push({
      op: op,
      limits: groupData.map(value => {
         // [PMAX-4467] contentcat should save translated sentence to make PMAX1.0 to show correct value.
        return {
          label: getLimitLabel(type, value),
          value: value.value
        };
      }),
      isGroup: true,
      type
    });
  }
  return result;
};

export function toServerStructure (
  limitations,
  useAliasName: boolean = false,
  filterTA: {[key: string]: string[]} = {
    other: ['dealId'],
    include: ['age_min', 'age_max']
  }
) {

  const otherFilter = _.defaultTo(filterTA.other, []);
  const otherLimitations = _.filter(
    _.defaultTo(limitations.other, []),
    (limitation: Limitation) => !otherFilter.includes(limitation.type)
  );

  const includeFilter = _.defaultTo(filterTA.include, []);
  const includeLimitations = _.filter(
    _.defaultTo(limitations.include, []),
    (limitation: Limitation) => !includeFilter.includes(limitation.type)
  );

  const _transformLimitationData = _.partial(transformLimitationData, useAliasName);

  let result = _.compact(_.flatten(_.concat(
    includeLimitations.map(value => {
      return _transformLimitationData('inc', value);
    }),
    _.defaultTo(limitations.exclude, []).map(value => {
      return _transformLimitationData('exc', value);
    }),
    _.defaultTo(limitations.preferred, []).map(value => {
      return _transformLimitationData('Preferred', value);
    }),
    _.defaultTo(limitations.nonPreferred, []).map(value => {
      return _transformLimitationData('NonPreferred', value);
    }),
    // dealId
    otherLimitations.map(value => {
      if (value.type === 'dealId') {
        return _transformLimitationData('inc', value);
      } else {
        return _transformLimitationData(value.op, value);
      }
    }))
  ));
  return result;
}

export function getLimitationContentLabel (type, limitation) {
  const i18nValue = limitation.value
    .toString()
    .toLowerCase()
    .replace(/-|\s/g, '_');
  const inventoryData: any = rtbInventories[type]
    ? rtbInventories[type]
    : { i18nPrefix: 'limitation.labels' };

  const i18nKey = `${inventoryData.i18nPrefix}.${i18nValue}`;
  return inventoryData.i18nPrefix && i18n.exists(i18nKey)
    ? i18n.t<string>(i18nKey)
    : limitation.label;
}

export function getLimitationAddonDependency (limitationType, limitationName) {
  const addonMap = {
    geography: `${limitationType}_country`,
    carrier: `${limitationType}_carrier`,
    device: `${limitationType}_device`,
    os: `${limitationType}_device`,
    segment: `${limitationType}_target_audience`,
    tenmaxAudience: `${limitationType}_tenmax_audience`,
    contentcat: `${limitationType}_content_category`,
    unicornkeywords: `${limitationType}_keyword`,
    adx: `${limitationType}_adexchange`,
    spaceType: `${limitationType}_spaceType`,
    adspace: `${limitationType}_asiamax_space`,
    contentlang: `${limitationType}_content_language`,
    adcat: `${limitationType}_space_category`,
    domain: `${limitationType}_domain`
  };
  return addonMap[limitationName];
}

export function wrapLimitationsFromServer (
  limitations,
  dealIds?: string[],
  ageMin?: number,
  ageMax?: number,
  useAliasName: boolean = false
) {
  const limitationMap = {
    inc: {},
    exc: {},
    Preferred: {},
    NonPreferred: {},
    other: {}
  };
  limitations.forEach(limitation => {
    const limits = _.get(limitation, 'limits', []);
    const isGroup = _.get(limitation, 'isGroup');
    const type = useAliasName
      ? getTypeAlias(isGroup, _.get(limitation, 'type'))
      : _.get(limitation, 'type');
    const wrappedLimitation = {
      op: _.get(limitation, 'op'),
      value: Array.isArray(limits) ? limits.map(limits => ({
        label: _.get(limits, 'label'),
        value: _.get(limits, 'value'),
        isGroup
      })) : limits,
      type
    };
    if (type === 'dealId') {
      limitationMap['other'][type] = wrappedLimitation;
    } else if (type === 'instl') {
      // transfer legacy code
      limitationMap[limitation.op]['adFormat'] = {
        op: limitation.op,
        value: [{
          label: 'instl',
          value: 'instl',
          isGroup: false
        }],
        type: 'adFormat'
      };
    } else if (type in limitationMap[limitation.op]) {
      limitationMap[limitation.op][type].value = _.concat(limitationMap[limitation.op][type].value, wrappedLimitation.value);
    } else {
      limitationMap[limitation.op][type] = wrappedLimitation;
    }
  });

  const wrappedLimitations: { [type: string]: Limitation[] } = {
    include: Object.values(limitationMap['inc']),
    exclude: Object.values(limitationMap['exc']),
    preferred: Object.values(limitationMap['Preferred']),
    nonPreferred: Object.values(limitationMap['NonPreferred']),
    other: Object.values(limitationMap['other'])
  };

  const ageOptValue = _.find(wrappedLimitations.preferred, (limitation: Limitation) => limitation.type === 'age') ? 'preferred' : 'include';

  ageMin && wrappedLimitations[ageOptValue].push({
    op: ageOptValue === 'include' ? 'inc' : 'Preferred',
    value: ageMin,
    type: 'age_min'
  });

  ageMax && wrappedLimitations[ageOptValue].push({
    op: ageOptValue === 'include' ? 'inc' : 'Preferred',
    value: ageMax,
    type: 'age_max'
  });

  const dealIdTA = wrappedLimitations.other.find(ta => ta.type === 'dealId');
  !dealIdTA && wrappedLimitations.other.push({
    op: 'inc',
    value: dealIds ? dealIds.map(dealId => {
      return {
        label: dealId,
        value: dealId
      };
    }) : [],
    type: 'dealId'
  });

  return wrappedLimitations;
}
