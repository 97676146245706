import React from 'react';
import { Row, Form } from 'react-bootstrap';
import Tags from 'components/common/Tags/Tags';
import Dropdowns from 'components/common/Dropdowns/Dropdowns';
import styles from './LimitationSet.module.scss';
import i18n from 'i18next';
import classnames from 'classnames/bind';
import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const cx = classnames.bind(styles);

const LimitationSet: React.FC<any> = ({
  title,
  value,
  onChange,
  deleteLimitation,
  changeOperate,
  operate,
  add,
  type,
  needShow,
  preComment,
  postComment,
  errorTip,
  i18nPrefix,
  disable,
  disableReason,
  supportOperates,
  tipsConfig,
  showPlus = true,
  showClearButton = false,
  ...props
}) => {

  if (value.length <= 0 && !needShow) {
    return null;
  }
  const valueWithI18n = i18nPrefix ? value.map(option => {
    const i18nKey = `${i18nPrefix}.${option.value.toString().toLowerCase().replace(/-|\s/g, '_')}`;
    return {
      ...option,
      label: i18n.exists(i18nKey) ? i18n.t<string>(i18nKey) : option.value.toString()
    };
  }) : value;
  const handleOnChange = (choosedValueWithI18n) => {
    const choosedValue = _.intersectionWith(value, choosedValueWithI18n, (option: SelectOptions, optionWithI18n: SelectOptions) => option.value === optionWithI18n.value);
    onChange(choosedValue);
  };
  const dropdownHidden = needShow;
  const className = cx('limitationSet', props.className, type);
  const tagsClassName = cx('tags', {
    hasError: errorTip !== undefined
  });
  return (
    <div className={className}>
      {preComment}
      <Form.Group as={Row} controlId={title}>
        <Form.Label>{i18n.t<string>(title)}</Form.Label>
        <Tags
          value={valueWithI18n}
          onChange={handleOnChange}
          operate={operate}
          addAction={add}
          className={tagsClassName}
          showPlus={showPlus}
          disabled={disable}
          disableReason={disableReason}
          tipsConfig={tipsConfig}
        />
        { showClearButton &&
          <FontAwesomeIcon
            className={styles.clearBtn}
            icon={faTimes}
            onClick={deleteLimitation}
          />
        }
        {
          dropdownHidden || disable ? <div className={styles.emptySpace}/> :
          <Dropdowns
            deleteLimitation={deleteLimitation}
            changeOperate={changeOperate}
            type={type}
            operate={operate}
            supportOperates={supportOperates}
          />
        }
      </Form.Group>
      {postComment}
      {errorTip}
    </div>
  );
};

export default LimitationSet;
