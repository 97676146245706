import { CreativeType } from 'core/creative/Creative';
import { ComboCreativeListDataProvider } from './ComboCreativeListDataProvider';
import { OneForAllVideoCreativeListDataProvider } from './OneForAllVideoCreativeListDataProvider';
import { HTML5CreativeListDataProvider } from './HTML5CreativeListDataProvider';
import { ImageCreativeListDataProvider } from './ImageCreativeListDataProvider';
import { NativeCreativeListDataProvider } from './NativeCreativeListDataProvider';
import { OneForAllCreativeListDataProvider } from './OneForAllCreativeListDataProvider';
import { OneForAllDisplayCreativeListDataProvider } from './OneForAllDisplayCreativeListDataProvider';
import { PPSCreativeListDataProvider } from './PPSCreativeListDataProvider';
import { VideoCreativeListDataProvider } from './VideoCreativeListDataProvider';
import { RetailProductCreativeListDataProvider } from './RetailProductCreativeListDataProvider';
import { PilotTVCreativeListDataProvider } from './PilotTVCreativeListDataProvider';
import { CustomLayoutCreativeListDataProvider } from './CustomLayoutCreativeListDataProvider';
import { EdiMaxCreativeListDataProvider } from './EdiMaxCreativeListDataProvider';
import { PICCreativeListDataProvider } from './PICCreativeListDataProvider';

export function getDataProvider (creativeType: CreativeType, model: any) {

  switch (creativeType) {
    case CreativeType.NATIVE:
      return new NativeCreativeListDataProvider(model);
    case CreativeType.IMAGE:
      return new ImageCreativeListDataProvider(model);
    case CreativeType.VIDEO:
    case CreativeType.CTV:
      return new VideoCreativeListDataProvider(model);
    case CreativeType.ONE_FOR_ALL:
      return new OneForAllCreativeListDataProvider(model);
    case CreativeType.ONE_FOR_ALL_DISPLAY:
      return new OneForAllDisplayCreativeListDataProvider(model);
    case CreativeType.THIRDPARTY:
      return new PPSCreativeListDataProvider(model);
    case CreativeType.HTML5:
      return new HTML5CreativeListDataProvider(model);
    case CreativeType.EDIMAX:
      return new EdiMaxCreativeListDataProvider(model);
    case CreativeType.PIC_SHORT:
    case CreativeType.PIC_LONG:
      return new PICCreativeListDataProvider(model);
    case CreativeType.COMBO:
      return new ComboCreativeListDataProvider(model);
    case CreativeType.ONE_FOR_ALL_VIDEO:
      return new OneForAllVideoCreativeListDataProvider(model);
    case CreativeType.RETAIL_RICH_MEDIA_PRODUCT:
      return new RetailProductCreativeListDataProvider(model, creativeType);
    case CreativeType.RETAIL_NATIVE_PRODUCT:
      return new RetailProductCreativeListDataProvider(model, creativeType);
    case CreativeType.PILOT_TV:
      return new PilotTVCreativeListDataProvider(model);
    case CreativeType.CUSTOM_BOTTOM:
    case CreativeType.CUSTOM_RECTANGLE:
      return new CustomLayoutCreativeListDataProvider(model);
    default:
      return new NativeCreativeListDataProvider(model);
  }
}
