import { useCallback, useMemo } from 'react';
import i18n from 'i18n';
import { FormConfig, FormField } from 'components/common/form/FormConfig';
import { useOutdoorDurationFields } from './useOutdoorDurationFields';
import { useFormikContext } from 'formik';
import { floor } from 'lodash';
import { OutdoorType } from 'core/creative/Creative';
import styles from './outdoorFields.module.scss';
import { useOutdoorProperties } from './useOutdoorProperties';
import { VideoImageOutdoorCreativeFields } from 'core/creative/OutdoorCreative';
import { OutdoorFormModel } from '../OutdoorFormModel';

export const useVideoImageOutdoorFields = <T extends OutdoorFormModel<T>>(
  model: T
): FormField[] => {

  const { values, setFieldValue } = useFormikContext<VideoImageOutdoorCreativeFields>();
  const isEditing = !!values.creativeId;
  const {
    outdoorType
  } = useOutdoorProperties(values);

  const durationFields = useOutdoorDurationFields(
    isEditing || model.useMediaDuration ? 'label' : 'select'
  );

  const onMediaFileChange = useCallback(
    fileData => {
      fileData &&
        setFieldValue('typeProperties.duration', floor(fileData.duration));
    },
    [setFieldValue]
  );

  const fields = useMemo(() => {
    if (outdoorType !== OutdoorType.VIDEO_IMAGE) {
      return [];
    }

    const width = 1080;
    const imageHeight = 1313;
    const videoWidth = 1920;
    const videoHeight = 1080;
    const builder = new FormConfig.FieldsBuilder();
    builder
      .addFormikFileInput({
        type: 'video',
        label: i18n.t<string>('videoForm.labels.tenMaxVideoInput'),
        name: 'medias.video',
        maxWidth: 270,
        hints: model.getVideoHints(videoWidth, videoHeight),
        validate: value => model.validateVideo(videoWidth, videoHeight, value),
        onChange: model.useMediaDuration ? onMediaFileChange : undefined
      })
      .addFormikFileInput({
        type: 'image',
        label: i18n.t<string>('creativeSetupFlow.labels.image'),
        name: 'medias.image',
        className: styles.videoImageInput,
        maxWidth: 270,
        hints: [
          i18n.t<string>('creativeSetupFlow.labels.typeHint'),
          i18n.t<string>('creativeSetupFlow.labels.imageSizeHint', {
            size: `${width} x ${imageHeight}`
          }),
          i18n.t<string>('creativeSetupFlow.labels.storageHint', {
            storage: '2MB'
          })
        ],
        validate: value => model.validateImage(width, imageHeight, value)
      });

    return [...builder.build(), ...durationFields];
  }, [
    model,
    outdoorType,
    durationFields,
    onMediaFileChange
  ]);

  return fields;
};
