import config from 'config';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { SelectOptions } from 'components/common/commonType';
import { DefaultEditLimitationModel, EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { SavedTargeting } from 'core/limitation/Limitation';
import { DefaultLimitationManager, LimitationManager } from 'core/limitation/LimitationManager';
import { MIN_HOUR, MessageCampaignBasic, MessageCampaignPlanType } from 'core/messageCampaign/MessageCampaign';
import { DefaultMessageCampaignManager, MessageCampaignManager } from 'core/messageCampaign/MessageCampaignManager';
import { Order } from 'core/order/Order';
import { AdType, CampaignState, CreativeDeliverType, DeliverType } from 'core/rtbCampaign/RtbCampaign';
import { OPERATE } from 'enum/Operate';
import i18n from 'i18next';
import _, { defaultTo } from 'lodash';
import moment from 'moment';
import {
  FireableUpdateEventListener,
  UpdateEventListener
} from 'utils/UpdateEventListener';
import { DefaultMessageCampaignSummaryStepModel, MessageCampaignSummaryStepModel } from './FlowSteps/MessageCampaignSummaryStepModel';
import { MessageCampaignBasicFormModel, MessageCampaignBasicFormModelConstructorParams } from './MessageCampaignForm/MessageCampaignBasicFormModel';
import { CreateMessageFormModel, EditMessageFormModel, MessageFormModel } from './MessageCampaignForm/MessageFormModel';
import { getMessageLimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/messageLimitationInventorySettings';
import { getDefaultLimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/defaultLimitationInventorySettings';
import { LIMITATION_TYPE } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';

export interface MessageCampaignSetupFlowPageModel {
  readonly type: string;
  readonly objectType: string;
  readonly order: Order;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly state: MessageCampaignSetupFlowPageState;
  readonly event: UpdateEventListener<MessageCampaignSetupFlowPageModel>;
  readonly limitationModel?: EditLimitationModel;
  readonly campaignBasicFormModel?: MessageCampaignBasicFormModel;
  readonly limitationPreSet: any;
  readonly campaignId: number | string | null;
  readonly l1Object: L1Object;
  readonly localeMeta?: LocaleMeta;
  readonly audienceLowestThreshold: number;
  init (): void;
  setCampaign (campaign: any, rerender?: boolean): void;
  getMessageCampaignBasicFormModel (adType): MessageCampaignBasicFormModel | undefined;
  getLimitationModel (priceModel): EditLimitationModel;
  getTitle (): string;
  onAdTypeChange (adType): void;
  onUnmount (handler): void;
  setOrder (order: Order): void;
  setRedirectPath (redirectPath?: string): void;
  setFinishedRedirectPath (redirectPath?: string): void;
  cancel (): void;
  getMessageCampaignSummaryStepModel (goLast, goStep): MessageCampaignSummaryStepModel;
  getLimitationsSummaryData (limitations): any;
}

export type MessageCampaignSetupFlowPageProps = {
  readonly model: MessageCampaignSetupFlowPageModel;
};

export type MessageCampaignSetupFlowPageState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly campaign: any;
  readonly finished: boolean;
  readonly showTAManagement: boolean;
};

export abstract class DefaultMessageCampaignSetupFlowPageModel implements MessageCampaignSetupFlowPageModel {
  objectType: string;
  event: FireableUpdateEventListener<MessageCampaignSetupFlowPageModel>;
  loading: boolean;
  redirectPath?: string;
  addonFeatureManager: AddonFeatureManager;
  campaign: any;
  defaultCampaign: any;
  limitationPreSet: any;
  order: Order;
  campaignMessageFormModel?: MessageFormModel;
  campaignBasicFormModel?: MessageCampaignBasicFormModel;
  campaignSummaryModel?: MessageCampaignSummaryStepModel;
  limitationModel: any;
  rbLimitationModel: any;
  normalLimitationModel: any;
  finished: boolean;
  goSegments: SelectOptions[] | undefined = [];
  savedTAList: SavedTargeting[] = [];
  showTAManagement: boolean = false;
  appliedSavedTAInfo?: SelectOptions;
  operationSet: any;
  segmentsOptions: SelectOptions[] = [];

  constructor (
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    public l1Object: L1Object,
    public localeMeta?: LocaleMeta,
    protected otherCampaignOfL1Object?: MessageCampaignBasic[],
    protected manager: MessageCampaignManager= new DefaultMessageCampaignManager(),
    protected limitationManager: LimitationManager = new DefaultLimitationManager(),
    protected adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager()
  ) {
    this.objectType = 'campaign';
    this.order = { ...order };
    this.order.budgetBalance = this.l1Object ? this.l1Object.budgetBalance : this.order.budgetBalance;
    this.addonFeatureManager = addonFeatureManager;
    this.manager = manager;
    this.limitationManager = limitationManager;
    this.event = new FireableUpdateEventListener<MessageCampaignSetupFlowPageModel>();
    this.loading = true;
    this.finished = false;
  }

  getMessageCampaignBasicFormModel (_adType): MessageCampaignBasicFormModel | undefined {
    this.campaignBasicFormModel = this.campaignMessageFormModel;
    return this.campaignBasicFormModel;
  }

  abstract get type ();

  abstract init ();

  abstract getTitle ();

  get estimatedAudience () {
    return this.limitationModel ? this.limitationModel.estimateData : undefined;
  }

  get audienceLowestThreshold () {
    if (!config.audienceSizeLowerBound) {
      return 1000;
    }
    return config.audienceSizeLowerBound;
  }

  to404 () {
    const redirectPath = this.l1Object ?
      `/orders/${this.order.orderNumber}/campaign-groups/${this.l1Object.l1ObjectId}/message-campaigns/${this.campaignId}/edit/error404` :
      `/orders/${this.order.orderNumber}/message-campaigns/${this.campaignId}/edit/error404`;
    this.setRedirectPath(redirectPath);
  }

  initBidStrategy () {
    if (!this.campaign) {
      return;
    }
    _.set(this.campaign, 'basic.bidStrategy',
      _.isNumber(_.get(this.campaign, 'basic.bidPrice')) ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP
    );
  }

  async initSegmentOptions () {
    try {
      const segmentsOptions = await this.adRequestSourceManager.getMessageSegments();
      this.segmentsOptions = segmentsOptions;
    } catch (e) {}
  }

  getMessageCampaignSummaryStepModel (goLast, goStep) {
    if (this.campaignSummaryModel) {
      return this.campaignSummaryModel;
    }
    this.campaignSummaryModel = new DefaultMessageCampaignSummaryStepModel(this, goLast, goStep);
    return this.campaignSummaryModel;
  }

  getLimitationsSummaryData = (limitations): any => {
    const limitationSummaryData = this.manager.getLimitationSummaryData(
      limitations
    );
    return _.omitBy({
      include: {
        title: i18n.t<string>('campaignSummary.titles.inc'),
        titlePrefixColor: SummaryTitleColor.GREEN,
        content: limitationSummaryData.include
      }
    }, _.isEmpty);
  }

  setCampaign = (campaign: any, rerender: boolean | undefined = true) => {
    this.campaign = campaign;
    this.createCampaignFormModels();
    rerender && this.updateState(false);
  }

  setOrder = (order: Order) => {
    this.order = { ...order };
    this.order.budgetBalance = this.l1Object ? this.l1Object.budgetBalance : this.order.budgetBalance;
  }

  abstract get campaignId ();

  abstract createCampaignFormModels ();

  get state (): MessageCampaignSetupFlowPageState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      campaign: this.campaign,
      finished: this.finished,
      showTAManagement: this.showTAManagement
    };
  }

  getCamapignFormModelParams (): MessageCampaignBasicFormModelConstructorParams {
    return [
      this.defaultCampaign,
      this.campaign.basic,
      this.order,
      this.l1Object,
      this.addonFeatureManager,
      this.onPriceModelChange,
      this.otherCampaignOfL1Object
    ];
  }

  onAdTypeChange (adType: AdType) {
    const campaignBasicModel = this.getMessageCampaignBasicFormModel(adType);
    const defaultCampaign = _.cloneDeep(this.defaultCampaign);
    if (campaignBasicModel) {
      const optimize = campaignBasicModel.getDefaultOptimizeType(campaignBasicModel.defaultPriceModel);
      _.set(defaultCampaign, 'basic.priceModel', campaignBasicModel.defaultPriceModel);
      _.set(defaultCampaign, 'basic.optimize', optimize);
      const onlyBidCap = false;
      _.set(defaultCampaign, 'basic.bidStrategy', onlyBidCap ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP);
    }

    // since currently only has one ad type, no need to reset a brand new campaign
    const newCampaign = {
      ...defaultCampaign,
      ...this.campaign,
      basic: {
        ...this.campaign.basic,
        adType
      }
    };
    this.setCampaign(newCampaign, true);
  }

  setRedirectPath (redirectPath?: string) {
    this.redirectPath = redirectPath;
    this.updateState(false);
  }

  setFinishedRedirectPath (redirectPath?: string) {
    this.redirectPath = redirectPath;
    this.finished = true;
    this.updateState(false);
  }

  getLimitationModel (priceModel: MessageCampaignPlanType) {
    const defaultCanNotNull = {};
    const limitationPreSet = this.limitationPreSet ? this.limitationPreSet : {};
    Object.keys(defaultCanNotNull).forEach(opreate => {
      _.remove(defaultCanNotNull[opreate],
        value => !limitationPreSet[opreate] ||
          limitationPreSet[opreate].find(element => element.type === value) === undefined
      );
    });

    const limitationsCanNotNull = _.omitBy(defaultCanNotNull, _.isEmpty);
    const requiredOperateOfTaTypes = {};
    Object.keys(limitationsCanNotNull).forEach(operate => {
      const limitationCanNotNullOfOperate = limitationsCanNotNull[operate];
      limitationCanNotNullOfOperate && limitationCanNotNullOfOperate.forEach(type => {
        let requiredOperateOfTaType = defaultTo(requiredOperateOfTaTypes[type], []);
        requiredOperateOfTaType.push(operate);
        requiredOperateOfTaTypes[type] = requiredOperateOfTaType;
      });
    });
    this.limitationModel = this.getNormalLimitationModel(requiredOperateOfTaTypes);
    return this.limitationModel;
  }

  isCampaignNeedOtherLimitation (campaign) {
    return true;
  }

  getNormalLimitationModel (requiredOperateOfTaTypes) {
    const limitationSetting = AdType.MESSAGE === this.campaign.basic.adType ?
      getMessageLimitationInventorySettings(this.segmentsOptions) :
      getDefaultLimitationInventorySettings(requiredOperateOfTaTypes, this.campaign.basic.advertiserId, LIMITATION_TYPE.CAMPAIGN, this.goSegments, this.localeMeta);

    this.operationSet = {
      need: [OPERATE.INCLUDE],
      notNeed: undefined,
      other: undefined
    };
    if (!this.normalLimitationModel) {
      this.normalLimitationModel = new DefaultEditLimitationModel(
        limitationSetting,
        this.campaign.limitations,
        {
          need: [OPERATE.INCLUDE],
          notNeed: undefined,
          other: undefined
        },
        this.addonFeatureManager.addonFeature,
        undefined,
        {
          channel: L1ObjectChannel.MESSAGE,
          audienceLowestThreshold: this.audienceLowestThreshold,
          channelTargetingGetter: (limitationValue: any) => {
            const results: any = [];
            Object.values(limitationValue).forEach((limitationsOfOp: any) => {
              limitationsOfOp.forEach(limitation => {
                results.push({
                  op: limitation.op,
                  type: limitation.type,
                  limits: limitation.value
                });
              });
            });
            return results;
          }
        }
      );
    }
    this.normalLimitationModel.setLimitationSetting(limitationSetting);
    return this.normalLimitationModel;
  }

  getRtbTargeting = limitationValue => {
    return [];
  }

  onPriceModelChange = (priceModel, currentCampaignBasic) => {
    if (!this.campaignBasicFormModel) {
      return;
    }
    const limitationModel = this.getLimitationModel(priceModel);
    const optimize = this.campaignBasicFormModel.getDefaultOptimizeType(priceModel);
    this.setCampaign({
      basic: {
        ...currentCampaignBasic,
        priceModel,
        optimize,
        orderPrice: undefined
      },
      limitations: limitationModel.limitationValue
    });
  }

  cancel = () => {
    const redirectPath = this.l1Object ?
      `/orders/${this.order.orderNumber}/campaign-groups/${this.l1Object.l1ObjectId}` :
      `/orders/${this.order.orderNumber}`;
    this.setRedirectPath(redirectPath);
  }

  validateCampaign () {
    return (this.l1Object && this.campaign.basic.goGanGroupId !== _.get(this.l1Object, 'rtb.group_id')) || this.campaign.basic.state === CampaignState.DELETE;
  }

  onUnmount (handler) {
    this.event.remove(handler);
    this.redirectPath = undefined;
    this.campaign = undefined;
    this.rbLimitationModel = undefined;
    this.normalLimitationModel = undefined;
    this.campaignSummaryModel = undefined;
    this.finished = false;
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

abstract class CreateFlowPageModel extends DefaultMessageCampaignSetupFlowPageModel {

  createCampaignFormModels () {
    const params = this.getCamapignFormModelParams();
    this.campaignMessageFormModel = new CreateMessageFormModel(...params);
  }
}

abstract class EditFlowPageModel extends DefaultMessageCampaignSetupFlowPageModel {

  constructor (
    protected modalCampaignId: number,
    ...args: ConstructorParameters<typeof DefaultMessageCampaignSetupFlowPageModel>
  ) {
    super(...args);
    this.modalCampaignId = modalCampaignId;
  }

  createCampaignFormModels () {
    const params = this.getCamapignFormModelParams();
    this.campaignMessageFormModel = new EditMessageFormModel(...params);
  }
}

export class CreateMessageCampaignSetupFlowPageModel extends CreateFlowPageModel {

  get type () {
    return 'create';
  }

  get campaignId () {
    return null;
  }

  async init () {
    this.updateState(true);
    try {
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      this.campaign = await this.createDefaultCampaign();
      await this.initSegmentOptions();
      this.initBidStrategy();
      this.defaultCampaign = _.cloneDeep(this.campaign);
      this.createCampaignFormModels();
    } catch (e) {}
    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.createCampaignTitle');
  }

  async createDefaultCampaign () {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const startDate = this.getCampaignStartDay();
    const campaign = {
      basic: {
        adLogo: this.order.adLogo,
        advertiserId: this.order.advertiserId,
        budget: this.order.budgetBalance,
        checkpoints: [],
        creativeDeliverType: CreativeDeliverType.OPTIMIZE,
        dailyTargetBudget: null,
        tags: [],
        name: this.l1Object ? this.l1Object.name : this.order.projectName,
        orderId: this.order.id,
        startDate: startDate.format(dateFormat),
        priceModel: MessageCampaignPlanType.FIXED_VIEWABLE_IMPRESSION,
        optimize: L2ObjectOptimizationGoal.FIXED_VIEWABLE_IMPRESSION,
        message: '',
        enableMonitor: true,
        deliverType: DeliverType.STANDARD,
        convTrackEvent: 'click',
        expectedSpent: 0,
        orderPriceEnable: true
      },
      limitations: _.cloneDeep({
        include: []
      })
    };

    return campaign;
  }

  getCampaignStartDay () {
    const validStart = moment().add(1, 'day').startOf('day').hour(MIN_HOUR);
    const orderStartDate = moment(this.order.startDate);
    return validStart.isBefore(orderStartDate) ? orderStartDate.hour(MIN_HOUR) : validStart;
  }
}

export class EditMessageCampaignSetupFlowPageModel extends EditFlowPageModel {

  get type () {
    return 'edit';
  }

  get campaignId () {
    return this.modalCampaignId;
  }

  async getCampaign () {
    return this.manager.getCampaign(this.modalCampaignId);
  }

  async init () {
    this.updateState(true);
    try {
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      this.campaign = await this.getCampaign();
      await this.initSegmentOptions();
      this.initBidStrategy();
      this.defaultCampaign = _.cloneDeep(this.campaign);
      this.createCampaignFormModels();
      if (this.validateCampaign()) {
        this.to404();
        return;
      }
    } catch (e) {}
    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.editCampaignTitle');
  }
}

// TODO: add copy and split campaign model
